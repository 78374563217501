import React, { useState, useEffect, useContext } from 'react'
import styled from "styled-components"
import { AuthContext } from '../../context/AuthContext';
import { collection, getDocs, query, where, deleteDoc, doc  } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link } from 'react-router-dom';

export default function ProjectCart({ cartDescription }) {
  const { currentUser, cart, setCart, setProjectCart } = useContext(AuthContext);
  const [savedCarts, setSavedCarts] = useState([]); 
  const [selectedCart, setSelectedCart] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isListExpanded, setIsListExpanded] = useState(false)
  const [selectedCartId, setSelectedCartId] = useState('');



  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const fetchSavedCarts = async () => {
      try {
        const q = query(collection(db, 'savedCarts'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const carts = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          data.id = doc.id
          carts.push(data);
        });
        setSavedCarts(carts);
      } catch (error) {
        console.error('Error fetching saved carts: ', error);
      }
    };

    fetchSavedCarts();
  }, [currentUser]);

  const handleCartClick = (cart) => {
    setSelectedCart(cart);
  }

  const handleSelectChange = (event) => {
    const cartId = event.target.value;
    setSelectedCartId(cartId);
    setProjectCart(cartId)
    const cart = savedCarts.find(c => c.id === cartId);
    handleCartClick(cart);
  };

  const transferToGlobalCart = () => {
    // Merge the current global cart with the transformed cartKledning
    const mergedCart = [...cart, ...selectedCart.items];
    setCart(mergedCart);
    scrollToTop()
  };
  
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"  // Optional: smooth scrolling
    });
  }

  const getArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
  };

  const getTotalSum = () => {
    return selectedCart.items.reduce((total, product) => total + (product.byggmakkerPrice * product.quantity), 0);
  };

  const toggleList = () => {
    setIsListExpanded(!isListExpanded);
  };

  return (
    <StyledDiv>
      <div className='mt-5'>
      <p><strong>Legg til en handlelisten som viser hvilke produkter du brukte i ditt prosjekt. Velg blandt dine lagrede handlelister.</strong></p>

      <div className='input-container'>
  <select value={selectedCartId} onChange={handleSelectChange} className="select">
    <option value="">Velg en handleliste</option>
    {savedCarts.map(cart => (
      <option key={cart.id} value={cart.id}>
        {cart.name} - Lagret: {new Date(cart.createdAt.seconds * 1000).toLocaleDateString()}
      </option>
    ))}
  </select>
</div>

      {isListExpanded && selectedCart && (
        <CartContainer>

          {isListExpanded && (<Table>
            <thead>
              <tr>
              <th></th>
                <th>Produkt</th>
                <th>Pris</th>
                <th>Antall</th>
                <th className="hide-sm">Artikkelnummer</th>
                <th>Produkt total</th>
              </tr>
            </thead>
            <tbody>
              {isListExpanded && selectedCart.items.map((product, index) => (
                
                <tr key={index}>
                  <td><img src={product.imageSrc} alt={product.productName} style={{height: '50px'}}/></td>
                  <td>
                    <ProductNameLink to={`/${product.breadcrumb1}/${product.breadcrumb2}/${product.breadcrumb3}/${product.productName}`}>
                      <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>{product.productName}</h2>
                    </ProductNameLink>
                  </td>
                  <td>kr. {product.byggmakkerPrice} {product.unit}</td>
                  <td>{product.quantity}</td>
                  <td className="hide-sm">{getArticleNumber(product)}</td>
                  <td>{(product.byggmakkerPrice * product.quantity).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>)}
             </CartContainer>
      )}

          <Footer>
  <div className='d-flex justify-content-between w-100'>
    
   {isListExpanded && ( <div>
    <h2>Total: kr. {selectedCart && (getTotalSum().toFixed(2))}</h2>
    
      <button className='btn btn-info' onClick={transferToGlobalCart}>
        Overfør til handlehurv
      </button>
      </div>
    )}
  </div>
  <div>
  <p>{cartDescription}</p>

    <button onClick={toggleList} className="btn btn-outline-primary">
    {isListExpanded ? 'Skjul handleliste' : 'Vis handleliste'}
    </button>
  </div>
</Footer>

        
     
</div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`

.input-container {
    margin: 15px 0;
    padding: 5px;
    border: 1px solid #ddd; // Light grey border
    border-radius: 4px; // Rounded corners
    background-color: #fff; // White background
    width: 100%; // Full width

    select {
      width: 100%; // Full width of container
      padding: 8px 10px; // Padding for aesthetics
      border: none; // No additional border
      border-radius: 4px; // Rounded corners
      background-color: #fff; // White background
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      font-size: 16px; // Readable font size
      color: #333; // Dark text for readability

      &:focus {
        outline: none; // Remove focus outline
        box-shadow: 0 0 0 2px #3498db; // Highlight focus with a different color
      }

      option {
        padding: 8px; // Padding inside options
      }
    }
  }

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .toggleListButton {
    margin-top: 15px; // Space above the button
    // Other styles as needed
  }
    .listWrapper {
    display: flex; 
    align-items: center; 
    justify-content: space-between; // Adjust based on your layout preference
  }

  .lister {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 1; // it will take the available space
  }

  .lister:hover {
    color: tomato;
  }

  .list {
    width: 40px;
  }

  .lister:hover .list {
    filter: brightness(0.5) sepia(1) hue-rotate(-50deg) saturate(1000%) contrast(0.8);
  }

  .deleteIcon {
    height: 27px;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s ease; // for a smooth transition effect
  }

  .deleteIcon:hover {
    filter: brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(0.8); 
    // This applies a series of filter effects to alter the icon's appearance
    // You may need to play with these values to get your desired effect.
  }
`

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 60px);
  margin-top:10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    .hide-sm {
      display: none;
    }
  }
`;

const Footer = styled.div`
   display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; // Allow items to wrap onto the next line

  > div {
    flex-basis: 100%; // Make each direct child take full width on its own line
    margin-top: 10px; // Add some space between the lines
  }
`;

const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
