import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import styled from "styled-components"
import payment from "../images/paymentreq.png"
import paymentthings from "../images/paymentthings.png"

const stripePromise = loadStripe('pk_live_51NktR7EEXh426acwfprxgubSKYeyiUNdOJEpKsYXJpu2GtBKhymkVRyRQQYaWF4p4RJD28O01xJCZHkSceEW9rCp00uiGh56iC');

function BuyButtonComponent({ refId }) {
  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{price: 'price_1NkuEtEEXh426acwmFPrntot', quantity: 1}],
      mode: 'payment',
      successUrl: 'https://minoppussing.no/profile/tilbud',
      cancelUrl: 'https://minoppussing.no/paymenterror',
      clientReferenceId: refId,
    });

    if (error) {
      console.error(error);
    }
  };

  return (
    <StyledDiv className="stripe-button-container">
    <div className='wrapper'>
      <button className="btn btn-success stripe-button" onClick={handleCheckout}>
        Betal
      </button>
      <div className="payment-methods align-center">
        <img src={paymentthings} alt="morepayment" className='paymenthings' />
        <img src={payment} alt="stripemethods" className='stripemethods'/>

      </div>
      </div>
    </StyledDiv>
  );
}

export default BuyButtonComponent;


const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers children horizontally */
    justify-content: center;  /* Centers children vertically */
.wrapper{
border:1px solid lightgray;
border-radius:10px ;
padding:10px;
padding-top:20px;
margin:10px;
max-width:370px;

@media(max-width:380px){
  padding-left:0;
  padding-right:0;
  max-width:320px;
}
}

.payment-methods {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Centers children horizontally */
    justify-content: center;  /* Centers children vertically */
  }

  .stripe-button {
    width: 250px;
  }

  .stripemethods,
  .paymenthings {
    display: block;
    margin: auto;  /* Centers the block-level elements */
  }

  .stripemethods {
    width: 300px;
  }

  .paymenthings {
    width: 220px;
  }
`;