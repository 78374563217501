import React from 'react';
import styled from "styled-components";
import Banner from './HomeComponents/Banner';
import ProsjektkalkulatorFront from './HomeComponents/ProsjektkalkulatorFront';
import Services from './HomeComponents/Services';
import UtvalgteProdukter from './HomeComponents/UtvalgteProdukter';
//import AdComponent from '../AdComponent';
export default function Home() {

    return (
        <StyledDiv>
          <Banner/>
          <UtvalgteProdukter/>
          <ProsjektkalkulatorFront/>
          <Services/>
          <br/>
          <br/>
        {/*  <AdComponent  />  */}

        </StyledDiv>
    )
}

const StyledDiv = styled.div`


`;

