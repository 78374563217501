import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cartIcon from "../images/cart.png";
import { AuthContext } from '../context/AuthContext';  
import slett from "../images/delete.png";

const SideCart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cart, setCart } = useContext(AuthContext);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRemoveProduct = (productId) => {
    setCart(prevCart => prevCart.filter(product => product.id !== productId));
  };

  const handleIncreaseQuantity = (productId) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: product.quantity + 1} : product));
  };
  
  const handleDecreaseQuantity = (productId) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: Math.max(1, product.quantity - 1)} : product));
  };
  
  const handleUpdateQuantity = (productId, quantity) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: Math.max(1, parseInt(quantity))} : product));
  };

  const calculateTotal = () => {
    return cart.reduce((acc, product) => {
        const correctedPrice = (product.byggmakkerPrice || '0').replace(',', '.'); // Replace comma with period
        const parsedPrice = parseFloat(correctedPrice); // Parse corrected price to float
        const parsedQuantity = parseFloat(product.quantity || 0); // Parse quantity to float

        return acc + parsedPrice * parsedQuantity; // Accumulate the total
    }, 0).toFixed(2); // Format the result to two decimal places
};


  const formatter = new Intl.NumberFormat('no-NO', { style: 'decimal', minimumFractionDigits: 2 });

  const sumFixer = (price, quantity) => {
    const correctedPrice = (price || '0').replace(',', '.'); // Replace comma with period
    const parsedPrice = parseFloat(correctedPrice); // Parse corrected price to float
    const parsedQuantity = parseFloat(quantity || 0); // Parse quantity to float

    const total = parsedPrice * parsedQuantity; // Calculate the total
    return total.toFixed(2); // Format the total to two decimal places
};

  return (
    <>
      {isOpen && <Backdrop className="backdrop" onClick={handleToggle}></Backdrop>}
      <SideCartWrapper className={isOpen ? 'open' : 'closed'}>
        <div className="cart-header px-2" onClick={handleToggle}>
          <CartIcon>
            <img src={cartIcon} alt="Cart" className={`cart-icon ${isOpen ? 'open' : 'closed'}`} />
            {cart.length > 0 && <Counter>{cart.length}</Counter>}
          </CartIcon>
        </div>
        <div className={`cart-content ${isOpen ? 'open' : 'closed'}`}>
          {isOpen && (
            <div className="close-container my-3">
              <button className="close-button" onClick={handleToggle}>X - Lukk</button>
            </div>
          )}
          {isOpen && (
            <div className='cart-item-container'> 
              <div className="cart-items">
              {cart.map((product, index) => (
  <div key={index} style={{display: 'flex', marginBottom: '20px'}}>
    <img src={product.imageSrc} alt={product.productName} style={{height: '100px', marginRight: '10px', alignSelf: 'flex-start'}} />
    <div style={{flexGrow: 1}}>
      <ProductNameLink to={`/${product.breadcrumb1}/${product.breadcrumb2}/${product.breadcrumb3}/${product.productName}`}>
        <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>{product.productName}</h2>
      </ProductNameLink>
      <p style={{fontSize: '12px'}}>kr. {product.byggmakkerPrice} {product.unit}</p>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Button onClick={() => handleDecreaseQuantity(product.id)}>-</Button>
        <QuantityInput type="number" value={product.quantity} onChange={e => handleUpdateQuantity(product.id, e.target.value)}/>
        <Button onClick={() => handleIncreaseQuantity(product.id)}>+</Button>
      </div>
    </div>
    <p style={{marginRight: '10px'}}>{sumFixer(product.byggmakkerPrice, product.quantity)}</p>

    <img className="deleteIcon" src={slett} onClick={() => handleRemoveProduct(product.id)} alt="delete icon"/>
  </div>
))}


              </div>
              <TotalSum>
                Total: kr {formatter.format(calculateTotal())}
              </TotalSum>
              <Link onClick={handleToggle} to="/cart" className="btn btn-success checkOut">Til handlekurv</Link>
            </div>
          )}
        </div>
      </SideCartWrapper>
    </>
  );
};

export default SideCart;

const SideCartWrapper = styled.div`
  position: relative;
  z-index: 30;

  .close-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .close-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }

  .cart-items-container {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .cart-items-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .deleteIcon {
    height: 27px;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s ease; // for a smooth transition effect
  }

  .deleteIcon:hover {
    filter: brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(0.8); 
    // This applies a series of filter effects to alter the icon's appearance
    // You may need to play with these values to get your desired effect.
  }


  .cart-header {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding-left: 10px;
  }

  .cart-content {
    width: 350px;
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -350px;
    transition: right 0.3s ease-in-out;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 12px;
    height: calc(100vh);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .cart-items {
      margin-top: 10px;
    }
  }

  .cart-content.open {
    right: 0;
  }

  .checkOut {
    display: block;
    width: 90%;
    margin: 20px auto;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 25;
`;

const CartIcon = styled.div`
  height: 30px;
  position: relative;
  margin-right: 10px;
  margin-bottom:10px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  .cart-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;

const Counter = styled.span`
  position: absolute;
  top: -5px;
  right: -2px;
  background: #df3f3a;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  font-size:20px ;
  padding-left:3px;
  padding-right:3px;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
`;

const QuantityInput = styled.input`
  margin: 0 10px;
  width: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

`;

const TotalSum = styled.div`
  text-align:end;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right:10px;
  font-size:15px;
`;

const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
h2{
 width:125px ;
}
 

  &:hover {
    color: #ff0000;  // Change this to whatever color you want on hover
  }
`;
