import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Stage, Layer, Line, Text, Circle } from 'react-konva';

const VeggDesigner = ({onWallDataChange}) => {
    const [points, setPoints] = useState([]);
    const [lines, setLines] = useState([]);
    const [lengths, setLengths] = useState([]);
    const [angles, setAngles] = useState([]);
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);
    const [walls, setWalls] = useState([]); // Each wall is an array of points
    const [currentWall, setCurrentWall] = useState([]); // The points of the wall currently being drawn
    const [totalLength, setTotalLength] = useState(0);
    const [numCorners, setNumCorners] = useState(0);
    const [numEnds, setNumEnds] = useState(2);  // Assuming you start with 2 ends
    const [helperLine, setHelperLine] = useState(null);
    const [isNewWall, setIsNewWall] = useState(false);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth * 0.7,
        height: window.innerHeight * 0.6,
      });

      const [scale, setScale] = useState(1); // Starting scale is 1, no zoom
 


    const SNAP_THRESHOLD = 20;  // Adjust this value based on your needs
    const angleTolerance = 0.2;  // Allowing a 2-degree deviation from 90


      // Zoom in function
  const zoomIn = () => {
    setScale(scale * 1.1); // Increase scale by 10%
  };

  // Zoom out function
  const zoomOut = () => {
    setScale(scale / 1.1); // Decrease scale by 10%
  };

  const resetZoom = () =>{
    setScale(1)
  }

    useEffect(() => {
        const handleResize = () => {
          setDimensions({
            width: window.innerWidth * 0.70,
            height: window.innerHeight * 0.6,
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        // Assuming totalLength, numCorners, and numEnds are states or derived values
        onWallDataChange({
          totalLength: totalLength,
          numCorners: numCorners,
          numEnds: numEnds,
        });
      }, [totalLength, numCorners, numEnds, onWallDataChange]); 

    useEffect(() => {
        const updatedLines = [];
        const updatedLengths = [];
        const updatedAngles = [];
    
        // Calculate lines and lengths
        for (let i = 0; i < points.length - 1; i++) {
            const linePoints = [points[i], points[i + 1]];
            updatedLines.push(linePoints);
    
            const distance = Math.sqrt(
                Math.pow(points[i].x - points[i + 1].x, 2) + Math.pow(points[i].y - points[i + 1].y, 2)
            );
            const lengthInMeters = distance / 50;
            updatedLengths.push(lengthInMeters.toFixed(2)); // Store lengths in meters
        }
    
        for (let i = 1; i < points.length - 1; i++) {
            const angle = calculateAngle(points[i - 1], points[i], points[i + 1]);
            if (Math.abs(angle - 90) === 0) { 
                updatedAngles.push({ index: i, symbol: '⦜' }); 
            }
        }
    
        setLines(updatedLines);
        setLengths(updatedLengths);
        setAngles(updatedAngles);
        
    }, [points]);
    

    useEffect(() => {
        const updatedLines = [];
        const updatedLengths = [];
    
        for (let i = 0; i < currentWall.length - 1; i++) {
            const linePoints = [currentWall[i], currentWall[i + 1]];
            updatedLines.push(linePoints);
    
            const distance = Math.sqrt(
                Math.pow(currentWall[i].x - currentWall[i + 1].x, 2) + 
                Math.pow(currentWall[i].y - currentWall[i + 1].y, 2)
            );
            const lengthInMeters = distance / 50;
            updatedLengths.push(lengthInMeters.toFixed(2));
        }
    
        setLines(updatedLines);
        setLengths(updatedLengths);
        checkAngles();
        calculateWallMetrics();

    }, [currentWall]);
 
 
    const handleNewWall = () => {
        if (currentWall.length > 0) {
          // Save the current wall's points if it has any
          setWalls([...walls, currentWall]);
        }
        // Start a new wall by resetting the currentWall points
        setCurrentWall([]);
        calculateWallMetrics();
        setIsNewWall(true);

      };
      
      
      const calculateWallMetrics = () => {
        let totalLength = 0;
        let corners = 0;    
        let ends = walls.reduce((acc, wall) => acc + (isClosedLoop(wall) ? 0 : 2), 0);
    
        if (currentWall.length === 1) {
            ends += 1; 
        } else if (currentWall.length >= 2 && !isClosedLoop(currentWall)) {
            ends += 2;
        }
        
        const allWalls = [...walls, currentWall];
    
        allWalls.forEach((wall) => {
            for (let i = 0; i < wall.length - 1; i++) {
                const distance = Math.sqrt(
                    Math.pow(wall[i].x - wall[i + 1].x, 2) + Math.pow(wall[i].y - wall[i + 1].y, 2)
                );
                totalLength += distance; 
            }
        
            if (isClosedLoop(wall)) {
                corners += wall.length - 1; // Adjusting here
            } else if (wall.length > 2) {
                corners += wall.length - 2;
            }
        });
        
    
        setTotalLength((totalLength / 50).toFixed(2));
        setNumCorners(corners);
        setNumEnds(ends);
    };
    
    
    const isClosedLoop = (wall) => {
        if (wall.length < 3) { // At least 3 points required to form a closed loop
            return false;
        }
        const firstPoint = wall[0];
        const lastPoint = wall[wall.length - 1];
        const distance = Math.sqrt(Math.pow(firstPoint.x - lastPoint.x, 2) + Math.pow(firstPoint.y - lastPoint.y, 2));
        return distance < SNAP_THRESHOLD;  // SNAP_THRESHOLD can be a small value like 5 or 10, depending on your use case.
    };
    
    

      function computeAngleBetweenLines(pointA, pointB, pointC) {
        // Vector BA
        const BA = { x: pointA.x - pointB.x, y: pointA.y - pointB.y };
        // Vector BC
        const BC = { x: pointC.x - pointB.x, y: pointC.y - pointB.y };
    
        // Dot product
        const dotProduct = BA.x * BC.x + BA.y * BC.y;
        // Magnitude of BA
        const magBA = Math.sqrt(BA.x * BA.x + BA.y * BA.y);
        // Magnitude of BC
        const magBC = Math.sqrt(BC.x * BC.x + BC.y * BC.y);
    
        // Cosine of angle
        const cosTheta = dotProduct / (magBA * magBC);
    
        // Angle in radians
        const theta = Math.acos(Math.min(Math.max(cosTheta, -1.0), 1.0));  // Clamping values to handle potential floating point inaccuracies
    
        // Convert angle to degree
        const angleInDegrees = theta * (180 / Math.PI);
    
        return angleInDegrees;
    }
    


    // A function to calculate the angle between two points
    const calculateAngle = (A, B, C) => {
        // Direction vector from A to B
        const AB = { x: B.x - A.x, y: B.y - A.y };
        // Direction vector from B to C
        const BC = { x: C.x - B.x, y: C.y - B.y };
      
        // Calculating the dot product
        const dotProduct = AB.x * BC.x + AB.y * BC.y;
        // Calculating the magnitude of AB and BC vectors
        const magAB = Math.sqrt(AB.x * AB.x + AB.y * AB.y);
        const magBC = Math.sqrt(BC.x * BC.x + BC.y * BC.y);
        // Calculating cosine of the angle between AB and BC
        const cosineAngle = dotProduct / (magAB * magBC);
        // Calculating the angle in degrees
        const angle = Math.acos(cosineAngle) * (180 / Math.PI);
      
        return angle;
      };
      

  // New logic to check every point for its angle
  const checkAngles = () => {
    const newAngles = [];
  
    points.forEach((center, index) => {
        const connectedPoints = lines.reduce((acc, line) => {
            if (line.includes(center)) {
                const otherPoint = line.find(point => point !== center);
                if (otherPoint) {
                    acc.push(otherPoint);
                }
            }
            return acc;
        }, []);

        if (connectedPoints.length === 2) {
            const angle = calculateAngle(center, connectedPoints[0], connectedPoints[1]);
  
            if (Math.abs(angle - 90) === 0) { 
                newAngles.push({ index, symbol: '⦜' });
            }
        }
    });
  
    setAngles(newAngles);
};


function handleDragMove(e, index) {
    const newPosition = e.target.position();

    const updatedWall = [...currentWall];
    updatedWall[index] = newPosition;

    setCurrentWall(updatedWall);

    const newHelperLine = getHelperLine(newPosition, updatedWall);
    setHelperLine(newHelperLine);

    const { x, y } = e.target.position();

    let newX = x;
    let newY = y;

    if (index > 0) {
        const prevPoint = currentWall[index - 1];
        
        if (Math.abs(y - prevPoint.y) <= SNAP_THRESHOLD) {
            newY = prevPoint.y;
        }

        if (Math.abs(x - prevPoint.x) <= SNAP_THRESHOLD) {
            newX = prevPoint.x;
        }
    }

    for (let i = 0; i < currentWall.length; i++) {
        if (i !== index && isNear(x, y, currentWall[i].x, currentWall[i].y)) {
            newX = currentWall[i].x;
            newY = currentWall[i].y;
            break;
        }
    }

    const updatedPoint = { ...currentWall[index], x: newX, y: newY };
    const updatedCurrentWall = [
        ...currentWall.slice(0, index),
        updatedPoint,
        ...currentWall.slice(index + 1)
    ];

    setCurrentWall(updatedCurrentWall);
    calculateWallMetrics();
    setIsNewWall(false);
}


const isNear = (x1, y1, x2, y2) => {
    const distance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    return distance < SNAP_THRESHOLD;
};



const handleStageMouseDown = (e) => {
    // Deselect point when clicking elsewhere
    if (e.target === e.target.getStage()) {
        const { x, y } = e.target.getPointerPosition();
        let newPoint = { x, y };

        // If this is the second point, ensure the line is straight
        if (currentWall.length === 1) {
            const [p1] = currentWall;
            if (Math.abs(p1.x - x) < Math.abs(p1.y - y)) {
                newPoint = { x: p1.x, y };  
            } else {
                newPoint = { x, y: p1.y };
            }
        }

        setCurrentWall(prevWall => [...prevWall, newPoint]);
        setSelectedPointIndex(null);
        calculateWallMetrics();
        setIsNewWall(false);
    }
};


  const handleCircleClick = (index) => {
    // Select clicked circle
    setSelectedPointIndex(index);
  };


  const handleDeletePoint = () => {
    if (selectedPointIndex !== null) {
        const updatedWall = [...currentWall];
        updatedWall.splice(selectedPointIndex, 1);
        setCurrentWall(updatedWall);
        setSelectedPointIndex(null);
        calculateWallMetrics();
    }
    checkAngles();
};

  const handleClearAll = () => {
    setPoints([]);
    setLines([]);
    setSelectedPointIndex(null);
    setCurrentWall([])
    setWalls([])
    setTotalLength(null)
    setNumCorners(null)
    setNumEnds(null)
  };

  function getSegmentLength(point1, point2) {
    const distance = Math.sqrt(
        Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
    );
    return (distance / 50).toFixed(2);
}

function handleStageMouseMove(e) {
    const point = e.target.getStage().getPointerPosition();

    const wallPointsMerged = [...walls.flat(), ...currentWall];
    const newHelperLine = getHelperLine(point, wallPointsMerged);


    setHelperLine(newHelperLine);
}


function handleStageMouseUp() {
    setHelperLine(null);
}

function getHelperLine(currentPoint, wallPoints) {
    if (!wallPoints || wallPoints.length <= 1) return null;

    for (let i = 0; i < wallPoints.length; i++) {
        const point = wallPoints[i];
        const nextPoint = i < wallPoints.length - 1 ? wallPoints[i + 1] : wallPoints[0];

        const isAtRightAngle = checkRightAngle(currentPoint, point, nextPoint);
        if (isAtRightAngle) {

            const line = [currentPoint, point];  // Adjusted to the first point of the wall.
            return line;
        }
    }
    return null;
}






function handleDragEnd() {
    setHelperLine(null); // Reset helper line
}

function checkRightAngle(currentPoint, pointA, pointB) {
    // Don't check angles when there's only one point in wallPoints.
    if (pointA === pointB) {
        return false;
    }

    const angle = computeAngleBetweenLines(currentPoint, pointA, pointB);
    
    return Math.abs(angle - 90) < angleTolerance;
}




  return (
    <StyledDiv>
      <div className="container">
        <div className="panel">
          {/* Panel buttons */}
          <button className='btn btn-dark' onClick={handleDeletePoint} disabled={selectedPointIndex === null}>Slett markert punkt</button>
          <button className='btn btn-dark' onClick={handleClearAll}>Slett alt</button>
          <button className='btn btn-dark' onClick={handleNewWall}>Start ny vegg</button>



            <p>Total lengde: {totalLength} meter</p>
            <p>Antall hjørner: {numCorners}</p>
            <p>Antall ender: {numEnds}</p>
            
        </div>

        <Stage
    className="canvas"
    width={dimensions.width}
    height={dimensions.height}
    onMouseDown={handleStageMouseDown}
    onMouseMove={handleStageMouseMove}
    onMouseUp={handleStageMouseUp}
    scaleX={scale} 
    scaleY={scale}

>



<Layer>

{helperLine && (
    <Line
        points={[helperLine[0].x, helperLine[0].y, helperLine[1].x, helperLine[1].y]}
        stroke="rgba(0, 0, 0, 0.5)"
        strokeWidth={2} 
    />
)}







    {/* Render lines for past walls */}
    {walls.map((wall, wallIndex) => (
        wall.map((point, pointIndex) => {
            if (pointIndex < wall.length - 1) {
                const nextPoint = wall[pointIndex + 1];
                return (
                    <Line
                        key={`line-wall-${wallIndex}-point-${pointIndex}`}
                        points={[point.x, point.y, nextPoint.x, nextPoint.y]}
                        stroke="black"
                        strokeWidth={5}
                        tension={0}
                    />
                );
            }
            return null;
        })
    ))}


    {/* Render lines for the current wall */}
    {currentWall.map((point, index) => {
        if (index < currentWall.length - 1) {
            const nextPoint = currentWall[index + 1];
            return (
                <Line
                    key={`line-current-${index}`}
                    points={[point.x, point.y, nextPoint.x, nextPoint.y]}
                    stroke="black"
                    strokeWidth={5}
                    tension={0}
                />
            );
        }
        return null;
    })}

    {/* Render points, angles, and lengths for past walls */}
    {walls.map((wall, wallIndex) => (
    wall.map((point, pointIndex) => {
        const nextPoint = wall[pointIndex + 1];
        
        // Check if the nextPoint is defined before proceeding
        if (nextPoint) {
            const linePoints = [point, nextPoint];
            const distance = Math.sqrt(
                Math.pow(point.x - nextPoint.x, 2) + Math.pow(point.y - nextPoint.y, 2)
            );
            const lengthInMeters = (distance / 50).toFixed(2);
            return (
                <React.Fragment key={`frag-wall-${wallIndex}-point-${pointIndex}`}>
                    <Circle
                        x={point.x}
                        y={point.y}
                        radius={10}
                        fill="gray"
                        stroke="#000"
                        strokeWidth={2}
                    />
                    <Text
                        text={`${lengthInMeters}m`}
                        x={(point.x + nextPoint.x) / 2}
                        y={(point.y + nextPoint.y) / 2}
                        offsetY={-18}
                        fontSize={15}
                        fill="black"
                    />
                </React.Fragment>
            );
        }
        return null; // Return null for the last point in the wall
    })
))}


    {/* Render points, angles, and lengths for the current wall */}
    {currentWall.map((point, index) => (
        <React.Fragment key={`current-fragment-${index}`}>
            <Circle
                x={point.x}
                y={point.y}
                radius={10}
                fill={index === selectedPointIndex ? 'blue' : 'gray'}
                stroke="#000"
                strokeWidth={2}
                draggable
                onDragMove={(e) => handleDragMove(e, index)}
                onClick={() => handleCircleClick(index)}
                onDragEnd={handleDragEnd}

            />
            {index > 0 && (
                <Text
                    text={getSegmentLength(currentWall[index - 1], point) + "m"}
                    x={(currentWall[index - 1].x + point.x) / 2}
                    y={(currentWall[index - 1].y + point.y) / 2}
                    offsetY={-18}
                    fontSize={15}
                    fill="black"
                />
            )}
            {currentWall.map((point, index) => {
    if (index > 0 && index < currentWall.length - 1) {
        const prevPoint = currentWall[index - 1];
        const nextPoint = currentWall[index + 1];
        const angle = computeAngleBetweenLines(prevPoint, point, nextPoint);
        const roundedAngle = Math.round(angle);
        
        // Only show the icon if the angle is close to 90 degrees.
        if (Math.abs(roundedAngle - 90) === 0) {  // Allowing 2-degree deviation
            return (
                <Text
                    key={`angle-${index}`}
                    text="📐"
                    x={point.x}
                    y={point.y}
                    offsetY={-30}
                    offsetX={-10}
                    fontSize={20}
                    fill="red"
                />
            );
        }
    }
    return null;
})}
        </React.Fragment>
    ))}

</Layer>



        </Stage>
        <div className='d-flex mt-3'>
        <button className='btn btn-outline-dark btn-sm' onClick={zoomIn}>Zoom inn</button>
          <button className='btn btn-outline-dark btn-sm mx-3' onClick={zoomOut}>Zoom ut</button>
          <button className='btn btn-outline-dark btn-sm' onClick={resetZoom}>Tilbakestill zoom</button>


        </div>
      </div>
    </StyledDiv>
  );
  
};

export default VeggDesigner;


const StyledDiv = styled.div`
  .container-fluid {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: row; /* Default to row, but this will be overridden in the media query below */
  }
  
  .canvas {
    width: 70vw;
    height: 60vh;
    border: 2px solid black;
  }
  
  .panel {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 25vw;
    min-width:180px;

    p{
        margin-bottom: 0px;
    }
  }

  /* Add a media query for small screens */
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stack flex items vertically */
      align-items: center; /* Align items in the center on smaller screens */
    }

    .canvas, .panel {
      width: 90vw; /* Adjust width for small screens */
    }

    .panel {
      order: 2; /* Ensures that the panel comes after the canvas */
    }

    .canvas {
      order: 1; /* Ensures that the canvas comes before the panel */
      height: auto; /* Adjust height as necessary, or keep it to a fixed value */
    }
  }
  `