import React from 'react';

const SuccessPage = () => {
  return (
    <div className='container my-5'>
      <h1>Din tilbudsforespørsel er sendt</h1>
      <p>Din tilbudsforespørsel er sendt og blir behandlet med en gang. Det kan ta noen virkedager før tilbud kommer inn. Du vil motta en epost når tilbud er tilgjengelige og du vil se tilbud på din profil på minoppussing.no</p>
    </div>
  )
}

export default SuccessPage;
