import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import styled from "styled-components";
import CartItemsList from './CartItemsList';
import { AuthContext } from '../../context/AuthContext';


export default function KledningProducts() {

    const { materials, setCartKledning  } = useContext(AuthContext);


    const insulationCoverage = {
        glava: {
            "5cm": { coverage: 10.3, id: 'C2Z1Om6KJsIfh1aE2bNn' },
            "7cm": { coverage: 7.41, id: 'kCUhz6NV46gnMKtHKmk9' },
            "10cm": { coverage: 5.36, id: 'pqCEpj2l8nI1S0x3Icxx' },
            "15cm": { coverage: 3.65, id: 'FAQqUdKaQNJD7gFVFkvK' },
            "20cm": { coverage: 3.42, id: 'RIX7Ib5bwSnumeAk4PND' },
        },
        rockwool: {
            "5cm": { coverage: 8.14, id: 'M6dFptRdsIawwD3iaQlS' },
            "7cm": { coverage: 5.52, id: 'jhSVkyGf7CafYx6PzNIs' },
            "10cm": { coverage: 4.14, id: 'jQHfV51tVxS5TWDEJqrW' },
            "15cm": { coverage: 2.76, id: 'HQqgk4mD5AlbdrRRG5Eh' },
            "20cm": { coverage: 2.07, id: 'Er7Gn5VtnI2tTJM0MgXZ' },
        }
    };

    const LEKTER_LOOKUP = {
        '5cm': 'MP1tVWKvdMuXUyGOzSWi',
        '7cm': '2B6WBs82QQhu4cSK5wqx',
        '10cm': 'i3fAFZFSXs2DdEluPVbT',
        '15cm': '375QkN5MKVGsCi48zDSt',
        '20cm':'1NCtqOmZbVDu89QJ4Jp6'
    };

    const VINDSPERRE = {
        'vindsperre': {
            'small': { coverage: 32.51, id: 'a6gjOYQEjz3xnRz7Sfpm' },
            'medium':{coverage:65,id:'PAGxAv17xKJrZRBOsrd6'},
            'large': { coverage: 140, id: 'sGluPTq2R1aGWI5qcZcA' },
        },
        'asfalt': {
            'default': { coverage: 3.29, id: 'vRDsvIurWcoZiL1zMewS' } // or other variations if they exist
        },
        'gips': {
            'default': { coverage: 3.29, id: 'mKDNB1GbeZbJU0L7WwLe' } // or other variations if they exist
        }
    }

    const MUSEBÅND = {
        'default': { coverage: 1.25, id: 'v1tkbQa5xDQAqO3xdL6i' }
    };
    
    const SCREWS = {
        'small': { amount: 100, id: 'mF8XxM7Xgp2KON5I6Tn1' }, 
        'medium':{amount: 400, id: '65jAhJZEb2ubb6TXZvkF'}
    };


    const KLEDNING_COVERAGE = {
        '19x98': {
            dimension: '19x98',
            coverage: 12.5 
        },
        '19x123': {
            dimension: '19x123',
            coverage: 9.6
        },
        '19x148': {
            dimension: '19x148',
            coverage: 7.7
        },
        '19x173': {
            dimension: '19x173',
            coverage: 6.5
        }
    };
 
    const REKKER = {
        '23cm':'PwIpM8D00PtEbDKWdITH',
        '36cm':'PKgwQxlyonxFPtpHKGWA'
    }

    const NAILS = 'vaTCQKzoEUwn6hvoOyjc'

    const VINDSPERRETAPE = '3QvLLo9HmVnLqBaMfhPZ'
    


    const [productsDatabase, setProductsDatabase] = useState([]);

    useEffect(() => {
        async function fetchProducts() {
            const q = collection(db, "products");
            const querySnapshot = await getDocs(q);
            const products = [];
            querySnapshot.forEach((doc) => {
                products.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setProductsDatabase(products);
        }
        fetchProducts();
    }, []);

    useEffect(() => {
        if (materials && productsDatabase.length > 0) {
            calculationsToCart((materials));
        }
    }, [productsDatabase, materials]);

    function calculateInsulationQuantity(insulationType, thickness, area) {
        if (insulationType === null) {
            return 0;
        }
    
        if (!insulationType || !insulationCoverage[insulationType]) {
            console.error(`Invalid insulationType: ${insulationType}`);
            return 0;
        }
    
    
        const currentInsulationCoverage = insulationCoverage[insulationType];
        const currentInsulationThicknessCoverage = currentInsulationCoverage ? currentInsulationCoverage[thickness] : null;
    
        if (!currentInsulationThicknessCoverage) {
            console.error(`Invalid thickness for insulationType ${insulationType}: ${thickness}`);
            return 0;
        }
        
        const coverage = currentInsulationThicknessCoverage.coverage;
    
        if (!coverage) {
            console.error(`Coverage not found for insulationType ${insulationType} and thickness ${thickness}`);
            return 0;
        }
    
        return Math.ceil(area / coverage);
    }
    
    

    function calculationsToCart(materials) {
        const cartItems = [];

        let totalCoverageRequired = 0;

       const calculateLekterQuantity = (wallLength, wallHeight, wallHeightSide, wallHeightCenter, selectedWall) => {
           if (selectedWall === "sidewall") {
               // Calculate the quantity for a sidewall.
               return Math.ceil(wallLength * 2 + wallHeight * 2 + ((wallLength / 0.6) * wallHeight));
           } else if (selectedWall === "gablewall") {
               // Calculate the quantity for a gabel wall.
               return Math.ceil(wallLength * 2 + wallHeightSide * 2 + ((wallLength / 0.6) * wallHeightSide) + ((wallHeightCenter - wallHeightSide) * wallLength) / 2);
           } else {
               console.error("Invalid wall type:", selectedWall);
               return 0; // Return 0 or some other default value in case of invalid wall type.
           }
       };
    
        // Calculating for existing insulation
        const insulationQuantityExisting = calculateInsulationQuantity(
            materials.insulationType,
            materials.insulationForExistingThickness,
            materials.insulationForExisting
        );
    
        const currentInsulationTypeCoverage = insulationCoverage[materials.insulationType];
        const currentInsulationTypeThicknessCoverage = currentInsulationTypeCoverage ? currentInsulationTypeCoverage[materials.insulationForExistingThickness] : null;
    
        const insulationProductExisting = productsDatabase.find(product => product.id === currentInsulationTypeThicknessCoverage?.id);
    
        cartItems.push({
            product: insulationProductExisting,
            quantity: insulationQuantityExisting
        });
    
        // Calculating for etterisolering
        if (materials.etterisolereThickness) {
            const insulationQuantityEtterisolering = calculateInsulationQuantity(
                materials.insulationType,
                materials.etterisolereThickness,
                materials.insulationForEtterisolering
            );
    
            const etterisolereCoverage = insulationCoverage[materials.insulationType];
            const currentEtterisolereThicknessCoverage = etterisolereCoverage ? etterisolereCoverage[materials.etterisolereThickness] : null;
    
            const insulationProductEtterisolering = productsDatabase.find(product => product.id === currentEtterisolereThicknessCoverage?.id);
    
            cartItems.push({
                product: insulationProductEtterisolering,
                quantity: insulationQuantityEtterisolering
            });
    
                    // Calculating for lekter
        const lekterProduct = productsDatabase.find(product => product.id === LEKTER_LOOKUP[materials.etterisolereThickness]);
        const lekterQuantity = calculateLekterQuantity(materials.wallLength, materials.wallHeight, materials.wallHeightSide, materials.wallHeightCenter, materials.selectedWall);

        cartItems.push({
            product: lekterProduct,
            quantity: lekterQuantity
        });

             // Calculating for musebånd
        const musebåndRequiredM2 = materials.wallLength; // using wallLength
        const musebåndQuantity = Math.ceil(musebåndRequiredM2 / MUSEBÅND.default.coverage);
        const musebåndProduct = productsDatabase.find(product => product.id === MUSEBÅND.default.id);
        cartItems.push({
            product: musebåndProduct,
            quantity: musebåndQuantity
        });

        // Calculating for screws
        const totalScrewsRequired = Math.ceil(materials.lekter / 0.6);
        const mediumPacksRequired = Math.floor(totalScrewsRequired / SCREWS.medium.amount);
        const remainingScrews = totalScrewsRequired - (mediumPacksRequired * SCREWS.medium.amount);
        const smallPacksRequired = Math.ceil(remainingScrews / SCREWS.small.amount);

        const mediumPackScrewsProduct = productsDatabase.find(product => product.id === SCREWS.medium.id);
        const smallPackScrewsProduct = productsDatabase.find(product => product.id === SCREWS.small.id);

        if (mediumPacksRequired > 0) {
            cartItems.push({
                product: mediumPackScrewsProduct,
                quantity: mediumPacksRequired
            });
        }
        if (smallPacksRequired > 0) {
            cartItems.push({
                product: smallPackScrewsProduct,
                quantity: smallPacksRequired
            });
        }
    
    
        }
    
            // Calculating for vindsperre
    if (materials.wallArea && materials.selectedVindsperreOptions && materials.selectedVindsperreOptions.length) {
        materials.selectedVindsperreOptions.forEach(vindsperreType => {
            const vindsperreQuantities = calculateVindsperreQuantity(materials.wallArea, vindsperreType);
            
            for (let vindsperre of vindsperreQuantities) {
                const product = productsDatabase.find(p => p.id === vindsperre.id);
                
                if (!product) {
                    console.warn(vindsperreType, "product not found in database:", vindsperre.id);
                } else {
                    cartItems.push({
                        product,
                        quantity: vindsperre.quantity
                    });
                }
            }
        });
    }
 // Calculating for kledning
 // Calculating for kledning
 if (materials.selectedKledningProduct && materials.wallArea) {
    // Extracting the dimension information from the product name
    const dimensionRegex = /(\d+x\d+)/i;
    const matchedDimension = materials.selectedKledningProduct.productName.match(dimensionRegex);

    let matchedCoverageKey;
    if (matchedDimension && matchedDimension[1]) {
        matchedCoverageKey = matchedDimension[1].toLowerCase(); // Convert to lowercase to match the keys in KLEDNING_COVERAGE
    }

    if (matchedCoverageKey && KLEDNING_COVERAGE[matchedCoverageKey]) {
        const requiredCoveragePerM2 = KLEDNING_COVERAGE[matchedCoverageKey].coverage;
        
        totalCoverageRequired = requiredCoveragePerM2 * materials.wallArea; // total linear meters of kledning needed

        const kledningProduct = productsDatabase.find(product => product.id === materials.selectedKledningProduct.id);

        if (!kledningProduct) {
            console.warn("Kledning product not found in database:", materials.selectedKledningProduct.id);
        } else {
            cartItems.push({
                product: kledningProduct,
                quantity: Math.ceil(totalCoverageRequired) // Adjusting the quantity to nearest higher number if needed
            });
        }
    } else {
        console.warn("Matching kledning coverage not found for:", matchedCoverageKey);
    }
}

//calculations for REKKER
const rekker23cm = productsDatabase.find(product => product.id === REKKER['23cm']);
const rekker36cm = productsDatabase.find(product => product.id === REKKER['36cm']);


if (materials.selectedKledningOrientering === "liggende") {
    cartItems.push({
        product: rekker23cm,
        quantity: Math.ceil(calculateLekterQuantity(materials.wallLength, materials.wallHeight, materials.wallHeightSide, materials.wallHeightCenter, materials.selectedWall))
    });
} else if (materials.selectedKledningOrientering === "stående") {
    cartItems.push({
        product: rekker23cm,
        quantity: Math.ceil(calculateLekterQuantity(materials.wallLength, materials.wallHeight, materials.wallHeightSide, materials.wallHeightCenter, materials.selectedWall))
    });
    cartItems.push({
        product: rekker36cm,
        quantity: Math.ceil(calculateLekterQuantity(materials.wallLength, materials.wallHeight, materials.wallHeightSide, materials.wallHeightCenter, materials.selectedWall) * 0.7)
    });
}


// Calculations for nails
const spiker = productsDatabase.find(product => product.id === NAILS);
if (materials.selectedKledningProduct && materials.wallArea) {
    const totalNailsRequired = Math.ceil(totalCoverageRequired / 0.6);
    const boxesOfNailsRequired = Math.ceil(totalNailsRequired / 300);
    
    cartItems.push({
        product: spiker,
        quantity: boxesOfNailsRequired
    });
}

//calculations for vinsdperretape   (se om denne må korrigeres. quantity er kanskje feil)
const vindsperretape = productsDatabase.find(product => product.id === VINDSPERRETAPE)
if(materials.selectedVindsperreOptions && materials.selectedVindsperreOptions.length > 0){
    if(materials.wallHeightCenter){
            const amount = Math.ceil(((materials.wallLength / 1.6) * materials.wallHeightCenter)/25)
            cartItems.push({
                product:vindsperretape,
                quantity:amount
            })
    } else{
        const amount = Math.ceil(((materials.wallLength / 1.6) * materials.wallHeight)/25)
        cartItems.push({
            product:vindsperretape,
            quantity:amount
        })
    }
}



//console.log(cartItems);

const mergedCartItems = cartItems.reduce((accumulated, currentItem) => {
    const existingItem = accumulated.find(item => item.product && currentItem.product && item.product.id === currentItem.product.id);
    if (existingItem) {
        existingItem.quantity += currentItem.quantity;
    } else {
        accumulated.push(currentItem);
    }
    return accumulated;
}, []);

const filteredMergedCartItems = mergedCartItems.filter(item => item.product !== undefined);

setCartKledning(filteredMergedCartItems);
//console.log(filteredMergedCartItems);


    }
    

   // Additional helper function to compute vindsperre
function calculateVindsperreQuantity(area, vindsperreType) {
    const sizes = ['large', 'medium', 'small'];
    let remainingArea = area;
    const result = [];

    // Check if vindsperreType is valid
    if (!VINDSPERRE[vindsperreType]) {
        console.warn("Invalid vindsperre type:", vindsperreType);
        return result;
    }

    // If it's 'asfalt' or 'gips', process as default and return
    if (vindsperreType === 'asfalt' || vindsperreType === 'gips') {
        const quantity = Math.ceil(area / VINDSPERRE[vindsperreType].default.coverage);
        result.push({
            id: VINDSPERRE[vindsperreType].default.id,
            quantity: quantity
        });
        return result;
    }

    // For 'vindsperre', calculate based on sizes
    for (let size of sizes) {
        const coverage = VINDSPERRE[vindsperreType][size].coverage;
        const quantity = Math.floor(remainingArea / coverage);

        if (quantity > 0) {
            result.push({
                size: size,
                quantity: quantity,
                coverage: coverage,
                id: VINDSPERRE[vindsperreType][size].id
            });
            remainingArea -= quantity * coverage;
        }
    }

    return result;

}

    return (
        <StyledDiv>
                  <CartItemsList />

  
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    // Your styles go here...
`;
