import React from 'react'
import styled from "styled-components"

export default function personvernvilkår() {
  return (
    <StyledDiv>
      <div className='container my-5'>
        <h3>Salgsvilkår</h3>
        <ol>
          <li><strong>Definisjon av tjenesten:</strong> MinOppussing.no, heretter referert til som "tjenesten", er en webplattform som innhenter tilbud fra ulike leverandører og formidler disse tilbudene til brukere/kunder. Tjenesten selger ikke byggevarer eller fysiske produkter, men leverer en tjeneste.</li>
          <li><strong>Aksept av vilkår:</strong> Ved å bruke tjenesten, uavhengig av plattform og enhet, godtar du disse salgsvilkårene samt vår personvernerklæring. Hvis du ikke aksepterer disse vilkårene i sin helhet, har du ikke rett til å bruke tjenesten.</li>
          <li><strong>Parter:</strong> Tjenesten henter inn tilbud fra tredjepart (byggevarehus) i hele Norge. Disse tilbudene formidles til kunder via tjenesten.</li>
          <li><strong>Betaling:</strong> Kunden betaler for å få tilgang til tilbudene fra tredjepart. Kunden velger selv om han/hun ønsker å betale for denne tjenesten. Når kunden har gjort en forespørsel, innhenter tjenesten tilbud fra lokale byggevarehus.</li>
          <li><strong>Levering:</strong> Leveringen av det kunden kjøper forekommer på tjenesten, som gir tilgang til tilbudsfiler fra lokale byggevarehus.</li>
          <li><strong>Angrerett, retur, reklamasjon, og konflikt:</strong> Det er i utgangspunktet ingen angrerett, ettersom kunden har "brukt" produktet tjenesten selger så snart tilbudsfilene er mottatt. Hvis kunden er misfornøyd med tilbudet eller på annen måte føler at han/hun ikke fikk det han/hun betalte for, tilbyr tjenesten full refusjon.</li>
          <li><strong>Ansvarsfraskrivelse:</strong> Plattformen og alt innhold, tjenester, og tilbud på vår nettside tilbys "som de er" og "som tilgjengelig". Vi tar forbehold om trykkfeil, skrivefeil, og systemfeil som kan føre til feil visning av produkter, priser, lagerbeholdning, og lignende.</li>
          <li><strong>Generelle krav:</strong> Vi kan endre, oppdatere, og legge ut nytt innhold på tjenesten og tjenestens vilkår når som helst, uten å varsle deg. Du er ansvarlig for å holde deg oppdatert på disse vilkårene. Din bruk av tjenesten innebærer at du godtar eventuelle endringer i disse vilkårene.</li>
        </ol>

        <h3>Personvern</h3>
        <p>MinOppussing.no AS ("tjenesten") er forpliktet til å beskytte personvernet til brukerne av våre tjenester. Denne personvernerklæringen forklarer hvilke personopplysninger vi samler inn, hvorfor vi samler dem inn og hvordan vi beskytter dem i samsvar med gjeldende personvernlovgivning, inkludert GDPR.</p>
        <ol>
          <li><strong>Behandlingsansvarlig:</strong> MinOppussing er ansvarlig for behandling av personopplysninger i forbindelse med kjøp av våre varer og tjenester.</li>
          <li><strong>Personopplysninger vi samler inn:</strong> Vi samler inn personopplysninger som navn, adresse, telefonnummer, e-postadresse, informasjon om kjøpte produkter/tjenester og inngåtte avtaler.</li>
          <li><strong>Formål med behandlingen:</strong> Vi behandler personopplysninger for å kunne tilby våre tjenester, oppfylle kjøpsavtaler, administrere garantier, utføre markedsføring, tilby kreditt og oppfylle lovpålagte forpliktelser.</li>
          <li><strong>Grunnlag for behandlingen:</strong> Vi behandler personopplysninger basert på personvernforordningens artikkel Art 6 (b) for å oppfylle kjøpsavtalen og Art 6 (a) der du har samtykket til å motta informasjon fra oss.</li>
          <li><strong>Underleverandører (databehandlere):</strong> Vi bruker underleverandører for å kunne levere våre tjenester, og pålegger dem å beskytte personopplysningene.</li>
          <li><strong>Overføring av data til tredjeparter:</strong> Vi deler personopplysninger med tredjeparter, som transportleverandører, vareleverandører, banker/forsikringsselskaper og kredittkortselskaper, kun for å oppfylle våre forpliktelser.</li>
          <li><strong>Sletteplikt:</strong> Vi lagrer personopplysninger så lenge det er nødvendig for å oppfylle de formålene opplysningene ble samlet inn for, eller så lenge loven pålegger oss.</li>
          <li><strong>Rettigheter:</strong> Du har rett til innsyn, retting og sletting av personopplysninger. Du har også rett til å trekke tilbake samtykker, klage på vår behandling og kreve begrensning i behandlingen av personopplysninger.</li>
        </ol>
        <p>Henvendelser: For spørsmål eller forespørsler om personopplysningene dine, kontakt oss på: post@pusse.no.</p>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  ol {
    li {
      font-weight: 400;
    }
  }
`
