import React, {useState} from 'react';
import styled from "styled-components";
import Sidebar from './Sidebar';
import {Link} from "react-router-dom"

export default function NavTwo() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledDiv>
      <div className='d-flex flex-column mainWrapper'>
        <div className='d-flex justify-content-start  h-100'>
          <Sidebar/>

          <Hamburger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            <span />
            <span />
            <span />
          </Hamburger>

          <MenuLinks isOpen={isOpen}>
          
          <Link to="/prosjektkalkulator" className='link' onClick={() => setIsOpen(false)}><div className='px-5 items'>Prosjektkalkulator</div></Link>
          <Link to="personvernvilkår" className='link' onClick={() => setIsOpen(false)}><div className='px-5 items optional'>Personvern & vilkår</div></Link>
          <Link to="/kontakt" className='link' onClick={() => setIsOpen(false)}><div className='px-5 items optional'>Kontakt</div></Link>
          <Link to="/hvordan" className='link' onClick={() => setIsOpen(false)}><div className='px-5 items optional'>Hvordan</div></Link>
          </MenuLinks>


        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`

.link{
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent
}

  .mainWrapper {
    width: 100%;
    height: 34px;
    background-color: #f0f0f0;
    border-bottom: 0.4px solid #868686a1;
    overflow: hidden;
  }

  .items {
    padding-top: 7px;
    font-size: 12px;
    padding-bottom:8px;
    white-space: nowrap;
    overflow: hidden;

  }

  .items:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  /* Hide the optional items on small screens 
  @media (max-width: 768px) {
    .items.optional {
      display: none;
    }
  }*/
`;

const Hamburger = styled.div`
  display: none;
  margin-left: auto;
  @media (max-width: 772px) {
    display: block;
  }
  
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  margin-right: 10px;
  margin-top:6px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: ${props => props.isOpen ? '7px' : '0px'};
    transform: ${props => props.isOpen ? 'rotate(135deg)' : 'rotate(0deg)'};
  }

  span:nth-child(2) {
    top: 7px;
    opacity: ${props => props.isOpen ? 0 : 1};
    left: ${props => props.isOpen ? '60px' : '0px'};
  }

  span:nth-child(3) {
    top: ${props => props.isOpen ? '7px' : '14px'};
    transform: ${props => props.isOpen ? 'rotate(-135deg)' : 'rotate(0deg)'};
  }
  `;

const MenuLinks = styled.div`
  display: flex;

  @media (max-width: 772px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    width: 250px;
    border-bottom: 0.4px solid #868686a1;
    background-color: #f0f0f0;
    position: absolute;
    top: 94px;
    right: 0px;
    transition: left 0.3s ease-in-out;
    box-sizing: border-box;
    z-index:10;
    font-size:12px;
    
    p, a {
      cursor: pointer;
    }

    p:hover, a:hover {
      color: tomato;

    }
  }
`;