import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import AdminNav from './AdminNav';
import styled from "styled-components"

export default function Byggevarehus() {
  const [byggevarehus, setByggevarehus] = useState([]);
  const navigate = useNavigate(); // Notice the use of useNavigate

  const fetchByggevarehus = async () => {
    const byggevarehusSnapshot = await getDocs(collection(db, 'byggevarehus'));
    const byggevarehusData = byggevarehusSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setByggevarehus(byggevarehusData);
  };

  useEffect(() => {
    fetchByggevarehus();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this Byggevarehus?")) {
      await deleteDoc(doc(db, 'byggevarehus', id));
      // Fetch data again after delete operation to update the UI
      fetchByggevarehus();
    }
  }

  const handleEdit = (id) => {
    // navigate to the edit page, passing the id in the url
    navigate(`/admin/registerbyggevarehus/${id}`);
  }

  return (
    <StyledDiv>
        <AdminNav/>

      <h2>Byggevarehus</h2>
      <Link to="/admin/registerbyggevarehus"><button className='btn btn-info'>Register Byggevarehus</button></Link>
      
      <table>
        <thead>
          <tr>
            <th>Navn byggevarehus</th>
            <th>Adresse</th>
            <th>Tlf</th>
            <th>Handling</th>
          </tr>
        </thead>
        <tbody>
          {byggevarehus.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.address}</td>
              <td>{item.phoneNumber}</td>
              <td>
                <button onClick={() => handleEdit(item.id)} className='btn btn-outline-warning me-5'>Edit</button>
                <button onClick={() => handleDelete(item.id)} className='btn btn-outline-danger'>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledDiv>
  );
}


const StyledDiv = styled.div`

table td {
    padding-right:  50px; /* Adjust the right and left padding as per your needs */
}

table {
    border-spacing: 0 20px; /* This adds 20px spacing between columns */
}
`