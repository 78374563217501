import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { db } from '../firebase/firebase';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import PriceInfoIcon from '../components/PriceInfoIcon';

export default function Products() {
  const { breadcrumb1, breadcrumb2, breadcrumb3 } = useParams();
  const [products, setProducts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [sortOrder, setSortOrder] = useState('');
  

  const fetchProducts = async (startAfterDoc = null) => {
    const productCollection = collection(db, 'products');

    if (breadcrumb1 === breadcrumb2 && breadcrumb2 === breadcrumb3) {
      const q1 = query(productCollection, where("breadcrumb1", "==", breadcrumb1), orderBy("productName"), startAfter(startAfterDoc), limit(50));
      const q2 = query(productCollection, where("breadcrumb2", "==", breadcrumb2), orderBy("productName"), startAfter(startAfterDoc), limit(50));
      const q3 = query(productCollection, where("breadcrumb3", "==", breadcrumb3), orderBy("productName"), startAfter(startAfterDoc), limit(50));

      const [snapshot1, snapshot2, snapshot3] = await Promise.all([getDocs(q1), getDocs(q2), getDocs(q3)]);
      
      const lastVisible = snapshot1.docs[snapshot1.docs.length - 1] || snapshot2.docs[snapshot2.docs.length - 1] || snapshot3.docs[snapshot3.docs.length - 1];
      setLastVisible(lastVisible);

      const productList = [...snapshot1.docs, ...snapshot2.docs, ...snapshot3.docs].map(doc => doc.data());
      setProducts(productList);
    } else {
      let q;
  
      if (breadcrumb3 && breadcrumb2 !== breadcrumb3) {
        q = query(productCollection, 
                  where("breadcrumb1", "==", breadcrumb1), 
                  where("breadcrumb2", "==", breadcrumb2),
                  where("breadcrumb3", "==", breadcrumb3),
                  orderBy("productName"),
                  startAfter(startAfterDoc || null),
                  limit(50));
      } else if (breadcrumb2) {
        q = query(productCollection, 
                  where("breadcrumb1", "==", breadcrumb1), 
                  where("breadcrumb2", "==", breadcrumb2),
                  orderBy("productName"),
                  startAfter(startAfterDoc || null),
                  limit(50));
      } else {
        q = query(productCollection, 
                  where("breadcrumb1", "==", breadcrumb1),
                  orderBy("productName"),
                  startAfter(startAfterDoc || null),
                  limit(50));
      }
    
      const productSnapshot = await getDocs(q);
      const lastVisible = productSnapshot.docs[productSnapshot.docs.length - 1];
      setLastVisible(lastVisible);
      const productList = productSnapshot.docs.map(doc => doc.data());
      console.log(productList)
      setProducts(productList);
    }
  };
  
  useEffect(() => {
    fetchProducts();
  }, [breadcrumb1, breadcrumb2, breadcrumb3]);

  useEffect(() => {
    if (products.length > 0) {
      sortProducts();
    }
  }, [sortOrder]);

  const sortProducts = () => {
    let sortedProducts = [...products];
    if (sortOrder === 'priceLowToHigh') {
      sortedProducts.sort((a, b) => parseFloat(a.byggmakkerPrice) - parseFloat(b.byggmakkerPrice));
    } else if (sortOrder === 'priceHighToLow') {
      sortedProducts.sort((a, b) => parseFloat(b.byggmakkerPrice) - parseFloat(a.byggmakkerPrice));
    }
    setProducts(sortedProducts);
  };
  

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleLoadMore = () => {
    fetchProducts(lastVisible);
  };

  return (
    <Container>
      <div className="breadcrumbs my-4">
        <StyledLink to={`/${breadcrumb1}`}>{breadcrumb1}</StyledLink>
        {breadcrumb2 && <> / <StyledLink to={`/${breadcrumb1}/${breadcrumb2}`}>{breadcrumb2}</StyledLink></>}
        {breadcrumb3 && <> / {breadcrumb3}</>}
      </div>
      <SelectContainer>
        <StyledSelect  onChange={handleSortChange} value={sortOrder}>
          <option value="">Sorter</option>
          <option value="priceLowToHigh">Pris: Lav til Høy</option>
          <option value="priceHighToLow">Pris: Høy til Lav</option>
        </StyledSelect>
        </SelectContainer>

      <Row xs={2} md={2} lg={5} className="g-4">
        {products.map((product, idx) => (
          <Col key={idx} >
          <StyledLink to={`/${breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`}>
              <StyledCard>
                <Card className='card'>
                  <Card.Img variant="top" src={product.imageSrc} />
                  <Card.Body className='bodyWrapper'>
                    <Card.Title>{product.productName}</Card.Title>
                    <PriceUnitContainer>
                    <PriceInfoIcon infoText={"Alle priser er basert på ca. utsalgspriser fra de store byggevarekjedene."}/>
                      <PriceText>Kr {product.byggmakkerPrice}</PriceText>
                      <UnitText>{product.unit}</UnitText>
                    </PriceUnitContainer>
                    <Advantages>
                      {product.advantages.map((adv, idx) => <li key={idx}>{adv}</li>)}
                    </Advantages>
                  </Card.Body>
                </Card>
              </StyledCard>
            </StyledLink>
          </Col>
        ))}
      </Row>
      <div className='w-100 text-center'>
        {lastVisible && products.length >= 50 && <button onClick={handleLoadMore} className='btn btn-outline-secondary my-4'>Last inn flere</button>}
      </div>
    </Container>
  )
}

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end; // Align to the right
  margin-bottom: 15px; // Adjust as needed
`;

const StyledSelect = styled.select`
  width: 30%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;


const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;
    @media(max-width:397px){
      font-size:1em ;
    }
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
    @media(max-width:397px){
      font-size:1em ;
    }
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;

const StyledLink = styled(Link)`
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent

  &:hover{
    color:tomato;
}
`;

const StyledCard = styled.div`
  cursor: pointer;
  .card:hover{
    border:1px solid gray;
    scale:1.01 ;
  }
  .body{
    padding:12px;
  }

  .bodyWrapper{
    padding:16px;
    @media(max-width:388px){
      padding:9px;
    }
    @media(max-width:318px){
      padding:2px;
    }
  }
`;

