import React, { useState, useEffect, useContext } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card, Row } from 'react-bootstrap';
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import glava from "../../images/glavaIcon.webp"
import rockwool from "../../images/rockwoolIcon.webp"
import { AuthContext } from '../../context/AuthContext';
import LettveggProducts from './LettveggProducts';
import FineProdukterLettvegg from './FineProdukterLettvegg';


export default function Lettvegg() {
    const [platerOptions, setPlaterOptions] = useState([]);
    const [wallLength, setWallLength] = useState('');
    const [wallThickness, setWallThickness] = useState('48x98cm');
    const [selectedPlateProduct, setSelectedPlateProduct] = useState()
    const [selectedInsulation, setSelectedInsulation] = useState("glava")
    const [trigger, setTrigger] = useState(false)

    const {  setMaterialsLettvegg   } = useContext(AuthContext);


    useEffect(() => {
        async function fetchProductsById() {
            const productRef = collection(db, 'products');
            let products = [];
            
            for (let item of platerProductMapping) {
                const docRef = doc(productRef, item.plate);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    products.push(docSnapshot.data());
                }
            }
            setPlaterOptions(products);
        }
        fetchProductsById();
    }, []);
    

     const handleWallLengthChange = (e) => {
        setWallLength(e.target.value);
    }

    const handleInsulationClick = (insulationType) => {
        setSelectedInsulation(insulationType);
    };

    const handleWallThicknessChange = (e) => {
        const selectedThickness = e.target.value;
        setWallThickness(selectedThickness);
        
    }
    

    const handleOpenProduct = (event, product) => {
        event.preventDefault();
        const url = `/${product.breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`;
        window.open(url, "_blank");
      };


      const platerProductMapping = [
        {plate:"D1M70HnHJ9nY6ICTk1I0", screwsNeeded:20, screwType: 'sponScrew'},
        {plate:"S9wZZMpNL7bwxy3D7k19", screwsNeeded:20, screwType: 'sponScrew'},
        {plate:"aY2QJffze85kpDZC11cO", screwsNeeded:20, screwType: 'sponScrew'},
        {plate:"rR7SLmz70iZ8Mkx8VRVw", screwsNeeded:20, screwType: 'sponScrew'},
        {plate:"aF9QtYzepvpb2Ud24pTc", screwsNeeded:34, screwType: 'gipsScrew'},
        {plate:"Ihun1i02O6O5tqYNp7dC", screwsNeeded:20, screwType: 'wallsToPaintScrew'}
    ];
 const SCREWS = {
    gipsScrew: "nukXpSDmQNwnmOqO5WRL", // 250
    gipsScrewBig: "mya0eMan0B1pbJBh2XEc", // 900
    sponScrew: "4jG2D6KSnFHaSjnVWk3b", // 500
    sponScrewBig: "XXozGU3VAzVlhUq40lXW", // 1000
    wallsToPaintScrew: "EP9SCK6t2I2Q926ISTOx" // 200
};

const SCREWS_WOOD = "L83HxoPoQcOCxhD6V1H0"

const LEKTER = {
    '48x48cm': 'MP1tVWKvdMuXUyGOzSWi',
    '36x68cm': 'ag7fiL1HWqFP7Z1hxWa3',
    '48x68cm': 'IAzq0FdhyR01tapP0e28',
    '48x73cm': '2B6WBs82QQhu4cSK5wqx',
    '36x98cm':'m2CEnrN5TNpbPLRgtvda',
    '48x98cm': 'i3fAFZFSXs2DdEluPVbT',
    '36x148cm':'1n90pzqaQ4MVooP8sZyt',
    '48x148cm': '375QkN5MKVGsCi48zDSt',
    
};

const insulationCoverage = {
    glava: {
        "5cm": { coverage: 10.3, id: 'C2Z1Om6KJsIfh1aE2bNn' },  //48x48
        "7cm": { coverage: 7.41, id: 'kCUhz6NV46gnMKtHKmk9' },  //36x68  48x68  48x73
        "10cm": { coverage: 5.36, id: 'pqCEpj2l8nI1S0x3Icxx' }, //36x98  48x98
        "15cm": { coverage: 3.65, id: 'FAQqUdKaQNJD7gFVFkvK' }, //36x148 48x148
    },
    rockwool: {
        "5cm": { coverage: 8.14, id: 'M6dFptRdsIawwD3iaQlS' },      //48x48
        "7cm": { coverage: 5.52, id: 'jhSVkyGf7CafYx6PzNIs' },      //36x68  48x68  48x73
        "10cm": { coverage: 4.14, id: 'jQHfV51tVxS5TWDEJqrW' },     //36x98  48x98
        "15cm": { coverage: 2.76, id: 'HQqgk4mD5AlbdrRRG5Eh' },     //36x148 48x148
    }
};

const insulationThicknessMapping = {
    "48x48cm": "5cm",
    "36x68cm": "7cm",
    "48x68cm": "7cm",
    "48x73cm": "7cm",
    "36x98cm": "10cm",
    "48x98cm": "10cm",
    "36x148cm": "15cm",
    "48x148cm": "15cm"
};


const handleCalculateMaterials = () => {
    let numPlater, totalScrews, screwType, screwPackage, numPackages;

    if (!selectedPlateProduct) {
        console.error("selectedPlateProduct is undefined!");
        return;
    }

    // Determine the number of plates required
    if (selectedPlateProduct.id === "aY2QJffze85kpDZC11cO" || selectedPlateProduct.id === "rR7SLmz70iZ8Mkx8VRVw") {
        numPlater = (wallLength / 0.6)*2;
    } else {
        numPlater = (wallLength / 1.2)*2;
    }

    // Find the mapping for the selected plate
    const selectedPlateMapping = platerProductMapping.find(item => item.plate === selectedPlateProduct.id);
    if (selectedPlateMapping) {
        screwType = selectedPlateMapping.screwType;
        totalScrews = numPlater * selectedPlateMapping.screwsNeeded;

        // Determine screw package based on total screws
        if (screwType === 'gipsScrew') {
            if (totalScrews > 250) {
                screwPackage = SCREWS.gipsScrewBig;
                numPackages = Math.ceil(totalScrews / 900);  // number of screws in the big package
            } else {
                screwPackage = SCREWS.gipsScrew;
                numPackages = Math.ceil(totalScrews / 250);  // number of screws in the small package
            }
        } else if (screwType === 'sponScrew') {
            if (totalScrews > 500) {
                screwPackage = SCREWS.sponScrewBig;
                numPackages = Math.ceil(totalScrews / 1000); // number of screws in the big package
            } else {
                screwPackage = SCREWS.sponScrew;
                numPackages = Math.ceil(totalScrews / 500);  // number of screws in the small package
            }
        } else {
            screwPackage = SCREWS.wallsToPaintScrew; // Assumes only one package size for this screw type
            numPackages = Math.ceil(totalScrews / 200); // number of screws in this package
        }
    }

    let requiredLekterMeters = 2.4 * 2 + wallLength * 2 + wallLength / 0.6;
    let requiredWoodScrews = 4.2 * 8 + wallLength * 2 + (wallLength / 0.6 * 4);

    // Parse wallThickness to get the desired format for insulation thickness
    const parsedWallThickness = insulationThicknessMapping[wallThickness.trim()];

    if (!parsedWallThickness) {
        console.error(`Wall thickness ${wallThickness} not mapped!`);
        return;
    }
    

    // New insulation data checks
    const insulationData = insulationCoverage[selectedInsulation];
    if (!insulationData) {
        console.error(`Insulation type ${selectedInsulation} not found!`);
        return;
    }
    
    const insulationInfo = insulationData[parsedWallThickness];
    if (!insulationInfo) {
        console.error(`Insulation thickness ${parsedWallThickness} not found for ${selectedInsulation}!`);
        return;
    }
    

    const { id: insulationId, coverage: selectedInsulationCoverage } = insulationInfo;

    const totalWallArea = 2.4 * wallLength;
    const requiredInsulationPackages = Math.ceil(totalWallArea / selectedInsulationCoverage);

    // Update the materials state
    const newMaterialsLettvegg = {
        lekter: {
            id: LEKTER[wallThickness],
            quantity: requiredLekterMeters,
        },
        screwsForBoards: {
            id: screwPackage,
            quantity: numPackages
        },
        screwsForLekter: {
            id: SCREWS_WOOD,
            quantity: Math.ceil(requiredWoodScrews / 200)
        },
        insulation: {
            id: insulationId,
            quantity: requiredInsulationPackages
        },
        plates: {
            id: selectedPlateProduct.id,
            quantity: Math.ceil(numPlater)
        }
    };

    setMaterialsLettvegg(newMaterialsLettvegg);
    setTrigger(true)
    scrollToBottom()
}


const scrollToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
    });
}





    return (
        <StyledDiv>
            <div className='container'>
                <div className='breadcrumbs my-4'>
                    <StyledLink to="/prosjektkalkulator">Prosjektkalkulator</StyledLink> / 
                    <StyledLink to="/prosjektkalkulator/lettvegg">Lettvegg</StyledLink>
                </div>
                <h3>Bygge Lettvegg</h3>
                <p>Denne kalkulatoren gir et estimat over hvilke materialer du trenger for å bygge en lettvegg. 
                   Det er viktig å huske at kalkulatoren kun gir et estimat for ditt materialebehov. 
                   Kalkulatoren tar utgangspunkt i at veggen er 2.4 meter høy.
                </p>

                <section>
                    <div className='input-container'>
                        <label>Hvor lang vegg skal du bygge?</label>
                        <input type="number" value={wallLength} onChange={handleWallLengthChange} />
                        <span> meter</span>
                    </div>
                </section>  

                <section>
                    <div className='input-container'>
                        <label>Hvor tykk vegg ønsker du?</label>
                        <select value={wallThickness} onChange={handleWallThicknessChange}>
                            <option value="48x48cm">48x48cm</option>
                            <option value="36x68cm">36x68cm</option>
                            <option value="48x68cm">48x68cm</option>
                            <option value="48x73cm">48x73cm</option>
                            <option value="36x98cm">36x98cm</option>
                            <option value="48x98cm">48x98cm</option>
                            <option value="36x148cm">36x148cm</option>
                            <option value="48x148cm">48x148cm</option>
                        </select>      
                    </div>
                </section>

                <section>
                        <div className='insulationOptions'>
                            <label>Hvilken type isolasjon ønsker du?</label>
                            <img src={glava} className={` ${selectedInsulation === "glava" ? "selected" : ""}`} onClick={() => handleInsulationClick("glava")} alt="glava icon"/>
                            <img src={rockwool} className={` ${selectedInsulation === "rockwool" ? "selected" : ""}`} onClick={() => handleInsulationClick("rockwool")} alt="rockwoll icon" />
                        </div>
                    </section>

                <section>
                    <div className='product-cards-container'>
                        <label>Hvilke plater ønsker du på vegge?</label>
                        <Row xs={2} md={2} lg={5} className="g-4">
                            {platerOptions.length > 0 && platerOptions.map(product => (
                                <StyledCard key={product.id}
                                    onClick={() => setSelectedPlateProduct(product)}
                                    className={selectedPlateProduct && selectedPlateProduct.id === product.id ? "selected" : ""}
                                    >
                                    <Card className='card'>
                                        <Card.Img variant="top" src={product.imageSrc} />
                                        <Card.Body className="body">
                                            <Card.Title>{product.productName}</Card.Title>
                                            <PriceUnitContainer>
                                                <PriceText>Kr {product.byggmakkerPrice}</PriceText>
                                                <UnitText>{product.unit}</UnitText>
                                            </PriceUnitContainer>
                                            <Advantages>
                                                {product.advantages.map((adv, idx) => 
                                                    <li key={idx}>{adv}</li>
                                                )}
                                            </Advantages>
                                        </Card.Body>
                                    </Card>
                                    <button className='btn btn-success my-1' onClick={(event) => handleOpenProduct(event, product)}>Se produkt</button>
                                </StyledCard>
                            ))}
                        </Row>
                    </div>
                </section> 

                <section>
                    <button className='btn btn-info buttonRegneUt' onClick={handleCalculateMaterials}>Regn ut materialer</button>
                    <p className='text-center my-3'>Obs. Kalkulatoren antar at du skal ha plater på begge sider av veggen du bygger.</p>
                </section>


            <LettveggProducts/>

           { trigger && <FineProdukterLettvegg/> } 

            </div>
        </StyledDiv>
    )
}



const StyledDiv = styled.div`

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    section{
        background-color: #ecf0f1;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0,0,0,0.1);
        margin-top:15px;
        margin-bottom:15px;
        
        @media(max-width:400px){
                padding:12px!important;
            }
    }

    label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #2c3e50;
    }

    input[type="number"], select {
        width: 50%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #bdc3c7;
        border-radius: 4px;
    }
    .buttonRegneUt{
        width: 100%; 
        text-align: center; 
    }


    .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.input-container label {
    margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-container label {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.input-container input[type="number"] {
    width: auto;
    margin-right: 5px;
}

.input-container span {
    margin-left: 5px;
}

.insulationOptions, .vindsperreOptions {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap; // Add this line to allow the images to wrap to the next line if there's not enough space

    img {
        width: 150px;
        height: auto;
        cursor: pointer;
        margin-right: 30px;
        transition: transform 0.3s ease;
        mix-blend-mode: multiply;

        &:hover {
            transform: scale(1.1);
        }
    }

    .selected {
        transform: scale(1.1);
        border: 3px solid #3498db;
        border-radius: 4px;
    }
}

`



const StyledCard = styled.div`
@media(max-width:400px){
    padding-left:3px;
    padding-right:3px;
}
  cursor: pointer;
  .card:hover{
    border:1px solid gray;
    scale:1.01 ;
  }
  .body{
    padding:12px;
  }


  &.selected{
    border: 3px solid #3498db;
    border-radius:4px ;
    padding:3px;
  }
`;
const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;