import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db, storage } from "../firebase/firebase";
import { collection, addDoc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styled from "styled-components";

function Scraper() {
  const [url, setUrl] = useState("");
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [brandName, setBrandName] = useState("");
  const [breadcrumb1, setBreadcrumb1] = useState("");
  const [breadcrumb2, setBreadcrumb2] = useState("");
  const [breadcrumb3, setBreadcrumb3] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [articleNumbers, setArticleNumbers] = useState([]);
  const [advantages, setAdvantages] = useState([]);
  const [competingPrices, setCompetingPrices] = useState({
    byggmakkerPrice: '',
    monterPrice: '',
    manualMonterPrice: '',
    averagePrice: '',
  });

  const getImageAsBlob = async (url) => {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/jpeg' });
    return blob;
  };

  useEffect(() => {
    const averagePrice = computeAveragePrice();
    setPrice(averagePrice?.toString() || '');
  }, [competingPrices]);

  useEffect(() => {
    if (competingPrices.manualMonterPrice) {
      const averagePrice = computeAveragePrice();
      setPrice(averagePrice?.toString() || '');
    }
  }, [competingPrices.manualMonterPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://us-central1-mop-simple.cloudfunctions.net/scrapeWebsite",
        { url }
      );
      const data = response.data;
      setProductName(data.productName);
      setBrandName(data.brandName);
      setBreadcrumb1(data.breadcrumb1);
      setBreadcrumb2(data.breadcrumb2);
      setBreadcrumb3(data.breadcrumb3);
      setUnit(data.unit);
      setDescription(data.description);

      const blob = await getImageAsBlob(data.imageSrc);
      const imageRef = ref(storage, 'images/' + new Date().getTime() + '.jpg');
      const snapshot = await uploadBytes(imageRef, blob);
      const newImageSrc = await getDownloadURL(snapshot.ref);

      setImageSrc(newImageSrc);
      setArticleNumbers(data.articleNumbers);
      setAdvantages(data.advantages);

      // Fetch the prices from the competing sites
      fetchCompetingPrices();

    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const saveProduct = async () => {
    const product = {
      productName,
      price: computeAveragePrice(),
      brandName,
      breadcrumb1,
      breadcrumb2,
      breadcrumb3,
      unit,
      description,
      imageSrc,
      articleNumbers,
      advantages,
      competingPrices
    };
  
    const productCollection = collection(db, 'products');
    const querySnapshot = await getDocs(query(productCollection, where("productName", "==", productName)));
    
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      if (window.confirm("Product with same name exists. Overwrite?")) {
        await updateDoc(docRef, { ...product, id: docRef.id }); // update the product data and add the doc ID
        console.log("Existing document updated with ID: ", docRef.id);
        console.log("Document added successfully");
        clearFields();
      } else {
        console.log("Operation aborted by user.");
      }
    } else {
      const docRef = await addDoc(collection(db, 'products'), product);
      const productDocRef = docRef.id;
      await updateDoc(docRef, { ...product, id: productDocRef }); // add the product data and doc ID
      console.log("New document written with ID: ", docRef.id);
      console.log("Document added successfully");
      saveCategory(breadcrumb1, breadcrumb2, breadcrumb3);
      console.log("Category function ran")
    }
      clearFields();
  };
  
  
  

  const clearFields = () => {
    setProductName("");
    setPrice("");
    setBrandName("");
    setBreadcrumb1("");
    setBreadcrumb2("");
    setBreadcrumb3("");
    setUnit("");
    setDescription("");
    setImageSrc("");
    setArticleNumbers([]);
    setAdvantages([]);
    setCompetingPrices({
      monterUrl: "",
      monterPrice: "",
      byggmakkerUrl: "",
      byggmakkerPrice: "",
    });
  };

  const handleCompetingPricesChange = (field, value) => {
    setCompetingPrices(prevState => ({ ...prevState, [field]: value }));
  };

 // Fetch the prices from the competing sites
 const fetchCompetingPrices = async () => {
  const sites = [
    { siteName: "byggmakker", url: competingPrices.byggmakkerUrl },
    { siteName: "monter", url: competingPrices.monterUrl },
  ];

  try {
    const response = await axios.post(
      "https://us-central1-mop-simple.cloudfunctions.net/scrapePrices",
      { sites }
    );

    const data = response.data;
    for (const site of data) {
      if (site.site === 'byggmakker') {
        handleCompetingPricesChange(`${site.site}Price`, site.price);
      }
      if (site.site === 'monter' && competingPrices.manualMonterPrice === '') {  // <-- added a check here
        handleCompetingPricesChange(`${site.site}Price`, site.price);
      }
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};


  const computeAveragePrice = () => {
    let numberOfPrices = 0;
    let totalPrice = 0;
  
    if (competingPrices.byggmakkerPrice) {
      numberOfPrices += 1;
      totalPrice += parseFloat(competingPrices.byggmakkerPrice);
    }
    
    if (competingPrices.manualMonterPrice) {
      numberOfPrices += 1;
      totalPrice += parseFloat(competingPrices.manualMonterPrice);
    } else if (competingPrices.monterPrice) {
      numberOfPrices += 1;
      totalPrice += parseFloat(competingPrices.monterPrice);
    }
  
    if (numberOfPrices > 0) {
      return totalPrice / numberOfPrices;
    }
  };
  
  

  const handleManualPricesChange = (field, value) => {
    setCompetingPrices(prevState => ({ ...prevState, [field]: value }));
  };
  
  const saveCategory = async (category1, category2, category3) => {
    const categoryCollection = collection(db, 'categories');

    // Here we're creating a unique identifier for the category based on its hierarchy
    const uniqueId = `${category1}-${category2}-${category3}`;

    // Query Firestore for the category
    const q = query(categoryCollection, where("id", "==", uniqueId));
    const querySnapshot = await getDocs(q);

    // If the category doesn't exist in Firestore, add it
    if (querySnapshot.empty) {
      const category = {
        id: uniqueId,
        breadcrumb1: category1,
        breadcrumb2: category2,
        breadcrumb3: category3
      };
      await addDoc(categoryCollection, category);
    }
  };


  return (
    <StyledDiv className='container-fluid'>
    <div className='row'>
      <div className='col'>
        <h1>Add new product</h1>
        <div className="Scraper">
          <form onSubmit={handleSubmit}>
            <label>
              URL:
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </label>
            <input type="submit" value="Submit" />
          </form>
          <div className='formContent'>
            <p>Product name: <input value={productName} onChange={(e) => setProductName(e.target.value)} /></p>
            <p>Breadcrumb 1: <input value={breadcrumb1} onChange={(e) => setBreadcrumb1(e.target.value)} /></p>
            <p>Breadcrumb 2: <input value={breadcrumb2} onChange={(e) => setBreadcrumb2(e.target.value)} /></p>
            <p>Breadcrumb 3: <input value={breadcrumb3} onChange={(e) => setBreadcrumb3(e.target.value)} /></p>
            <p>Price: <input value={price} readOnly /></p>
            <p>Brand: <input value={brandName} onChange={(e) => setBrandName(e.target.value)} /></p>
            <p>Unit: <input value={unit} onChange={(e) => setUnit(e.target.value)} /></p>
            <p>Description: <input value={description} onChange={(e) => setDescription(e.target.value)} /></p>
            <img src={imageSrc} alt="productImg"/>
            <ul>
              {articleNumbers.map((item, i) => (
                <li key={i}>
                  <input value={item.key} onChange={(e) => {
                    const newArr = [...articleNumbers];
                    newArr[i].key = e.target.value;
                    setArticleNumbers(newArr);
                    }} />:
                    <input value={item.value} onChange={(e) => {
                      const newArr = [...articleNumbers];
                      newArr[i].value = e.target.value;
                      setArticleNumbers(newArr);
                    }} />
                  </li>
                ))}
              </ul>
              <ul>
                {advantages.map((advantage, i) => (
                  <li key={i}><input value={advantage} onChange={(e) => {
                    const newArr = [...advantages];
                    newArr[i] = e.target.value;
                    setAdvantages(newArr);
                  }} /></li>
                ))}
              </ul>
              <button onClick={saveProduct}>Save Product</button>
              <button onClick={clearFields}>Clear</button>
            </div>
          </div>
        </div>
        <div className='col'>
      <h1>Add price scraper</h1>
      <p>
        Competing URL Byggmakker:
        <input value={competingPrices.byggmakkerUrl} onChange={(e) => handleCompetingPricesChange("byggmakkerUrl", e.target.value)} />
        <span>{competingPrices.byggmakkerPrice}</span>
      </p>
      <p>
        Competing URL Monter:
        <input value={competingPrices.monterUrl} onChange={(e) => handleCompetingPricesChange("monterUrl", e.target.value)} />
        <input value={competingPrices.monterPrice || ''} readOnly /> {/* Here's a change */}
        <br/>
        Manually Entered Monter Price:
        <input value={competingPrices.manualMonterPrice || ''} onChange={(e) => handleManualPricesChange("manualMonterPrice", e.target.value)} /> {/* Here's a change */}
      </p>
      <button type="button" onClick={fetchCompetingPrices}>Test Scrape Prices</button>
    </div>
      </div>
    </StyledDiv>
  );
  
  
}

export default Scraper;


const StyledDiv = styled.div`
.formContent{
  float:left ;
}

`
