import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import { collection, getDocs, query, orderBy } from 'firebase/firestore'; // Make sure to import necessary Firestore functions
import { db } from '../firebase/firebase'; // Import your db as well

export default function AdminNav() {
    const [quoteRequests, setQuoteRequests] = useState([]);
    const [newRequestCount, setNewRequestCount] = useState(0); // To store count of new requests

    useEffect(() => {
        const fetchQuoteRequests = async () => {
            const q = query(collection(db, 'quoteRequests'), orderBy('createdAt'));
            const quoteRequestsSnapshot = await getDocs(q);
            const quoteRequestsData = quoteRequestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setQuoteRequests(quoteRequestsData);

            // Counting new requests
            const newRequests = quoteRequestsData.filter(request => request.status === 'new');
            setNewRequestCount(newRequests.length);
        };

        fetchQuoteRequests();
    }, []);

    return (
        <StyledDiv>
            <div className='d-flex flex-column mainWrapper'>
                <Link to="/admin"><h2>Admin panel</h2></Link>
                <div className='d-flex justify-content-start  h-100'>
                    <Link to="/admin/addproduct"><div className='px-5 items'>Legg til produkt</div></Link>
                    <Link to="/admin/requests"><div className='px-5 items optional'>Tilbudsforespørsler {newRequestCount > 0 && <span className="new-request-icon">{newRequestCount}</span>}</div></Link>
                    <Link to="/admin/byggevarehus"><div className='px-5 items optional'>Byggevarehus</div></Link>
                    <Link to="/"><div className='px-5 items optional'>Tilbake til "brukerside"</div></Link>
                </div>
            </div>
        </StyledDiv>
    );
}



const StyledDiv = styled.div`
  .mainWrapper {
    width: 100%;
    height: 100px;
    background-color: #f0f0f0;
    border-bottom: 0.4px solid #868686a1;
    overflow: hidden;
    position:absolute ;
    top:0;
    right:0;
    z-index:100;
  }

  .items {
    padding-top: 7px;
    font-size: 14px;
  }
Link{
  text-decoration: none;
}
  .items:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  .new-request-icon {
    background-color: red; // or any color of your choice
    color: white;
    border-radius: 50%;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

`;
