import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment, collection, query, where, getDocs, addDoc, deleteDoc  } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import Comments from './Comments';
import ProjectCartSingleProject from './ProjectCartSingleProject';
import RedigerProsjekt from './RedigerProsjekt';


export default function SingleProject() {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);

    const { currentUser } = useContext(AuthContext);
    const [hasVoted, setHasVoted] = useState(false); // Track if the user has voted
    const [selectedImage, setSelectedImage] = useState(null);


    useEffect(() => {
        const fetchUserVote = async () => {
            const votesRef = collection(db, "votes");
            const voteQuery = query(votesRef, where("userId", "==", currentUser.uid), where("projectId", "==", projectId));
    
            try {
                const querySnapshot = await getDocs(voteQuery);
                if (!querySnapshot.empty) {
                    const userVote = querySnapshot.docs[0].data().voteType;
                    setHasVoted(userVote); // Store the type of vote ('likes' or 'dislikes')
                }
            } catch (error) {
                console.error("Error fetching user vote: ", error);
            }
        };
    
        if (currentUser) {
            fetchUserVote();
        }
    }, [currentUser, projectId]);

    useEffect(() => {
        const fetchSingleProject = async () => {
          const docRef = doc(db, "projects", projectId);
          try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const projectData = docSnap.data();
              setProject({
                ...projectData,
                likes: projectData.likes || 0,
                dislikes: projectData.dislikes || 0
              });
              console.log(projectData)
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error("Error fetching document:", error);
          }
        };
      
        if (projectId) {
          fetchSingleProject();
        }
      }, [projectId]);
    
      
      const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };
    
      
      const handleVote = async (type) => {
        if (!currentUser) {
            alert("Please log in to vote.");
            return;
        }
    
        const votesRef = collection(db, "votes");
        const voteQuery = query(votesRef, where("userId", "==", currentUser.uid), where("projectId", "==", projectId));
    
        const querySnapshot = await getDocs(voteQuery);
        const projectRef = doc(db, "projects", projectId);
    
        if (!querySnapshot.empty) {
            const voteDoc = querySnapshot.docs[0];
            const currentVoteType = voteDoc.data().voteType;
    
            if (currentVoteType === type) {
                await updateDoc(projectRef, {
                    [type]: increment(-1)
                });
                await deleteDoc(doc(db, "votes", voteDoc.id));
    
                setProject(prevProject => ({
                    ...prevProject,
                    [type]: (prevProject[type] || 0) - 1
                }));
                setHasVoted(false);
            } else {
                await updateDoc(projectRef, {
                    [currentVoteType]: increment(-1),
                    [type]: increment(1)
                });
                await updateDoc(doc(db, "votes", voteDoc.id), {
                    voteType: type
                });
    
                setProject(prevProject => ({
                    ...prevProject,
                    [currentVoteType]: (prevProject[currentVoteType] || 0) - 1,
                    [type]: (prevProject[type] || 0) + 1
                }));
                setHasVoted(type);
            }
        } else {
            await addDoc(votesRef, {
                userId: currentUser.uid,
                projectId: projectId,
                voteType: type
            });
            await updateDoc(projectRef, {
                [type]: increment(1)
            });
    
            setProject(prevProject => ({
                ...prevProject,
                [type]: (prevProject[type] || 0) + 1
            }));
            setHasVoted(type);
        }
    };
    
    
    
 
    if (!project) {
        return <StyledDiv>Loading...</StyledDiv>;
    }

    const calculateSvgProperties = (score, maxScore = 10) => {
        const radius = 20; // Radius of the circle
        const circumference = 2 * Math.PI * radius;
        const normalizedScore = Math.min(score, maxScore); // Ensure score doesn't exceed maxScore
        const filledPortion = (normalizedScore / maxScore) * circumference;
        const emptyPortion = circumference - filledPortion;
      
        return { circumference, emptyPortion };
      };

      function calculateScore(likes, dislikes, comments, shares) {
        // Constants to control the influence of each factor
        const likeWeight = 2;
        const dislikeWeight = 2;
        const commentWeight = 1;
        const shareWeight = 1.5;
        const maxScore = 10;

        likes = likes || 0;
  dislikes = dislikes || 0;
  comments = comments || 0;
  shares = shares || 0;
      
        // Check if there are no interactions at all
        if (likes === 0 && dislikes === 0 && comments === 0 && shares === 0) {
            // Handle this case as needed; for example, return a default score
            return 0; // or any other default value you prefer
        }
      
        // Calculate a raw score based on likes, dislikes, comments, and shares
        let rawScore = (likes * likeWeight - dislikes * dislikeWeight) + 
                       (comments * commentWeight + shares * shareWeight);
      
        // Normalize the raw score using a logarithmic function
        let normalizedScore = Math.log10(rawScore + 1) * maxScore / Math.log10(maxScore);
      
        // Ensure the score is within 0-10 range
        normalizedScore = Math.min(Math.max(normalizedScore, 0), maxScore);
      
        return normalizedScore.toFixed(1); // Rounded to one decimal place
      }
      const projectScore = calculateScore(project.likes, project.dislikes, project.commentCount, project.shareCount);


      return (
        <StyledDiv className='container my-5'>
        <div className='breadcrumbs my-4'>
        <StyledLink to="/prosjekter">Prosjekter</StyledLink> /
        </div>
            <div className="project">

            {currentUser && project.userId && currentUser.uid === project.userId && (
  <Link to={`/RedigerProsjekt/${projectId}`} className="edit-button">
    Rediger
  </Link>
)}
           
           {/*Overlay for image full screen start*/}

{selectedImage && (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px' // Add padding to ensure some space around the image
    }} onClick={() => setSelectedImage(null)}>
        <img src={selectedImage} alt="Full Size" style={{
            maxHeight: '90%', // Larger than before, but leaves space for the close button
            maxWidth: '90%', // This maintains the aspect ratio
            objectFit: 'contain' // Ensures the image is fully visible and retains its aspect ratio
        }} />
        <span style={{
            position: 'absolute',
            top: '10px',
            right: '20px',
            cursor: 'pointer',
            color: 'white',
            fontSize: '30px',
            zIndex: 1001 // Ensures the close button is above the image
        }} onClick={(e) => { e.stopPropagation(); setSelectedImage(null); }}>X</span>
    </div>
)}

           {/*Overlay for image full screen end */}


                <div className="project-container">
                    <div className="project-details">
                        <div className="project-score-and-reactions">
                            <div className="project-score">
                                <div className="score-circle">
                                    <span>{projectScore}</span>
                                    <svg width="50" height="50" className="score-bar" style={{ transform: "rotate(-180deg)", transformOrigin: "center" }}>
                                        <circle
                                            stroke="#13849b"
                                            strokeWidth="8"
                                            fill="transparent"
                                            r="20"
                                            cx="25"
                                            cy="25"
                                            style={{
                                                strokeDasharray: `${calculateSvgProperties(projectScore).circumference}`,
                                                strokeDashoffset: `${calculateSvgProperties(projectScore).emptyPortion}`
                                            }}
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="project-reactions">
                                <div className="reaction-circle" onClick={() => handleVote('likes')}>
                                    <FontAwesomeIcon icon={faThumbsUp} className={`voteIcons ${hasVoted === 'likes' ? 'voted' : ''}`} />
                                    <span>{project.likes}</span>
                                </div>
                                <div className="reaction-circle" onClick={() => handleVote('dislikes')}>
                                    <FontAwesomeIcon icon={faThumbsDown} className={`voteIcons ${hasVoted === 'dislikes' ? 'voted' : ''}`} />
                                    <span>{project.dislikes}</span>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="project-info">
                        <h1>{project.title}</h1>
                        Sist oppdatert {new Date(project.createdAt.seconds * 1000).toLocaleDateString()} av {project.createdBy}
                        <div>
                            <ul>
                                {project.categories.map((category, index) => (
                                    <li key={index}>{category}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div>
                    <p className='project-description'>{project.description}</p>
                </div>
    
                <div className="grid-container">
                    {project.items && project.items.map((item, index) => {
                        if (item.type === 'component') {
                            switch (item.content) {
                                case 'Comments':
                                    return <Comments key={item.id} projectId={projectId} className='grid-item' />;
                                case 'ProjectCart':
                                    if (project.selectedCartId) {
                                        return <ProjectCartSingleProject 
                                                    key={item.id} 
                                                    selectedCartId={project.selectedCartId} 
                                                    cartDescription={item.cartDescription}
                                                    className='grid-item' 

                                        />;
                                    }
                                    break;
                                // Add other cases for different components
                                default:
                                    return null;
                            }
                        } else if (item.type === 'custom') {
                            switch (item.contentType) {
                                case 'text':
                                    return <p key={item.id} className='grid-item'>{item.content}</p>;
                                case 'image':
                                    return <>
                                    {item.contentType === 'image' && (
                                      <div className="image-overlay-container grid-item" key={item.id}>
                                        <img src={item.content} alt={`Item ${index}`} onClick={() => handleImageClick(item.content)}/>
                                        {item.imageDescription && <div className="image-overlay">{item.imageDescription}</div>}
                                      </div>
                                    )}
                                    </>
                                // Add other cases for different custom content types
                                default:
                                    return null;
                            }
                        }
                        return null;
                    })}
                </div>
            </div>
        </StyledDiv>
    );
    
}

const StyledDiv = styled.div`
    padding: 0 15px; /* Adjust padding as needed */
    margin: 0 auto; /* Center the container */

    * {
    box-sizing: border-box;
}

.imageDesctiptionPrev{
  white-space: pre-line;
}
.image-overlay-container {
    position: relative;
    width: 100%; /* Full width of the grid column */
    height: auto; /* Height as per content */
    overflow: hidden; /* Prevents overflow of child elements */
}

.image-overlay-container img {
    width: 100%; /* Full width of its container */
    height: auto; /* Maintain aspect ratio */
    display: block;
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    background: rgba(0, 0, 0, 0.65); /* Semi-transparent black background */
    color: white;
    padding: 10px;
    font-size: 0.8rem; /* Adjust as needed */
    text-align: center; /* Center the text */
}


    .project-top-container {
    display: flex;
    align-items: flex-start; // Aligns items to the top
    justify-content: space-between; // Distributes space between items

    .project-info, .project-details {
        flex: 1; // Each section takes equal space
        // Add any necessary padding, margin, etc.
    }
}
    .project-container {
        display: flex;
    flex-wrap: wrap;
    justify-content: center; // Horizontally centers items within the container
    box-sizing: border-box; /* Include padding and borders in width and height */
    width: 100%;

    .project-info, .project-details {
        flex: 1; // Each takes equal width
        min-width: 0; // Allows the flex items to shrink below their content's intrinsic size
        padding: 5px; // Add some padding for spacing (adjust as needed)
        overflow-x:hidden ; // Allows horizontal scrolling if content overflows

    }
}
@media (max-width: 768px) {
    .project-top-container {
        flex-direction: column;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* One column for small screens */
    gap: 20px; /* Space between items */
    padding: 0;
    margin: 0;
@media(max-width: 860px){
    grid-template-columns: 1fr;
}
}

.grid-item {
    max-width: 100%; /* Ensure grid items don't overflow */
    min-width:0;
    padding: 0;
    margin: 0;
    display: flex;
        border: 1px solid #ddd;
        border-radius: 8px;
        flex-shrink: 1;
        word-wrap: break-word;
}

@media(max-width:370px){
    .grid-item{
        max-width:90% ;
    }
}


.grid-item img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the area without distorting the aspect ratio */
    max-height: 400px; /* Maximum height of the image */
    }

    .grid-item p{
        
    }

.voteIcons {
    cursor: pointer;
    color: white; // Default color: ;
}

.voteIcons.voted {
    color: green; // Color when voted
}


    .project {
        padding: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: 500px) {
    .project {
     padding:10px;
    }
}
    

    .project-info h1 {
        color: #2c3e50;
        margin-bottom: 10px;
    }

    .project-info p {
        margin-bottom: 20px;
    }

    .project-details {
        margin-top: 20px;
    }

    .project-score-and-reactions {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px; /* Adjust the space between score and reactions */
}
.reaction-circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #121c2f;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px; /* Space between reaction circles */
    margin-top:3px ;
}

.reaction-circle span {
    margin-left: 5px;
}
.reaction-circle:hover{
    scale:1.05 ;
}

.voteIcons {
    height: 25px; /* Adjust icon size */
}

.project-description {
    margin-top: 20px;
    white-space: pre-line;

}

    .project-reactions span {
        display: inline-block;
        margin-right: 15px;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    li {
        background-color: #233148;
        color: white;
        font-weight: 500;
        border-radius: 15px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: inline-block;
    }

    @media (max-width: 500px) {
    ul {
        padding: 2px 5px;
        margin-right: 2px;
        margin-bottom: 2px;
        font-size:12px ;
    }
    }
    .voteIcons{
        height:20px;
        margin-left:15px;
    }
    .score-circle {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #121c2f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.score-circle span {
    position: absolute;
    z-index: 1;
}

.score-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.score-bar circle {
    stroke: #13849b;
    stroke-width: 8;
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.project-score {
        width: 50px; // Adjust as needed
        display: flex;
        justify-content:center;
        align-items:center ;
    }
    // Add more styles as needed

    @media (max-width: 500px) {
    .project-score-and-reactions {
        gap: 10px; /* Reduce the space between items */
    }

    .reaction-circle {
        width: 35px; /* Smaller width */
        height: 35px; /* Smaller height */
        /* Adjust other styles as needed */
    }

    .reaction-circle span, .voteIcons {
        font-size: 0.7em; /* Smaller font size */
    }

  

    .project-description {
        font-size: 0.7em; /* Smaller font size for the description */
    }

    /* Add any other adjustments for smaller screens here */
}
.breadcrumbs{
    text-decoration: none; // Removes the underline
    color: inherit; // Inherits the color from its parent
    }
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;
