import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProjectCart from './ProjectCart';
import Comments from "./Comments"
import { collection, addDoc,  updateDoc, doc, getDocs } from "firebase/firestore"; 
import { db } from '../../firebase/firebase'; // Adjust this import based on your Firebase configuration
import { AuthContext } from '../../context/AuthContext';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase/firebase'; // Adjust the import based on your Firebase configuration
import {Link, useNavigate} from "react-router-dom"




export default function NyttProsjekt() {
  const [items, setItems] = useState([
    {
      id: 'item-1',
      type: 'component',
      content: 'Comments',
      contentType: 'component',
      isContentEditable: false // False for default items like Comments or ProjectCart
    },
    {
      id: 'item-2',
      type: 'component',
      content: 'ProjectCart',
      contentType: 'component',
      isContentEditable: false, // False for default items like Comments or ProjectCart
      cartDescription:'',
    },
    // ... any other default items
  ]);
        const [projectTitle, setProjectTitle] = useState('');
        const [projectDescription, setProjectDescription] = useState('');
        const [categories, setCategories] = useState([]);
        const [projectKategorier, setProjectKategorier] = useState([]);
        const [selectedCategories, setSelectedCategories] = useState([]);
        const [editType, setEditType] = useState({});
        const [filteredCategories, setFilteredCategories] = useState([]);
        const navigate = useNavigate();
        const [isLoading, setIsLoading] = useState(false);


        const { currentUser, projectCart } = useContext(AuthContext);
 
        const userEmailFull = currentUser && currentUser.email ? currentUser.email.split('@')[0] : "";

  useEffect(()=>{
console.log(items)
  },[items])

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);

    setItems(newItems);
  }

  useEffect(() => {
    const fetchCategories = async () => {
        const querySnapshot = await getDocs(collection(db, "projects"));
        const categoriesSet = new Set();
        querySnapshot.docs.forEach(doc => {
            const project = doc.data();
            project.categories && project.categories.forEach(cat => categoriesSet.add(cat));
        });
        setCategories(Array.from(categoriesSet));
    };

    fetchCategories();
}, []);

const handleEditTypeChange = (itemId, type) => {
  setEditType(prevEditType => ({ ...prevEditType, [itemId]: type }));
};

const handleAddText = (itemId, text) => {
  setItems(items.map(item => 
    item.id === itemId ? { ...item, content: text, contentType: 'text', type: 'custom' } : item
  ));
};


const handleImageDescriptionChange = (itemId, description) => {
  setItems(prevItems => prevItems.map(item => 
    item.id === itemId ? { ...item, imageDescription: description } : item
  ));
};




const uploadImageToStorage = async (file) => {
  if (!file) return null;

  try {
    // Create a storage reference
    const storageRef = ref(storage, `projectImages/${file.name}`);

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);

    // After upload, get the URL of the file
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading file to Firebase Storage", error);
    return null;
  }
};


const handleImageUpload = async (itemId, event) => {
  const file = event.target.files[0];
  if (file) {
    try {
      // Create a storage reference
      const storageRef = ref(storage, `projectImages/${file.name}`);
      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);
      // After upload, get the URL of the file
      const imageUrl = await getDownloadURL(snapshot.ref);

      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, userContent: imageUrl, userContentType: 'image' } : item
      ));
    } catch (error) {
      console.error("Error uploading file to Firebase Storage", error);
    }
  }
};



const handleAddItem = (type, content) => {
  const newItem = {
    id: `item-${items.length + 1}`,
    type: type, // 'text', 'image', or 'component'
    content: content, // This will be the text, URL of the uploaded image, or component name
    isContentEditable: true // Assuming user-added items are always editable
  };
  setItems([...items, newItem]);
};




const handleAddNewItem = () => {
  const newItem = {
    id: `item-${items.length + 1}`,
    type: 'custom',
    content: '', // initial content
    isContentEditable: true, // allow content editing for this item
  };
  setItems([...items, newItem]);
};

  const handleTitleChange = (event) => {
    setProjectTitle(event.target.value);
  };
  
  const handleDescriptionChange = (event) => {
    setProjectDescription(event.target.value);
  };

  const handleProjectKategorier = (event) => {
    const input = event.target.value;
    setProjectKategorier(input);

    if (!input) {
        setFilteredCategories([]);
        return;
    }

    const matchedCategories = categories.filter(category =>
        category.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCategories(matchedCategories);

    if (event.key === 'Enter' || input.endsWith('  ')) {
        const newCategory = input.trim();
        if (newCategory && !selectedCategories.includes(newCategory)) {
            setSelectedCategories([...selectedCategories, newCategory]);
            setProjectKategorier(''); // Clear the input field
        }
    }
};

const handleProjectKategorierChange = (event) => {
    const input = event.target.value;
    setProjectKategorier(input);

    if (!input) {
        setFilteredCategories([]);
        return;
    }

    const matchedCategories = categories.filter(category =>
        category.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCategories(matchedCategories);
};

const handleProjectKategorierKeyDown = (event) => {
    // Check for 'Enter' key or two consecutive spaces
    if (event.key === 'Enter') {
        const newCategory = projectKategorier.trim();
        if (newCategory && !selectedCategories.includes(newCategory)) {
            setSelectedCategories([...selectedCategories, newCategory]);
            setProjectKategorier(''); // Clear the input field after adding the category
        }
    }
};

const handleAddCategory = ()=>{
    handleProjectKategorierChange()
}


const removeCategory = (categoryToRemove) => {
    setSelectedCategories(selectedCategories.filter(category => category !== categoryToRemove));
};

const handleDeleteItem = (itemId) => {
  setItems(items.filter(item => item.id !== itemId));
};


const handleAddNewCart = () => {
  const newCart = {
    id: `item-${Date.now()}`,
    type: 'component',
    content: 'ProjectCart',
    contentType: 'component',
    isContentEditable: true, // Adjust based on your needs
    cartDescription: '' // Empty initially, editable by user
  };
  setItems([...items, newCart]);
};


const handleCartDescriptionChange = (itemId, description) => {
  setItems(items.map(item => 
    item.id === itemId ? { ...item, cartDescription: description } : item
  ));
};

  
const publishProject = async () => {
  const serializableItems = items.map(item => {
    let serializableItem = {
      id: item.id,
      type: item.type,
      content: item.content,
      contentType: item.contentType,
      isContentEditable: item.isContentEditable,
      // Include cartDescription for ProjectCart items
      ...(item.type === 'component' && item.content === 'ProjectCart' && { cartDescription: item.cartDescription }),
      // Include imageDescription if the item is an image
      ...(item.contentType === 'image' && { imageDescription: item.imageDescription }),
    };

    return serializableItem;
  });

  const projectData = {
      title: projectTitle,
      description: projectDescription,
      items: serializableItems,
      selectedCartId: projectCart || [], 
      createdAt: new Date(),
      userId: currentUser.uid, 
      createdBy: userEmailFull,
      likes: 0,
      dislikes: 0,
      commentCount: 0,
      shareCount:0,
      categories: selectedCategories,
  };

    try {
        const docRef = await addDoc(collection(db, "projects"), projectData);
        console.log("Document written with ID: ", docRef.id);

        // Optionally, update the project data with the generated ID
        await updateDoc(doc(db, "projects", docRef.id), { projectId: docRef.id });
        navigate('/prosjekter'); // Navigate on success

        // Handle post-publish actions
    } catch (e) {
        console.error("Error adding document: ", e);
        // Handle error
    }
};


const renderComponent = (item) => {
  switch (item.content) {
    case 'Comments':
      return <Comments />;
    case 'ProjectCart':

    return <ProjectCart cartDescription={item.cartDescription}/>;
    // Add cases for other types of components
    default:
      return null;
  }
};

const handleImageChange = async (itemId, event) => {
  const file = event.target.files[0];
  if (file) {
    const uniqueFilename = `${Date.now()}-${file.name}`; // Using a timestamp
    try {
      const storageRef = ref(storage, `projectImages/${uniqueFilename}`);
      const snapshot = await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(snapshot.ref);

      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, content: imageUrl, contentType: 'image', type: 'custom', imageDescription: '' } : item
      ));
    } catch (error) {
      console.error("Error uploading file to Firebase Storage", error);
    }
  }
};



return (
  <DragDropContext onDragEnd={onDragEnd}>
      <StyledDiv className='container my-5'>
      <div className='breadcrumbs my-4'>
        <StyledLink to="/prosjekter">Prosjekter</StyledLink> / <ActiveLink to="/nyttprosjekt">NyttProsjekt</ActiveLink>
        </div>
          <h3>Opprett nytt prosjekt</h3>
          <div className='topText'>
              <p>Beskriv ditt prosjekt her. Gi prosjektet en tittel, en kort beskrivelse. Du kan også legge til bilder og andre ting.</p>
              <button className='btn btn-outline-primary m-3' onClick={publishProject}>Publiser prosjekt</button>
          </div>

          <div className='firstRow'>
              <div className='defaultItem'>
                  <input
                      type="text"
                      placeholder="Tittel på prosjekt"
                      value={projectTitle}
                      onChange={handleTitleChange}
                      className="titleInput"
                  />
              </div>
              <div className='defaultItem'>
                  <textarea
                      placeholder="Prosjektbeskrivelse"
                      value={projectDescription}
                      onChange={handleDescriptionChange}
                      className="descriptionTextarea"
                  />
              </div>

              <div className='defaultItem'>
                  <div className="input-button-container">
                      <input
                          type="text"
                          placeholder="Kategorier"
                          value={projectKategorier}
                          onChange={handleProjectKategorierChange}
                          onKeyDown={handleProjectKategorierKeyDown}
                          className="titleInput"
                      />
                      <button onClick={handleAddCategory} className="leggTilKnapp btn btn-outline-primary btn-sm m-2">Legg til kategori</button>
                  </div>

                  {projectKategorier && (
                      <div className="autocomplete-options">
                          {filteredCategories.map(category => (
                              <div key={category} onClick={() => setProjectKategorier(category)}>
                                  {category}
                              </div>
                          ))}
                      </div>
                  )}
              </div>

              <div className="selected-categories">
                  {selectedCategories.map((category, index) => (
                      <div key={index} className="selected-category">
                          {category}
                          <span className="remove-category" onClick={() => removeCategory(category)}> X </span>
                      </div>
                  ))}
              </div>
          </div>

          <Droppable droppableId="droppable">
    {(provided) => (
        <div 
            className='dndContainer'
            {...provided.droppableProps}
            ref={provided.innerRef}
        >
            {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                        <div
                            className='gridItem'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            {item.type === 'component' && renderComponent(item)}


                            {item.type === 'component' && item.content === 'ProjectCart' && item.isContentEditable && (
                              <textarea
                                value={item.cartDescription || ''}
                                onChange={(e) => handleCartDescriptionChange(item.id, e.target.value)}
                                placeholder="Beskrivelse av handlekurv"
                                className='my-2'
                              />
                            )}


                            {item.isContentEditable && (
                                <div>
                                    <button onClick={() => handleEditTypeChange(item.id, 'text')} className='btn btn-outline-primary'>Tekst</button>
                                    <button onClick={() => handleEditTypeChange(item.id, 'image')} className='btn btn-outline-primary mx-2'>Bilde</button>

                                    {editType[item.id] === 'text' && (
                                        <textarea onBlur={(e) => handleAddText(item.id, e.target.value)} className='textArea'/>
                                    )}
                                    {editType[item.id] === 'image' && (
                                        <input type="file" onChange={(e) => handleImageChange(item.id, e)} accept="image/*" />
                                    )}
                                    <button className='btn btn-outline-danger deleteButton' onClick={() => handleDeleteItem(item.id)} >Slett</button>
                                </div>
                            )}

                    


                            {item.contentType === 'text' && <div>{item.content}</div>}


                            {item.contentType === 'image' && (
                              <>
                              
                                <img src={item.content} alt="Uploaded" style={{ maxWidth: '100%' }} />
                                {item.imageDescription && <p className='imageDesctiptionPrev'>{item.imageDescription}</p>}

                                <textarea
                                  type="text"
                                  placeholder="Legg til beskrivelse av bilde"
                                  value={item.imageDescription || ''}
                                  onChange={(e) => handleImageDescriptionChange(item.id, e.target.value)}
                                  className="image-description-input"
                                />
                              </>
                            )}



                        </div>
                    )}
                </Draggable>
            ))}
            {provided.placeholder}
        </div>
    )}
</Droppable>

          <button onClick={handleAddItem} className='btn btn-outline-primary my-4 me-4'>Legg til bilde eller tekst</button>
          <button onClick={handleAddNewCart} className='btn btn-outline-primary my-4'>Legg til enda en handleliste</button>



      </StyledDiv>
  </DragDropContext>
);

}

const StyledDiv = styled.div`
.imageDesctiptionPrev{
  white-space: pre-line;

}

.textArea{
  width:100%;
  height:100% ;

  
}
  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .leggTilKnapp{
    white-space: nowrap;
  }
  .selected-categories {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .selected-category {
        display: inline-flex;
        align-items: center;
        background-color: #233148;
        color: white;
        font-weight:500 ;
        border-radius: 15px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;

        .remove-category {
            margin-left: 5px;
            cursor: pointer;
            color: #999;
            &:hover {
                color: #333;
                color:darkred;

            }
        }
    }
  .topText{
    display:flex;
    flex-direction:row ;
    justify-content: space-between ;
  }

  .titleInput, .descriptionTextarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }

  .descriptionTextarea {
    height: 100px; // Adjust height as needed
    resize: vertical; // Allow vertical resize
  }
  .input-button-container {
  display: flex;
  align-items: center; /* Align vertically */
}

  .firstRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .defaultItem {
    flex-grow: 1;
    border: 1px solid #ddd;
    padding: 10px;
    margin-right: 10px; /* Add some space between items */
  }

  .dndContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Default to two columns
    grid-gap: 10px;
    grid-auto-rows: minmax(min-content, max-content);

    @media (max-width: 768px) { // Adjust the breakpoint as needed
      grid-template-columns: 1fr; // One column on small screens
    }
  }

  .gridItem {
    min-height:150px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start; // Align content to start
  }
  .textAreaItem, .imageUploadItem {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}

.textAreaItem {
    height: 150px; /* Adjust the height as needed */
}

.imageUploadItem {
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.deleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
    cursor: pointer;
}
  .dragHandle {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    user-select: none;
  }

  .autocomplete-options {
        position: absolute;
        border: 1px solid #ddd;
        border-top: none;
        z-index: 1000;
        background: white;
        width: 80%;
        max-height: 200px;
        overflow-y: auto;

        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
  
    .breadcrumbs{
    text-decoration: none; // Removes the underline
    color: inherit; // Inherits the color from its parent
    }
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;


const ActiveLink = styled(Link)`
text-decoration: none; // Removes the underline
color: tomato; // Inherits the color from its parent

`;
