import React from 'react'
import Scraper from '../components/Scraper'
import AdminNav from './AdminNav'

export default function AddProduct() {
  return (
    <div>
    <AdminNav/>
    <Scraper/>
    </div>
  )
}
