import React, {useState, useCallback, useContext, useEffect} from 'react'
import styled from "styled-components"
import {Link} from "react-router-dom"
import VeggDesigner from "./VeggDesigner"
import { FaPlus, FaTrash } from "react-icons/fa";
import LecaProducts from './LecaProducts';
import fugearmering from "../../images/Calculator/fugearmering"
import sikksakkarmering from "../../images/Calculator/sikksakkarmering.avif"
import laftestrimel from "../../images/Calculator/laftestrimel.avif"
import FineProdukterLecaVegg from "./FineProdukterLecaVegg";

import { AuthContext } from '../../context/AuthContext';


export default function LecaVegg() {

    const [wallData, setWallData] = useState({
        totalLength: 0,
        numCorners: 0,
        numEnds: 0,
      });
      const [wallHeight, setWallHeight] = useState(0)
      const [windows, setWindows] = useState([]);
      const [groundLevel, setGroundLevel] = useState('over'); // default to 'over'
      const [wallThickness, setWallThickness] = useState('30')
      const [såleblokk, setSåleblokk] = useState('yes')
      const [ublokkTopp, setUblokkTopp] = useState('yes')
      const [faktiskHøyde, setFaktiskHøyde] = useState()

      const {setMaterialsLecaVegg, materialsLecaVegg} = useContext(AuthContext)


      const handleWallDataChange = useCallback((data) => {
        setWallData(data);
    }, []);

    const addWindow = () => {
        setWindows(windows => [...windows, { count: 1, width: 0, height: 0 }]);
      };
      

      const calculateTotalWindowArea = () => {
        return windows.reduce((totalArea, window) => {
          return totalArea + (window.count * window.width/100 * window.height/100);
        }, 0);
      };

      const calculateWallArea = () =>{
        return (wallHeight * wallData.totalLength) - calculateTotalWindowArea().toFixed(2)
      }

      
      const updateWindow = (index, field, value) => {
        const newWindows = windows.map((window, i) => {
          if (i === index) {
            return { ...window, [field]: value };
          }
          return window;
        });
        setWindows(newWindows);
      };
      

      const removeWindow = (index) => {
        setWindows(windows => windows.filter((_, i) => i !== index));
      };
      


          // Constants for dimensions in centimeters
    const SÅLEBLOKK_LENGTH = 50;
    const SÅLEBLOKK_HEIGHT = 19;
    const ISO_HEIGHT = 20;
    const ISO_SHORT_HEIGHT = 9;
    const ISOBLOKK_LENGTH = 50;
    const MULTIBLOKK_LENGTH = 43;
    const MULTIBLOKK_HEIGHT = 20;
    const U_BLOKK_LENGTH = 25;
    const U_BLOKK_HEIGHT = 25;
    const U_BLOKK_OVER_WINDOW_EXTRA = 30;

    
    // Constants for armering and laftestrimle
    const SIKKSAKKARMERING_LENGTH_CM = 300;
    const FUGEARMERING_LENGTH_CM = 400;
    const U_BLOKKARMERING_LENGTH_CM = 400;
    const LAFTEREMSE_PACK_COUNT = 18;
    const FUGE_HEIGHT_UNDER = 0.10; // Height of the fugemasse under the ground
    const FUGE_HEIGHT_OVER = 0.3; // Height of the fugemasse over the ground

    const B20_PER_UBLOKK = 0.24 //sekk
    const B20_PER_SÅLEBLOKK = 0.29 //sekk
    const M5_PER_ISOBLOKK = 0.097 //sekk
    const M5_PER_MULTIBLOKK = 0.085 //sekk
  const handleCalculateMaterials = () => {

    // Convert meters to centimeters for calculations
    const totalLengthCm = wallData.totalLength * 100;
    const wallHeightCm = wallHeight * 100;
  

    //Calculate number of Såleblokk
    let numSåleblokk
    if(såleblokk === "yes"){
         numSåleblokk = Math.ceil(totalLengthCm / SÅLEBLOKK_LENGTH)
    } else{
        numSåleblokk = 0;
    }

    //Calculate number of U-blocks on top
    let numUblokkTopp
    if(ublokkTopp === "yes"){
        numUblokkTopp = Math.ceil(totalLengthCm/U_BLOKK_LENGTH)
    } else{
        numUblokkTopp = 0;
    }

    //Calculate numver of u-blocks over windows
      // Calculate number of U-blokks over windows
  let numUblokkOverWindows = windows.reduce((total, window) => {
    // Calculate the total width for the window including extra space
    const windowWidthWithExtra = window.width + U_BLOKK_OVER_WINDOW_EXTRA;
    // Calculate the number of U-blokks needed for this width, rounded up
    const ublokksForWindow = Math.ceil(windowWidthWithExtra / U_BLOKK_LENGTH);
    // Accumulate the total number of U-blokks needed
    return total + ublokksForWindow;
  }, 0); // Start accumulating from 0

  
 //calculations for number of layers of isoblokk
 let numLayers = 0; 
const effectiveWallHeightCm = Math.max(0, wallHeightCm 
  - (såleblokk === "yes" ? SÅLEBLOKK_HEIGHT : 0)
  - (ublokkTopp === "yes" ? U_BLOKK_HEIGHT : 0));

numLayers = Math.ceil(effectiveWallHeightCm / ISO_HEIGHT);
numLayers = Math.max(0, numLayers);


//calculations for number of multiblocks
let numMultiblokk = 0;
const multiblokkCorner = wallData.numCorners * numLayers
const multiblokkEnd = wallData.numEnds * numLayers

let multiblokkWindow = windows.reduce((total, window)=>{
    const multiblokksForWindow = Math.ceil(window.height / MULTIBLOKK_HEIGHT)
    return total + multiblokksForWindow
},0)
numMultiblokk = multiblokkCorner + multiblokkEnd + (multiblokkWindow * 2)


// Calculations for Isoblokk
let numIsoblokk = 0;
if (numLayers > 0) {
  const totalLengthCm = wallData.totalLength * 100; // Convert meters to cm
  let totalLengthForIsoblokksCm = totalLengthCm * numLayers; // Total length for all layers
  // Subtract the length occupied by Multiblokks
  totalLengthForIsoblokksCm -= numMultiblokk * MULTIBLOKK_LENGTH;
  // Calculate the initial number of Isoblokks, before considering windows
  numIsoblokk = Math.ceil(totalLengthForIsoblokksCm / ISOBLOKK_LENGTH);
  // Calculate the number of Isoblokks to remove for each window
  const isoblokksRemovedForWindows = windows.reduce((total, window) => {
    // Calculate the number of Isoblokks removed for one window, both width and height
    const isoblokksForWindow = Math.ceil(window.width / ISOBLOKK_LENGTH) * 
                                Math.ceil(window.height / ISO_HEIGHT) * 
                                window.count;
    console.log(`Isoblokks removed for a window of width ${window.width}cm and height ${window.height}cm: ${isoblokksForWindow}`);
    return total + isoblokksForWindow;
  }, 0);
  // Subtract the Isoblokks displaced by windows from the initial total
  numIsoblokk -= isoblokksRemovedForWindows;
  // Ensure numIsoblokk is not negative
  numIsoblokk = Math.max(numIsoblokk, 0);
}

//Calculations for armering type Ublokk
const numArmeringSåleblokk = (numSåleblokk * SÅLEBLOKK_LENGTH)/U_BLOKKARMERING_LENGTH_CM
const numArmeringUblokk = ((numUblokkTopp + numUblokkOverWindows) * U_BLOKK_LENGTH ) /U_BLOKKARMERING_LENGTH_CM
let armeringUblokk = Math.ceil(numArmeringSåleblokk + numArmeringUblokk)


//Calculations for armering between isoblokks
let fugearmering;
let laftestrimle;
let sikksakkarmering;


if(groundLevel === "over"){
    laftestrimle = null
    sikksakkarmering = null
    const meterOfFugearmering = Math.ceil(((((numIsoblokk * ISOBLOKK_LENGTH)+ (numMultiblokk*MULTIBLOKK_LENGTH))*1.1) / FUGEARMERING_LENGTH_CM)/2)
    fugearmering = meterOfFugearmering
}
if(groundLevel === "under"){
    fugearmering = null
    const meterOfSikksakkarmaring = Math.ceil(((((numIsoblokk * ISOBLOKK_LENGTH) + (numMultiblokk*MULTIBLOKK_LENGTH))*1.1) / SIKKSAKKARMERING_LENGTH_CM)/2)
    sikksakkarmering = meterOfSikksakkarmaring

    const meterOfLaftestrimle = Math.ceil(((numIsoblokk * ISOBLOKK_LENGTH)+ (numMultiblokk*MULTIBLOKK_LENGTH)) / (LAFTEREMSE_PACK_COUNT*100))
    laftestrimle = meterOfLaftestrimle
}

//Calculations for sement

    const sementB20 = Math.ceil((numSåleblokk * B20_PER_SÅLEBLOKK) +  (numUblokkOverWindows * B20_PER_UBLOKK) + (numUblokkTopp * B20_PER_UBLOKK))
    const sementM5 = Math.ceil((numMultiblokk * M5_PER_MULTIBLOKK) + (numIsoblokk * M5_PER_ISOBLOKK))

    // Compile the result
    let result = {
        numberOfSåleblokks: numSåleblokk,
        numberOfUblokks: numUblokkTopp + numUblokkOverWindows,
        layers: numLayers,
        numberOfMultiblokks: numMultiblokk,
        numberOfIsoblokk: numIsoblokk,
        wallThickness: wallThickness,

        numberOfArmeringTypeUblokk: armeringUblokk,
        numberOfFugearmering:fugearmering,
        numberOfSikksakkarmering: sikksakkarmering,
        numberOfLaftestrimle: laftestrimle,
        numberOfB20: sementB20,
        numberOfM5: sementM5


              // Add other materials calculations if necessary
    }
    setMaterialsLecaVegg(result); // Update the state with the results
    scrollToBottom()

  };

  const handleFaktiskHøyde = () => {
    let faktiskHøyde = 0; // Start with a base height of 0

    if (såleblokk === "yes") {
        faktiskHøyde += SÅLEBLOKK_HEIGHT; // Add the height of the sole block
    }

    if (ublokkTopp === "yes") {
        faktiskHøyde += U_BLOKK_HEIGHT; // Add the height of the top block
    }

    if (materialsLecaVegg.layers > 0) {
        faktiskHøyde += materialsLecaVegg.layers * ISO_HEIGHT; // Add the height of the layers of blocks
    }

    if (groundLevel === "over") {
        faktiskHøyde += materialsLecaVegg.layers * FUGE_HEIGHT_OVER; // Add the height of the mortar joints above ground
    } else {
        faktiskHøyde += materialsLecaVegg.layers * FUGE_HEIGHT_UNDER; // Add the height of the mortar joints below ground
    }

    setFaktiskHøyde(faktiskHøyde); // Set the state with the calculated height
}

useEffect(()=>{
    handleFaktiskHøyde()
},[materialsLecaVegg])

  const scrollToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth"
    });
}

  return (
    <StyledDiv>
        <div className='container'>
            <div className='breadcrumbs my-4'>
             <StyledLink to="/prosjektkalkulator">Prosjektkalkulator</StyledLink> / <StyledLink to="/prosjektkalkulator/Lecavegg">Lecavegg</StyledLink>
            </div>
            <h3>Lecaveggkalkulator for Isoblokk 30 og 35 cm</h3>
            <p>Denne kalkulatoren gir et estimat over nødvendige materialer for å bygge en Lecavegg. Det er essensielt å være oppmerksom på at kalkulatoren kun tilbyr et estimat, og det faktiske behovet kan variere. Det finnes flere metoder og varianter av Lecavegger, denne kalkulatoren beregner materialebehovet for å konstruere en Lecavegg med isoblokk, enten det er over eller under bakkenivå.</p>
            <p>Lecavegger kan ha ulike typer overflatebehandlinger. Vær oppmerksom på at denne kalkulatoren ikke tar høyde for behovet for overflatebehandling, verken på innsiden eller utsiden av veggen. <a href='https://www.leca.no/sites/leca.no/files/pdf/Leca%20Isoblokk%20Monteringsanvisning%20brosjyre_0.pdf' target='blank'>Her kan se lese Leca sin monteringsanvisning for Leca Isoblokk 30 og 35 cm.</a></p>

<br></br>
<h5> Tegn veggens form i boksen under </h5>

<VeggDesigner onWallDataChange={handleWallDataChange}/>


   <section>
   <div className='input-container'>
  <form>
    <label>Hvor høy vegg skal du bygge? {wallHeight.toFixed(2)} meter</label>
    <input
      type="range"
      min="0"
      max="3.64"
      step="0.01" // Allows the slider to move in increments of 0.01 meters
      value={wallHeight}
      onChange={(e) => setWallHeight(parseFloat(e.target.value))}
    />
    <input
      type="number"
      value={wallHeight}
      onChange={(e) => {
        const value = parseFloat(e.target.value);
        setWallHeight(isNaN(value) ? 0 : value); // If not a number, set to default
      }}
      step="0.01" // Matches the slider increment
      min="0"
      max="3"
    />
  </form>

</div>
    {calculateWallArea() > 0 &&  <p>Veggens totale areal: {calculateWallArea().toFixed(2)} m<sup>2</sup></p>}

   </section>

   <section>
   <div className='input-container'>
    <label>Hvor tykk vegg skal du bygge? </label>
    <select
  value={wallThickness}
  onChange={(e) => setWallThickness(e.target.value)}
>
  <option value="30">30 cm</option>
  <option value="35">35 cm</option>
</select>

</div>

   </section>

   <section>
  <label>Hvor store og mange vinduer er det på veggen?</label>
  <button type="button" onClick={addWindow} className='btn btn-outline-primary mx-2'> <FaPlus/> Legg til vinduer</button>
  {windows.map((window, index) => (
    <div key={index} className='window-group'>
      <hr /> {/* This horizontal line will separate each set of window inputs */}
      <div className='input-container'>
        <label>Antall vinduer:</label>
        <input 
          type="number"
          value={window.count}
          onChange={(e) => updateWindow(index, 'count', parseInt(e.target.value, 10))}
        />
      </div>
      <div className='input-container'>
        <label>Bredde på vinduer (cm):</label>
        <input 
          type="number"
          value={window.width}
          onChange={(e) => updateWindow(index, 'width', parseInt(e.target.value, 10))}
        />
      </div>
      <div className='input-container'>
        <label>Høyde på vinduer (cm):</label>
        <input 
          type="number"
          value={window.height}
          onChange={(e) => updateWindow(index, 'height', parseInt(e.target.value, 10))}
        />
      </div>
      <button type="button" onClick={() => removeWindow(index)} className='btn btn-danger'><FaTrash/> Fjern</button>
    </div>
  ))}

  {windows.length > 0 &&  <p>Det totale arealet dekket av vinduer: {calculateTotalWindowArea().toFixed(2)} m<sup>2</sup></p>}
  <p>Når du legger til vinduer beregner kalkulatoren at det skal være Leca U-blokk med U-blokkarmering og B20 betong over vinduet. </p>

</section>

<section>
    <label>Skal veggen bygges over eller under bakkenivå?</label>
    <select
  value={groundLevel}
  onChange={(e) => setGroundLevel(e.target.value)}
>
  <option value="over">Over bakkenivå</option>
  <option value="under">Under bakkenivå</option>
</select>
    {groundLevel === "over" &&  <><p>Over bakkenivå benyttes Laca Fugearmering og murmørtel M5, normal fugehøyde er 3 mm </p> <img src={fugearmering} alt="armering" className='overUnderArmeringImg' /> </>}
    {groundLevel === "under" && <><p>Lecavegg som bygges under bakkenivå hvor det skal fylles etterfylles masse skal ha Leca Sikksakk-armerin og Laftestrimle. Laftestrimle brukes i mellom hvert blokkskift for å isolere fugene i murverket. Murmørtel M5 benyttes, normal fugehøyde under bakken er 10 mm.</p> 
    <img src={sikksakkarmering} alt="armering" className='overUnderArmeringImg' /> 
    <img src={laftestrimel} alt="armering" className='overUnderArmeringImg' /> 
    </>}
    
   
</section>

<section>
    <div>
    <label>Skal fundament ha Såleblokk?</label>

    <select
  value={såleblokk}
  onChange={(e) => setSåleblokk(e.target.value)}
>
  <option value="yes">Ja</option>
  <option value="no">Nei</option>
</select>
 </div>
 <div>
 <label>Skal du ha U-Blokk i øverste skift?</label>
    <select
  value={ublokkTopp}
  onChange={(e) => setUblokkTopp(e.target.value)}
>
  <option value="yes">Ja</option>
  <option value="no">Nei</option>
</select>
    </div>
   
</section>


    <section>
        <button onClick={()=>handleCalculateMaterials()} className='btn btn-info buttonRegneUt' >Regn ut materialer</button>
    </section>

    {materialsLecaVegg && Object.keys(materialsLecaVegg).length > 0 && <LecaProducts/>}

{materialsLecaVegg && Object.keys(materialsLecaVegg).length > 0 && 
(
  <section>
        <label>Veggens faktiske høyde!</label>
        <p>Kalkulatoren runder oppover til nærmeste hele Lecastein fra høyden du har satt inn (<strong>{wallHeight*100} cm</strong>). Det betyr at veggens faktiske høyde kan avvike fra denne høyden.</p>
        <p>Kalkulatoren beregner at veggen din blir omtrent <strong>{faktiskHøyde} cm.</strong></p>
        <p>
  Dette er basert på 
  <strong>{materialsLecaVegg.numberOfSåleblokks ? ' et lag såleblokk i bunn' : ' ingen såleblokk'}, </strong>
  <strong>{materialsLecaVegg.numberOfUblokks ? ' at lag med ublokk på toppen' : ' ingen ublokk på toppen'}, </strong>
  <strong>{materialsLecaVegg.layers}  lag med vanlig Isoblokk. </strong> 
  <strong>{groundLevel === "over" ? '3 mm.' : '10 mm.'}</strong> fuger mellom hvert lag.
</p>
    </section>  
)}
    

    {materialsLecaVegg && Object.keys(materialsLecaVegg).length > 0 && <FineProdukterLecaVegg/>}

        </div>
    </StyledDiv>
  )
}


const StyledDiv = styled.div`

.buttonRegneUt{
        width: 100%; /* Add this line */
        text-align: center; /* Add this line */
    }

font-family: Arial, sans-serif;


h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 15px;
    color: #34495e;
}

section{
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    margin-bottom:15px;
    margin-top:15px;


    @media(max-width:400px){
            padding:12px!important;
        }
}

label {
        margin-bottom: 5px;
        font-weight: bold;
        color: #2c3e50;
    }

    input[type="number"], select {
        width: 50%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #bdc3c7;
        border-radius: 4px;
        margin-left:10px;
        margin-right:10px;
    }


.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.input-container label {
    margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-container label {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.input-container input[type="number"] {
    width: auto;
    margin-right: 5px;
}

.input-container span {
    margin-left: 5px;
}


.overUnderArmeringImg{
    width:200px;
}
    
`

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`