import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from "styled-components"

export default function CasdSkeleton() {
  return (
    <StyledCard className='cardSkeleton'>
    <div className="box">

    </div>
       
        <div className=''>
            <Skeleton count={5} style={{width:"200px", color: "#ffff"}}/>
        </div>

    
    </StyledCard>
  )
}


const StyledCard = styled.div`
  cursor: pointer;
  width: 250px; // set the maximum width of each card
  margin: auto; // centers the card within its container

  .card {
    &:hover {
      border: 1px solid gray;
      scale: 1.01;
    }
    .body {
      padding: 12px;
    }
    img {
      object-fit: cover;  // or use 'contain' based on your need
      height: 200px;  // set to the height you desire
      width: 100%; // auto-adjusts to keep aspect ratio
    }
  }

.box{
    border: 1px solid #ccc;
        display: block;
        border-radius:10px ;
        line-height: 2;
        padding: 1rem;
        margin-bottom: 0.5rem;
        margin:auto;
        width: 80%;
        height:150px;
}

`;