import React from "react";
import styled from "styled-components";

import logo from "../images/logo.png";
import tlf from "../images/tlf.png";
import email from "../images/email.png";
import info from "../images/info.png";

export default function Kontakt() {
  return (
    <DivWrapper>
      <>
        {/**   <Hero img={contactBcg} />*/}
        <div className="container">
          <div className="row my-5">
            <div className="col-sm-8  col-lg-offset-2 col-lg-10">
              <h3>Kontakt oss</h3>
              <p>
                Har du spørsmål om bruk av MinOppussing.no?
                Kontakt oss på epost eller tlf ved eventuelle spørsmål.
              </p>
            </div>
          </div>

          <div className="row justify-content-between">
          {/*
            <div className="col-12 col-sm-12 col-md-6 ">
            </div>
            */}
            <div className="col-12 col-sm-12 col-md-6 ">
              <div className="row" />
              <div className="rightInfoBox">
                <div className="grayBox p-3">
                  <img
                    src={logo}
                    className="logo"
                    width="165px"
                    alt="minoppussinglogo"
                  />
                  <div className="row p-2">
                  <div className="col-3">
                    <img src={tlf} alt="" className="icon" />
                    </div>
                    <div className="col">
                      <h5>TELEFON</h5>
                      <h4> 45441336</h4>
                    </div>
                  </div>


                  <div className="row p-2">
                  <div className="col-3">
                    <img src={email} alt="" className="icon" />
                    </div>
                    <div className="col">
                      <h5>EPOST</h5>
                      <h4> post@minoppussing.no</h4>
                    </div>
                  </div>


                  <div className="row p-2">
                  <div className="col-3">
                    <img src={info} alt="" className="icon" />
                    </div>
                    <div className="col">
                      <h5>FORETAKSNUMMER</h5>
                      <h4> 29221188922</h4>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
      </>
    </DivWrapper>
  );
}

const DivWrapper = styled.div`
  margin-top: 50px;

  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .grayBox {
    background-color: #f2f2f2;
    max-width: 350px;
  }
  
  .icon {
    width: 45px;
  }
  h5 {
    font-size: 14px;
    color: black;
    font-weight: bold;
    margin: 0;
    margin-top: 5px;
  }
  h4 {
    font-size: 12px;
    margin: 0;
  }
  .logo {
    margin-bottom: 15px;
  }

  .row {
    display: flex;
    align-items: flex-start;
  }
`;


