import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../firebase/firebase';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from "styled-components";
import { AuthContext } from '../context/AuthContext';  
import PriceHistory from '../components/PriceHistory';


export default function SingleProduct() {
  const { productName } = useParams();
  const decodedProductName = decodeURIComponent(productName);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const { breadcrumb1, breadcrumb2, breadcrumb3 } = useParams();
  const { cart, setCart } = useContext(AuthContext);


  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const q = query(collection(db, "products"), where("productName", "==", decodedProductName));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // product found
        const product = querySnapshot.docs[0].data();

        // check if breadcrumb2 and breadcrumb3 are the same as productName
        // if they are, fetch them from product data
        if (breadcrumb2 === decodedProductName) {
          breadcrumb2 = product.breadcrumb2;
        }
        if (breadcrumb3 === decodedProductName) {
          breadcrumb3 = product.breadcrumb3;
        }
        
        setProduct(product);
      } else {
        // product not found
        console.log('No such product!');
      }       
      setLoading(false);
    };
  
    fetchProduct();
  }, [decodedProductName]);
  
  const handleScrollToDescription = () => {
    const descriptionElement = document.getElementById('product-description');
    if (descriptionElement) {
      descriptionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAddProduct = (product) => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(p => p.id === product.id);
      if (existingProduct) {
        // The product is already in the cart, increase its quantity
        return prevCart.map(p => p.id === product.id ? {...p, quantity: p.quantity + 1} : p);
      } else {
        // The product is not in the cart, add it
        return [...prevCart, {...product, quantity: 1}];
      }
    });
  };
  
  
  

  return (
    <Container>
      <div className="breadcrumbs my-4">
        <StyledLink to={`/${breadcrumb1}`}>{breadcrumb1}</StyledLink>
        {breadcrumb2 && <> / <StyledLink to={`/${breadcrumb1}/${breadcrumb2}`}>{breadcrumb2}</StyledLink></>}
        {breadcrumb3 && <> / <StyledLink to={`/${breadcrumb1}/${breadcrumb2}/${breadcrumb3}`}>{breadcrumb3}</StyledLink></>}
        <> / <ActiveBreadcrumb>{decodedProductName}</ActiveBreadcrumb></>
      </div>
      <Row>
        <Col lg={6}>
          <Card.Img variant="top" src={product.imageSrc} />
        </Col>
        <Col lg={6}>
          <h1>{product.productName}</h1>
          <h2>Kr {product.byggmakkerPrice} {product.unit}</h2>
          <ul>
          {
            !loading && Array.isArray(product.advantages) && product.advantages.map((advantage, i) => (
                <li key={i}>{advantage}</li>
            ))
          }
          
          </ul>
          <StyledHyper onClick={handleScrollToDescription}>Mer om produkt</StyledHyper>
          <button className='btn btn-success' onClick={()=>handleAddProduct(product)}>Legg i handlekurv</button>
        </Col>
        <Col lg={12}>
        <p>Nobb nummer:
        {
        !loading && Array.isArray(product.articleNumbers) &&
        product.articleNumbers.find(obj => obj.key === 'Artikkelnummer')?.value
        }
        </p>
          <p id="product-description">{product.description}</p>
        </Col>
      </Row>
      <br/>
      <h3>Prisutvikling:</h3>
      {product.id && <PriceHistory productId={product.id} />}


    </Container>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent
  
  &:hover {
    text-decoration: underline; // Underlines the text on hover
    color: #ff6347; // Changes the color to tomato on hover
  }
`;

const ActiveBreadcrumb = styled.span`
  text-decoration: underline; // Underlines the text
  color: #ff6347; // Changes the color to tomato
`;

const StyledHyper = styled.p`

cursor: pointer;

&:hover{
    text-decoration:underline ;
}
`