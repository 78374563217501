
import React, { useState, useEffect, useContext } from 'react'
import styled from "styled-components"
import { AuthContext } from '../../context/AuthContext';
import { collection, getDocs, query, where, deleteDoc, doc, getDoc  } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link } from 'react-router-dom';

export default function ProjectCartSingleProject({selectedCartId, cartDescription  }) {
  const { currentUser, cart, setCart, setProjectCart } = useContext(AuthContext);
  const [savedCarts, setSavedCarts] = useState([]); 
  const [selectedCart, setSelectedCart] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isListExpanded, setIsListExpanded] = useState(false)

    useEffect(()=>{
        const fetchSavedCarts = async () => {
            try {
              // Ensure selectedCartId is not empty and is a valid ID
              if (!selectedCartId) {
                console.log('No selectedCartId provided');
                return;
              }
              // Fetching the document by its ID
              const docRef = doc(db, 'savedCarts', selectedCartId);
              const docSnap = await getDoc(docRef);
          
              if (docSnap.exists()) {
                // Document data will be in docSnap.data()
                const cartData = docSnap.data();
                cartData.id = docSnap.id; // Adding the document ID to the data
                setSavedCarts(cartData); // Setting the state with the fetched cart
              } else {
                // Handle the case where the document does not exist
                console.log('No such document!');
                setSavedCarts([]); // Reset the state if no document is found
              }
            } catch (error) {
              console.error('Error fetching saved carts: ', error);
            }
          };
          
          fetchSavedCarts();

}, [selectedCartId])

 

  const transferToGlobalCart = () => {
    // Merge the current global cart with the transformed cartKledning
    const mergedCart = [...cart, ...savedCarts.items];
    setCart(mergedCart);
    scrollToTop()
  };
  
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"  // Optional: smooth scrolling
    });
  }

  const getArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
  };

  const getTotalSum = () => {
    return savedCarts.items.reduce((total, product) => total + (product.byggmakkerPrice * product.quantity), 0);
  };

  const toggleList = () => {
    setIsListExpanded(!isListExpanded);
  };

  return (
    <StyledDiv>
      <div>

      {isListExpanded && savedCarts && (
        <CartContainer>
          
          {isListExpanded && (<Table>
            <thead>
              <tr>
              <th></th>
                <th>Produkt</th>
                <th>Pris</th>
                <th>Antall</th>
                <th className="hide-sm">Artikkelnummer</th>
                <th>Produkt total</th>
              </tr>
            </thead>
            <tbody>
              {isListExpanded && savedCarts.items.map((product, index) => (
                
                <tr key={index}>
                  <td><img src={product.imageSrc} alt={product.productName} style={{height: '50px'}}/></td>
                  <td>
                    <ProductNameLink to={`/${product.breadcrumb1}/${product.breadcrumb2}/${product.breadcrumb3}/${product.productName}`}>
                      <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>{product.productName}</h2>
                    </ProductNameLink>
                  </td>
                  <td>kr. {product.byggmakkerPrice} {product.unit}</td>
                  <td>{product.quantity}</td>
                  <td className="hide-sm">{getArticleNumber(product)}</td>
                  <td>{(product.byggmakkerPrice * product.quantity).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>)}
             </CartContainer>
      )}

          <Footer>
  <div className='d-flex justify-content-between w-100'>
    
   {isListExpanded && ( <div>
    <h2>Total: kr. {savedCarts && (getTotalSum().toFixed(2))}</h2>
    
      <button className='btn btn-info' onClick={transferToGlobalCart}>
        Overfør til handlehurv
      </button>
      </div>
    )}
  </div>
  <div>
  <h5>{cartDescription}</h5>

    <button onClick={toggleList} className="btn btn-outline-primary">
    {isListExpanded ? 'Skjul handleliste' : 'Vis handleliste'}
    </button>
  </div>
</Footer>

        
     
</div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`

StyledDiv, CartContainer {
    max-width: 100%;
    box-sizing: border-box; // Include padding and border in the width calculation
}

Table {
    width: 100%;
    max-width: 100%; // Prevent the table from expanding beyond its container
    table-layout: fixed; // Optional: Helps with managing column widths

    img {
        max-width: 100%; // Ensure images within the table are responsive
        height: auto;
    }

    th, td {
        overflow: hidden; // Prevents content from overflowing
        text-overflow: ellipsis; // Adds an ellipsis to overflowing content
    }
}


@media (max-width: 450px) {
    StyledDiv, CartContainer {
        padding: 5px; // Reduced padding
    }

    Table {
        th, td {
            padding: 4px 0px; // Reduced padding
            font-size: 0.8em; // Smaller font size
        }
    }

    .lister, .listWrapper, Footer {
        padding: 5px; // Reduced padding
        font-size: 0.8em; // Smaller font size
    }

    .productNameLink h2 {
        font-size: 0.8em; // Smaller font size for product names
    }

    .toggleListButton, .deleteIcon {
        // Adjust size and margins as needed
        font-size: 0.8em;
        margin: 2px;
    }

    // Any other specific elements you want to adjust
}





.listWrapper, .lister {
    flex-shrink: 1; // Allow flex items to shrink as needed
}
.input-container {
    margin: 15px 0;
    padding: 5px;
    border: 1px solid #ddd; // Light grey border
    border-radius: 4px; // Rounded corners
    background-color: #fff; // White background
    width: 100%; // Full width

    select {
      width: 100%; // Full width of container
      padding: 8px 10px; // Padding for aesthetics
      border: none; // No additional border
      border-radius: 4px; // Rounded corners
      background-color: #fff; // White background
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      font-size: 16px; // Readable font size
      color: #333; // Dark text for readability

      &:focus {
        outline: none; // Remove focus outline
        box-shadow: 0 0 0 2px #3498db; // Highlight focus with a different color
      }

      option {
        padding: 8px; // Padding inside options
      }
    }
  }

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .toggleListButton {
    margin-top: 15px; // Space above the button
    // Other styles as needed
  }
    .listWrapper {
    display: flex; 
    align-items: center; 
    justify-content: space-between; // Adjust based on your layout preference
  }

  .lister {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 1; // it will take the available space
  }

  .lister:hover {
    color: tomato;
  }

  .list {
    width: 40px;
  }

  .lister:hover .list {
    filter: brightness(0.5) sepia(1) hue-rotate(-50deg) saturate(1000%) contrast(0.8);
  }

  .deleteIcon {
    height: 27px;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s ease; // for a smooth transition effect
  }

  .deleteIcon:hover {
    filter: brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(0.8); 
    // This applies a series of filter effects to alter the icon's appearance
    // You may need to play with these values to get your desired effect.
  }
`

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 60px);
  margin-top:10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    .hide-sm {
      display: none;
    }
  }
`;

const Footer = styled.div`
   display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; // Allow items to wrap onto the next line

  > div {
    flex-basis: 100%; // Make each direct child take full width on its own line
    margin-top: 10px; // Add some space between the lines
  }
`;

const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
