import React, { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, doc, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../firebase/firebase';
import AdminNav from './AdminNav';
import styled from "styled-components";

export default function Tilbudsforespørsler() {
  const [quoteRequests, setQuoteRequests] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState(null);

  useEffect(() => {
    const fetchQuoteRequests = async () => {
      const q = query(collection(db, 'quoteRequests'), orderBy('createdAt'));
      const quoteRequestsSnapshot = await getDocs(q);
      const quoteRequestsData = quoteRequestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuoteRequests(quoteRequestsData);
    };

    fetchQuoteRequests();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'quoteRequests', id));
    setQuoteRequests(quoteRequests.filter(request => request.id !== id));
    setShowDeleteModal(false);
  };

  const handleModalOutsideClick = (e) => {
    if (e.target.id === 'modal-backdrop') {
      setShowDeleteModal(false);
    }
  };

  return (
    <StyledDiv>
      <AdminNav />
      <h2>Requests</h2>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Created At</th>
            <th>Status</th>
            <th>#id</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quoteRequests.map((request) => (
            <tr key={request.id}>
              <td><Link to={`/admin/requests/${request.id}`}>{request.userName}</Link></td>
              <td>{request.createdAt ? new Date(request.createdAt.seconds * 1000).toLocaleDateString() : ''}</td>
              <td>{request.status}</td>
              <td>{request.id}</td>
              <td>
                <Link to={`/admin/handlerequest/${request.id}`}>
                  <button className='btn btn-outline-primary'>Behandle tilbudsforespørsel</button>
                </Link>
                <button className='btn btn-danger ms-4' onClick={() => {
                  setShowDeleteModal(true);
                  setRequestToDelete(request.id);
                }}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteModal && (
        <DeleteConfirmationModal
          onRequestClose={() => setShowDeleteModal(false)}
          onConfirmDelete={() => handleDelete(requestToDelete)}
          onModalOutsideClick={handleModalOutsideClick}
        />
      )}
    </StyledDiv>
  );
}



function DeleteConfirmationModal({ onRequestClose, onConfirmDelete, onModalOutsideClick }) {
  return (
    <div id="modal-backdrop" className="modal-backdrop" onClick={onModalOutsideClick}>
      <div className="modal-content">
        <p>Er du sikker på at du vil slette tilbudsforespørsel?</p>
        <button onClick={onConfirmDelete} className='btn btn-danger btn-sm my-3'>Ja</button>
        <button onClick={onRequestClose} className='btn btn-primary btn-sm'>Nei</button>
      </div>
    </div>
  );
}


const StyledDiv = styled.div`
  th, td {
    padding-right:40px; 
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    width: 50%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;