import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { db } from '../../firebase/firebase';
import { AuthContext } from '../../context/AuthContext';
import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import arrow from "../../images/arrow-enter.svg"

export default function Comments({ projectId }) {
    const { currentUser } = useContext(AuthContext);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [editText, setEditText] = useState('');
    const [visibleComments, setVisibleComments] = useState(10); // Number of comments to show initially
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const commentsPerPage = 7; // Adjust this number as needed
    const [visibleReplies, setVisibleReplies] = useState({}); // { commentId: boolean }


    const [replyTo, setReplyTo] = useState(null); // State to hold the ID of the comment being replied to
    const [replyText, setReplyText] = useState(''); // State to hold the reply text
    const [isReplyFocused, setIsReplyFocused] = useState(false);
    const [replies, setReplies] = useState({});




    const indexOfLastComment = currentPage * commentsPerPage;
const indexOfFirstComment = indexOfLastComment - commentsPerPage;
const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment).reverse();



    const userEmailFull = currentUser && currentUser.email ? currentUser.email.split('@')[0] : "";



useEffect(() => {
    if (projectId) {
        const commentsRef = collection(db, 'comments');
        const q = query(commentsRef, where('projectId', '==', projectId), orderBy('timestamp'));        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const commentsArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setComments(commentsArray);
            console.log(comments)
            setTotalPages(Math.ceil(commentsArray.length / commentsPerPage));
        });

        return () => unsubscribe(); // Clean up the subscription
    }
}, [projectId]);

useEffect(() => {
    const handleClickOutside = (event) => {
      if (isReplyFocused && !event.target.closest('.reply-input')) {
        // User clicked outside the reply textarea
        setReplyTo(null); // Or any other action to abort the reply
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isReplyFocused]);
  

  useEffect(() => {
    const fetchComments = async () => {
        try {
            const commentsRef = collection(db, 'comments');
            const q = query(commentsRef, where('projectId', '==', projectId), orderBy('timestamp'));
            const querySnapshot = await getDocs(q);
            const commentsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setComments(commentsArray);
            setTotalPages(Math.ceil(commentsArray.length / commentsPerPage));
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    if (projectId) {
        fetchComments();
    }
}, [projectId, commentsPerPage]);


useEffect(() => {
    

    if (projectId) {
        fetchReplies();
    }
}, [projectId]);

const fetchReplies = async () => {
    const repliesRef = collection(db, 'replies');
    const q = query(repliesRef, where('projectId', '==', projectId), orderBy('timestamp'));
    const querySnapshot = await getDocs(q);

    let fetchedReplies = {};
    querySnapshot.forEach(doc => {
        const reply = { id: doc.id, ...doc.data() };
        fetchedReplies[reply.parentId] = fetchedReplies[reply.parentId] || [];
        fetchedReplies[reply.parentId].push(reply);
    });

    setReplies(fetchedReplies);
};


const toggleRepliesVisibility = async (commentId) => {
    if (!visibleReplies[commentId]) {
        // Check if replies are already fetched
        const comment = comments.find(c => c.id === commentId);
        if (!comment.replies || comment.replies.length === 0) {
            // Fetch replies if not already fetched
            const replies = await fetchReplies(commentId);
            setComments(comments.map(c => 
                c.id === commentId ? { ...c, replies } : c
            ));
        }
        setVisibleReplies(prev => ({ ...prev, [commentId]: true }));
    } else {
        // Hide replies
        setVisibleReplies(prev => ({ ...prev, [commentId]: false }));
    }
};


const submitReply = async () => {
    if (!replyText.trim()) return;
    setIsLoading(true);

    try {
        // Add the reply to the 'replies' collection with a reference to the parent comment
        await addDoc(collection(db, 'replies'), {
            parentId: replyTo, // ID of the comment being replied to
            projectId: projectId,
            userId: currentUser.uid,
            text: replyText,
            timestamp: serverTimestamp(),
            userName: userEmailFull
        });
        setReplyText('');
        setReplyTo(null);
    } catch (error) {
        console.error('Error adding reply:', error);
    }
    setIsLoading(false);
};



    // Function to start replying to a comment
    const startReply = (commentId) => {
        setReplyTo(commentId);
        setReplyText('');
    };

const handleLoadMoreComments = () => {
    setCurrentPage(currentPage + 1);
};

const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const submitEdit = async () => {
    const commentRef = doc(db, 'comments', editId);
    await updateDoc(commentRef, {
        text: editText,
        // You might also update the timestamp here
    });
    setIsEditing(false);
    setEditId(null);
    setEditText('');
};

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleCommentSubmit = async () => {
        if (!newComment.trim()) return;
        setIsLoading(true);
        
        try {
            await addDoc(collection(db, 'comments'), {
                projectId:projectId,
                userId: currentUser.uid,
                text: newComment,
                timestamp: serverTimestamp(),
                userName: userEmailFull,
            });
            setNewComment(''); // Clear the input field
        } catch (error) {
            console.error('Error adding comment:', error);
        }
        setIsLoading(false);
    };
    
    const deleteComment = async (commentId) => {
        // Firestore delete logic
        const commentRef = doc(db, 'comments', commentId);
        await deleteDoc(commentRef);
    };
    
    const startEdit = (comment) => {
        setEditId(comment.id);
        setEditText(comment.text);
        setIsEditing(true);
    };
    


    return (
        <StyledComments>
            {/* Comment Input Section */}
            <div className="comment-input">
                <textarea value={newComment} onChange={handleCommentChange} placeholder="Skriv en kommentar..." />
                <button onClick={handleCommentSubmit} disabled={isLoading}>Post</button>
            </div>
    
            {/* Comments Display Section */}
            {currentComments.map(comment => (
                <div key={comment.id} className="comment">
                    {/* Comment Details */}
                    <div className="comment-details">
                        <span className="username">{comment.userName}</span>
                        <span className="timestamp">
                            {comment.timestamp?.toDate().toLocaleTimeString()}
                        </span>
                        {currentUser && currentUser.uid === comment.userId && (
                            <span className="actions">
                                <span onClick={() => startEdit(comment)} className="action-text">Edit</span>
                                <span onClick={() => deleteComment(comment.id)} className="action-text">Slett</span>
                            </span>
                        )}
                    </div>
    
                    {/* Comment Text */}
                    <div className="comment-text">{comment.text}</div>
    
                    {/* Replies Toggle Button */}
                    <span onClick={() => toggleRepliesVisibility(comment.id)} className='toggle-replies-button'>
    {visibleReplies[comment.id] ? 
        `Skjul svar (${replies[comment.id] ? replies[comment.id].length : 0})` :
        `Vis svar (${replies[comment.id] ? replies[comment.id].length : 0})`}
</span>
    
                    {/* Replies Display Section */}
                    {visibleReplies[comment.id] && replies[comment.id] && (
                        <div className="replies">
                            {replies[comment.id].map(reply => (
                                <div key={reply.id} className="reply">
                                
                                    
                                    <div className="reply-details">
                                        <img src={arrow} alt="arrowIcon" className='arrowIcon'/>

                                        <span className="username">{reply.userName}</span>
                                        <span className="timestamp">{reply.timestamp?.toDate().toLocaleTimeString()}</span>
                                        <div className="reply-text">{reply.text}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
    
                    {/* Reply Input Trigger Button */}
                    <span onClick={() => startReply(comment.id)} className="reply-button">Svar</span>
                    
                    {/* Reply Input Section */}
                    {replyTo === comment.id && (
                        <div className="reply-input">
                            <textarea value={replyText} onChange={e => setReplyText(e.target.value)} placeholder="Skriv et svar..." />
                            <button onClick={submitReply} disabled={isLoading}>Svar</button>
                        </div>
                    )}
                </div>
            ))}
    
            {/* Pagination Controls */}
            {comments.length > commentsPerPage && (
                <div className="pagination-controls">
                    <button onClick={() => goToPage(1)} disabled={currentPage === 1}>Første</button>
                    <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Forrige</button>
                    <span>{currentPage} av {totalPages}</span>
                    <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>Neste</button>
                    <button onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>Siste</button>
                </div>
            )}
        </StyledComments>
    );
    
    
    
    
}

const StyledComments = styled.div`

.arrowIcon{
    width:20px;
    transform: scaleX(-1);
    margin-right:4px;

}
.reply-details{

    .username {
            font-weight: bold;
        }
        .timestamp{
            font-size: 11px;
            margin-left: 10px;
            color: #999;        }

            .reply-text{
                margin-left:24px;
            }
}

.reply {
    margin-left: 20px; /* Adjust for indentation */
    position: relative;
}




.reply-details {
    /* Additional styling for reply details */
}


.toggle-replies-button{
    cursor: pointer;
                color: #007bff; // Bootstrap primary color for example
                font-size: 0.8em;
                margin-right:20px;

                &:hover {
                    text-decoration: underline;
                }
}

.reply-input{
    textarea{
            width: 85%;
            min-height: 50px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            resize: none; // Disable resizing
        
    }

    button {
            padding: 5px 8px;
            margin-left: 10px;
            background-color: #3498db; // Blue button
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #2980b9; // Darker blue on hover
            }

            &:disabled {
                background-color: #95a5a6; // Grey out when disabled
            }
        }
}

.pagination-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        button {
            margin: 0 5px;
            padding: 5px 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #0056b3;
            }

            &:disabled {
                background-color: #cccccc;
                cursor: default;
            }
        }
        span {
            margin: 0 10px;
        }
    }
    @media (max-width: 500px) {
    .pagination-controls button {
        width: 40%;
        padding: 4px 8px; /* Smaller padding */
        font-size: 0.8em; /* Smaller font size */
    }

    .pagination-controls span {
        font-size: 0.6em; /* Smaller font size for text */
    }
}

.load-more-comments {
        background-color: #007bff;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
            background-color: #0056b3;
        }
    }

    .comment {
        margin-bottom: 15px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;}

        .comment-details {
        display: flex;
        align-items: center;
        justify-content: space-between;

  

        .username {
            font-weight: bold;
        }

        .timestamp {
            font-size: 11px;
            margin-left: 10px;
            color: #999;
        }

        .actions {
            margin-left: auto; // Pushes the actions to the right
            display: flex;
            gap: 10px; // Spacing between edit and delete actions

            .action-text {
                cursor: pointer;
                color: #007bff; // Bootstrap primary color for example
                font-size: 0.8em;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .reply-button{
        cursor: pointer;
                color: #007bff; // Bootstrap primary color for example
                font-size: 0.8em;

                &:hover {
                    text-decoration: underline;
                }
    }

    .comment-input {
        display: flex;
        align-items: center;
        margin-top: 20px;

        textarea {
            width: 100%;
            min-height: 50px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            resize: none; // Disable resizing
        }

        button {
            padding: 10px 15px;
            margin-left: 10px;
            background-color: #3498db; // Blue button
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #2980b9; // Darker blue on hover
            }

            &:disabled {
                background-color: #95a5a6; // Grey out when disabled
            }
        }
    }
`;
