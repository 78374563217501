import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import styled from "styled-components";
import CartItemsList from './CartItemsList';
import { AuthContext } from '../../context/AuthContext';
import YetterveggProductsList from './YtterveggProductsList';


export default function YtterveggProducts() {

    const { materialsYttervegg, setCartYttervegg, materialsCalculatedYttervegg,setMaterialsCalculatedYttervegg  } = useContext(AuthContext);


    const insulationCoverage = {
        glava: {
            "5cm": { coverage: 10.3, id: 'C2Z1Om6KJsIfh1aE2bNn' },
            "7cm": { coverage: 7.41, id: 'kCUhz6NV46gnMKtHKmk9' },
            "10cm": { coverage: 5.36, id: 'pqCEpj2l8nI1S0x3Icxx' },
            "15cm": { coverage: 3.65, id: 'FAQqUdKaQNJD7gFVFkvK' },
            "20cm": { coverage: 3.42, id: 'RIX7Ib5bwSnumeAk4PND' },
        },
        rockwool: {
            "5cm": { coverage: 8.14, id: 'M6dFptRdsIawwD3iaQlS' },
            "7cm": { coverage: 5.52, id: 'jhSVkyGf7CafYx6PzNIs' },
            "10cm": { coverage: 4.14, id: 'jQHfV51tVxS5TWDEJqrW' },
            "15cm": { coverage: 2.76, id: 'HQqgk4mD5AlbdrRRG5Eh' },
            "20cm": { coverage: 2.07, id: 'Er7Gn5VtnI2tTJM0MgXZ' },
        }
    };

    const LEKTER_LOOKUP = {
        '5cm': 'MP1tVWKvdMuXUyGOzSWi',
        '7cm': '2B6WBs82QQhu4cSK5wqx',
        '10cm': 'i3fAFZFSXs2DdEluPVbT',
        '15cm': '375QkN5MKVGsCi48zDSt',
        '20cm':'1NCtqOmZbVDu89QJ4Jp6'
    };

    const STENDERE = {
        "36mm x 98mm":"m2CEnrN5TNpbPLRgtvda",
        "48mm x 98mm":"i3fAFZFSXs2DdEluPVbT",
        "36mm x 148mm":"1n90pzqaQ4MVooP8sZyt",
        "48mm x 148mm":"375QkN5MKVGsCi48zDSt",
        "36mm x 198mm":"6PjCTu0UvGisaCz8durU",
        "48mm x 198mm":"1NCtqOmZbVDu89QJ4Jp6"
    }

    const VINDSPERRE = {
        'vindsperre': {
            'small': { coverage: 32.51, id: 'a6gjOYQEjz3xnRz7Sfpm' },
            'medium':{coverage:65,id:'PAGxAv17xKJrZRBOsrd6'},
            'large': { coverage: 140, id: 'sGluPTq2R1aGWI5qcZcA' },
        },
        'asfalt': {
            'default': { coverage: 3.29, id: 'vRDsvIurWcoZiL1zMewS' } // or other variations if they exist
        },
        'gips': {
            'default': { coverage: 3.29, id: 'mKDNB1GbeZbJU0L7WwLe' } // or other variations if they exist
        }
    }

    const DAMPSPERRE = {
        'default': {coverage:39, id:"b8xUXNwVmWvBlPNwd5WY"},
       
    }

    const MUSEBÅND = {
        'default': { coverage: 1.25, id: 'v1tkbQa5xDQAqO3xdL6i' }
    };
    
    const SCREWS = {
        'small': { amount: 100, id: 'mF8XxM7Xgp2KON5I6Tn1' }, 
        'medium':{amount: 400, id: '65jAhJZEb2ubb6TXZvkF'}
    };


    const KLEDNING_COVERAGE = {
        '19x98': {
            dimension: '19x98',
            coverage: 12.5 
        },
        '19x123': {
            dimension: '19x123',
            coverage: 9.6
        },
        '19x148': {
            dimension: '19x148',
            coverage: 7.7
        },
        '19x173': {
            dimension: '19x173',
            coverage: 6.5
        }
    };
 
    const REKKER = {
        '23cm':'PwIpM8D00PtEbDKWdITH',
        '36cm':'PKgwQxlyonxFPtpHKGWA'
    }

    const NAILS = 'vaTCQKzoEUwn6hvoOyjc'

    const VINDSPERRETAPE = '3QvLLo9HmVnLqBaMfhPZ'
    const DAMPSPERRETAPE = 'rJh1ir7HpyW1f0DGR8qd'
    


    const [productsDatabase, setProductsDatabase] = useState([]);

    useEffect(() => {
        async function fetchProducts() {
            const q = collection(db, "products");
            const querySnapshot = await getDocs(q);
            const products = [];
            querySnapshot.forEach((doc) => {
                products.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setProductsDatabase(products);
        }
        fetchProducts();
    }, []);

    useEffect(() => {
        if (materialsCalculatedYttervegg && materialsYttervegg && productsDatabase.length > 0) {
            calculationsToCart(materialsYttervegg);
            setMaterialsCalculatedYttervegg(false); // Reset the flag
        }
    }, [materialsYttervegg, productsDatabase, materialsCalculatedYttervegg]);

    function calculateInsulationQuantity(insulationType, thickness, area) { 
        if (insulationType === null) {
            return 0;
        }
    
        if (!insulationType || !insulationCoverage[insulationType]) {
            console.error(`Invalid insulationType: ${insulationType}`);
            return 0;
        }
    
        const realThickness = getThicknessValue(thickness)
    
        const currentInsulationCoverage = insulationCoverage[insulationType];
        const currentInsulationThicknessCoverage = currentInsulationCoverage ? currentInsulationCoverage[realThickness] : null;
    
        if (!currentInsulationThicknessCoverage) {
            console.error(`Invalid thickness for insulationType ${insulationType}: ${realThickness}`);
            return 0;
        }
        
        const coverage = currentInsulationThicknessCoverage.coverage;
    
        if (!coverage) {
            console.error(`Coverage not found for insulationType ${insulationType} and thickness ${realThickness}`);
            return 0;
        }
        return Math.ceil(area / coverage);
    }
    
    //helper function
    const getThicknessValue = (option) => {
        switch (option) {
            case '48mm x 98mm':
                return '10cm';
            case '36mm x 148mm':
            case '48mm x 148mm':
                return '15cm';
            case '36mm x 198mm':
            case '48mm x 198mm':
                return '20cm';
            default:
                return 'Unknown'; // or any default value you prefer
        }
    };
    

    function calculationsToCart(materialsYttervegg) {
        const cartItems = [];

        let totalCoverageRequired = 0;

       const calculateLekterQuantity = (wallLength, wallHeight, wallHeightSide, wallHeightCenter, selectedWall) => {
           if (selectedWall === "sidewall") {
               // Calculate the quantity for a sidewall.
               return Math.ceil(wallLength * 2 + wallHeight * 2 + ((wallLength / 0.6) * wallHeight));
           }  if (selectedWall === "gablewall") {
               // Calculate the quantity for a gabel wall.
               return Math.ceil(wallLength * 2 + wallHeightSide * 2 + ((wallLength / 0.6) * wallHeightSide) + ((wallHeightCenter - wallHeightSide) * wallLength) / 2);
           } else {
               console.error("Invalid wall type:", selectedWall);
               return 0; // Return 0 or some other default value in case of invalid wall type.
           }
       };
    
        // Calculating for wall insulation
        const insulationQuantityWall = calculateInsulationQuantity(
            materialsYttervegg.insulationType,
            materialsYttervegg.insulationWallThickness,
            materialsYttervegg.insulationWall
        );
    
        const currentInsulationTypeCoverage = insulationCoverage[materialsYttervegg.insulationType];
        const currentInsulationTypeThicknessCoverage = currentInsulationTypeCoverage ? currentInsulationTypeCoverage[getThicknessValue(materialsYttervegg.insulationWallThickness)] : null;

        const insulationProductExisting = productsDatabase.find(product => product.id === currentInsulationTypeThicknessCoverage?.id);
        cartItems.push({
            product: insulationProductExisting,
            quantity: insulationQuantityWall
        });
    
        // Calculating for isolering påforing
        if (materialsYttervegg.påforing === "yes") {
          
            const etterisolereCoverage = materialsYttervegg.selectedInsulationPåforing
            if(etterisolereCoverage === "glava"){
                const insulationProductPåforing = productsDatabase.find(product => product.id === "C2Z1Om6KJsIfh1aE2bNn") 
                const insulationQuantityPåforing = Math.ceil( materialsYttervegg.insulationWall / insulationCoverage.glava["5cm"].coverage)
                cartItems.push({
                    product: insulationProductPåforing,
                    quantity: insulationQuantityPåforing
                });
            }
            if(etterisolereCoverage === "rockwool"){
                const insulationProductPåforing = productsDatabase.find(product => product.id === "M6dFptRdsIawwD3iaQlS")
                const insulationQuantityPåforing = Math.ceil( materialsYttervegg.insulationWall / insulationCoverage.rockwool["5cm"].coverage)
                cartItems.push({
                    product: insulationProductPåforing,
                    quantity: insulationQuantityPåforing
                });
            }
       

        // Calculating for lekter
        const lekterProduct = productsDatabase.find(product => product.id === "MP1tVWKvdMuXUyGOzSWi");
        const lekterQuantity = calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall);
        const lekterForWindow = materialsYttervegg.lekterForWindow

        const totalLekter = lekterForWindow + lekterQuantity
        cartItems.push({
            product: lekterProduct,
            quantity: totalLekter
        });
    }

    //calculations for stendere
       
        
        const stendereProduct = productsDatabase.find(product => product.id === STENDERE[materialsYttervegg.insulationWallThickness]);
        const stendereQuantity = calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall);
        
        if(materialsYttervegg.selectedWall === "gablewall"){
            const tallWallAddStender = materialsYttervegg.wallLength * 2;
            cartItems.push({
                product:stendereProduct,
                quantity:stendereQuantity + tallWallAddStender
            })
        }
        if(materialsYttervegg.selectedWall === "sidewall"){
        cartItems.push({
            product:stendereProduct,
            quantity:stendereQuantity
        })
    }

             // Calculating for musebånd
             if(materialsYttervegg.wallLength > 0){
                    const musebåndRequiredM2 = materialsYttervegg.wallLength; // using wallLength
        const musebåndQuantity = Math.ceil(musebåndRequiredM2 / MUSEBÅND.default.coverage);
        const musebåndProduct = productsDatabase.find(product => product.id === MUSEBÅND.default.id);
        cartItems.push({
            product: musebåndProduct,
            quantity: musebåndQuantity
        });
             }
    

        // Calculating for screws
//helpers 
const lekterQuantity = calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall);
const lekterForWindow = materialsYttervegg.lekterForWindow

const totalLekter = lekterForWindow + lekterQuantity*2
//helpers end

        const totalScrewsRequired = Math.ceil(totalLekter / 0.6);
        const mediumPacksRequired = Math.floor(totalScrewsRequired / SCREWS.medium.amount);
        const remainingScrews = totalScrewsRequired - (mediumPacksRequired * SCREWS.medium.amount);
        const smallPacksRequired = Math.ceil(remainingScrews / SCREWS.small.amount);

        const mediumPackScrewsProduct = productsDatabase.find(product => product.id === SCREWS.medium.id);
        const smallPackScrewsProduct = productsDatabase.find(product => product.id === SCREWS.small.id);

        if (mediumPacksRequired > 0) {
            cartItems.push({
                product: mediumPackScrewsProduct,
                quantity: mediumPacksRequired
            });
        }
        if (smallPacksRequired > 0) {
            cartItems.push({
                product: smallPackScrewsProduct,
                quantity: smallPacksRequired
            });
        }
    
    
        
    
            // Calculating for vindsperre
    if (materialsYttervegg.wallArea && materialsYttervegg.selectedVindsperreOptions && materialsYttervegg.selectedVindsperreOptions.length) {
        materialsYttervegg.selectedVindsperreOptions.forEach(vindsperreType => {
            const vindsperreQuantities = calculateVindsperreQuantity(materialsYttervegg.wallArea, vindsperreType);
            
            for (let vindsperre of vindsperreQuantities) {
                const product = productsDatabase.find(p => p.id === vindsperre.id);
                
                if (!product) {
                    console.warn(vindsperreType, "product not found in database:", vindsperre.id);
                } else {
                    cartItems.push({
                        product: product,
                        quantity: vindsperre.quantity
                    });
                }
            }
        });
    }

//Calculations for dampsperre
if(materialsYttervegg.wallArea > 0){
    const dampsperreProduct = productsDatabase.find(product => product.id === DAMPSPERRE.default.id); 
const dampsperreQuantity = Math.ceil(materialsYttervegg.wallArea/DAMPSPERRE.default.coverage)
    
cartItems.push({
    product: dampsperreProduct,
    quantity: dampsperreQuantity
});
}


//calculations for dampsperreTape  kjører dobbel vindsperretape

const dampTape = productsDatabase.find(product => product.id === DAMPSPERRETAPE)
    if(materialsYttervegg.wallHeightCenter){
            const amount = Math.ceil(((materialsYttervegg.wallLength / 2.6) * materialsYttervegg.wallHeightCenter)/25)
            cartItems.push({
                product:dampTape,
                quantity:amount
            })
    } if(materialsYttervegg.wallHeight){
        const amount = Math.ceil(((materialsYttervegg.wallLength / 2.6) * materialsYttervegg.wallHeight)/25)
        cartItems.push({
            product:dampTape,
            quantity:amount
        })
    }


 // Calculating for kledning
 // Calculating for kledning
 if (materialsYttervegg.selectedKledningProduct && materialsYttervegg.wallArea) {
    // Extracting the dimension information from the product name
    const dimensionRegex = /(\d+x\d+)/i;
    const matchedDimension = materialsYttervegg.selectedKledningProduct.productName.match(dimensionRegex);

    let matchedCoverageKey;
    if (matchedDimension && matchedDimension[1]) {
        matchedCoverageKey = matchedDimension[1].toLowerCase(); // Convert to lowercase to match the keys in KLEDNING_COVERAGE
    }

    if (matchedCoverageKey && KLEDNING_COVERAGE[matchedCoverageKey]) {
        const requiredCoveragePerM2 = KLEDNING_COVERAGE[matchedCoverageKey].coverage;
        
        totalCoverageRequired = requiredCoveragePerM2 * materialsYttervegg.wallArea; // total linear meters of kledning needed

        const kledningProduct = productsDatabase.find(product => product.id === materialsYttervegg.selectedKledningProduct.id);

        if (!kledningProduct) {
            console.warn("Kledning product not found in database:", materialsYttervegg.selectedKledningProduct.id);
        } else {
            cartItems.push({
                product: kledningProduct,
                quantity: Math.ceil(totalCoverageRequired) // Adjusting the quantity to nearest higher number if needed
            });
        }
    } else {
        console.warn("Matching kledning coverage not found for:", matchedCoverageKey);
    }
}

//calculations for REKKER
const rekker23cm = productsDatabase.find(product => product.id === REKKER['23cm']);
const rekker36cm = productsDatabase.find(product => product.id === REKKER['36cm']);


if (materialsYttervegg.selectedKledningOrientering === "liggende") {
    cartItems.push({
        product: rekker23cm,
        quantity: Math.ceil(calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall))
    });
} else if (materialsYttervegg.selectedKledningOrientering === "stående") {
    cartItems.push({
        product: rekker23cm,
        quantity: Math.ceil(calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall))
    });
    cartItems.push({
        product: rekker36cm,
        quantity: Math.ceil(calculateLekterQuantity(materialsYttervegg.wallLength, materialsYttervegg.wallHeight, materialsYttervegg.wallHeightSide, materialsYttervegg.wallHeightCenter, materialsYttervegg.selectedWall) * 0.7)
    });
}


// Calculations for nails
const spiker = productsDatabase.find(product => product.id === NAILS);
if (materialsYttervegg.selectedKledningProduct && materialsYttervegg.wallArea) {
    const totalNailsRequired = Math.ceil(totalCoverageRequired / 0.6);
    const boxesOfNailsRequired = Math.ceil(totalNailsRequired / 300);
    
    cartItems.push({
        product: spiker,
        quantity: boxesOfNailsRequired
    });
}

//calculations for vinsdperretape   (se om denne må korrigeres. quantity er kanskje feil)
const vindsperretape = productsDatabase.find(product => product.id === VINDSPERRETAPE)
if(materialsYttervegg.selectedVindsperreOptions && materialsYttervegg.selectedVindsperreOptions.length > 0){
    if(materialsYttervegg.wallHeightCenter){
            const amount = Math.ceil(((materialsYttervegg.wallLength / 1.6) * materialsYttervegg.wallHeightCenter)/25)
            cartItems.push({
                product:vindsperretape,
                quantity:amount
            })
    } else{
        const amount = Math.ceil(((materialsYttervegg.wallLength / 1.6) * materialsYttervegg.wallHeight)/25)
        cartItems.push({
            product:vindsperretape,
            quantity:amount
        })
    }
}



//console.log(cartItems);

const mergedCartItems = cartItems.reduce((accumulated, currentItem) => {
    const existingItem = accumulated.find(item => item.product && currentItem.product && item.product.id === currentItem.product.id);
    if (existingItem) {
        existingItem.quantity += currentItem.quantity;
    } else {
        accumulated.push(currentItem);
    }
    return accumulated;
}, []);

const filteredMergedCartItems = mergedCartItems.filter(item => item.product !== undefined);
setCartYttervegg(filteredMergedCartItems);
//console.log(filteredMergedCartItems);


    }
    

   // Additional helper function to compute vindsperre
function calculateVindsperreQuantity(area, vindsperreType) {
    const sizes = ['large', 'medium', 'small'];
    let remainingArea = area;
    const result = [];

    // Check if vindsperreType is valid
    if (!VINDSPERRE[vindsperreType]) {
        console.warn("Invalid vindsperre type:", vindsperreType);
        return result;
    }

    // If it's 'asfalt' or 'gips', process as default and return
    if (vindsperreType === 'asfalt' || vindsperreType === 'gips') {
        const quantity = Math.ceil(area / VINDSPERRE[vindsperreType].default.coverage);
        result.push({
            id: VINDSPERRE[vindsperreType].default.id,
            quantity: quantity
        });
        return result;
    }

    // For 'vindsperre', calculate based on sizes
    for (let size of sizes) {
        const coverage = VINDSPERRE[vindsperreType][size].coverage;
        const quantity = Math.floor(remainingArea / coverage);

        if (quantity > 0) {
            result.push({
                size: size,
                quantity: quantity,
                coverage: coverage,
                id: VINDSPERRE[vindsperreType][size].id
            });
            remainingArea -= quantity * coverage;
        }
    }

    return result;
}



    return (
        <StyledDiv>
            <YetterveggProductsList/>
  
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    // Your styles go here...
`;
