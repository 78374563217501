import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import kledning from '../images/kledning.png';
import terrasse from '../images/terrasse.png';
import lettvegg from "../images/lettvegg.png";
import calculator from "../images/frontImages/calculator.png";
import Leca from "../images/Calculator/Isoblokk.jpg";
import yttervegg from "../images/Calculator/yttervegg.jpg"

export default function ProsjektKalkulator() {
  return (
    <StyledDiv className='container my-5'>
          <div className='headerContainer'>

      <StyledLink to="/prosjektkalkulator"><h3>Prosjektkalkulator</h3></StyledLink>
      <img src={calculator} alt="calc bilde" className='calculatorImg'/>
</div>
      <p>Er du usikker på hva ditt prosjekt vil koste eller hvilke materialer du trenger? Ikke bekymre deg, vi er her for å hjelpe! Med vår prosjektkalkulator gir du oss bare detaljene om ditt prosjekt, og vi presenterer umiddelbart en komplett innkjøpsliste med estimerte priser fra butikken. Tilpass listen som du ønsker, og legg den til i handlekurven for å få tilbud.</p>
<p>Har du forslag til kalkulatorer for andre byggeprosjekter? Vi vil gjerne høre dine ideer! Send oss en epost med tankene dine.</p>

    <div className='wrapper'>
      <StyledLink to='/prosjektkalkulator/kledning'>
        <StyledImg src={kledning} alt='bilde fra mesterbedriften-lund cc2 license' />
        <h5>Kledning</h5>
      </StyledLink>
      <StyledLink to='/prosjektkalkulator/terrasse'>
        <StyledImg src={terrasse} alt='bilde fra viivilla cc2 license' />
        <h5>Terrasse</h5>
      </StyledLink>
      <StyledLink to='/prosjektkalkulator/lettvegg'>
        <StyledImg src={lettvegg} alt='bilde fra viivilla cc2 license' />
        <h5>Lettvegg</h5>
      </StyledLink>
      <StyledLink to='/prosjektkalkulator/lecavegg'>
        <StyledImg src={Leca} alt='bilde fra leca.no' />
        <h5>Lecavegg</h5>
      </StyledLink>
      <StyledLink to='/prosjektkalkulator/yttervegg'>
        <StyledImg src={yttervegg} alt='bilde fra glava.no' />
        <h5>Yttervegg</h5>
      </StyledLink>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-left:10px;
  }

  h5{
    color: #2c3e50;

  }
 
  .wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap:wrap;

}

@media (max-width: 1000px) {
  .wrapper {
    flex-wrap:wrap;
  }
}
@media (max-width: 770px) {
  .wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}



  .calculatorImg{
    filter: invert(100%);
    color: black;
    width: 80px;
    margin-bottom:30px ;
  }

  .headerContainer {
    display: flex;
    align-items: center; // This will vertically align the items in the flex container
    gap: 10px; // This adds a space between the h3 and the img
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  text-align: center;
  margin: 10px;
  color: #2c3e50;
  text-decoration: none;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.01);
    color: tomato;
  }
`;

const StyledImg = styled.img`
  width: 250px;
  height: 145px;
  border-radius: 15px;
  margin-bottom: 10px;
`;
