import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import styled from 'styled-components';
import { doc, getDoc, addDoc, collection, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import slett from "../images/delete.png";

import CartToPDFButton from '../components/CartToPDFButton'


export default function Cart() {
  const { currentUser, cart, setCart } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Notice the use of useNavigate
  const [showSaveForm, setShowSaveForm] = useState(false); // new state variable to control the form display
  const nameInputRef = useRef(null); // new ref to get the value from the form input
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false);
  const [tempAdditionalInfo, setTempAdditionalInfo] = useState('');


  const handleDeleteMessage = () => {
    setAdditionalInfo('');
  };

  const handleEditMessage = () => {
    setTempAdditionalInfo(additionalInfo); // Preload existing message
    handleOpenAdditionalInfoModal(); // Open the modal for editing
  };

  const handleSaveMessage = () => {
    setAdditionalInfo(tempAdditionalInfo); // Set the final message
    handleCloseAdditionalInfoModal(); // Close the modal
    setTempAdditionalInfo(''); // Reset the temporary message
  };
  
  const handleOpenAdditionalInfoModal = () => {
    setShowAdditionalInfoModal(true);
  };

  const handleCloseAdditionalInfoModal = () => {
    setShowAdditionalInfoModal(false);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
}

const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
}


  const handleOpenConfirmModal = () => {
    setShowConfirmModal(true);
  };
  
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  
  useEffect(() => {
    if (currentUser) {
      const fetchUser = async () => {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      };

      fetchUser();
    }
  }, [currentUser]);

  const handleIncreaseQuantity = (productId) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: product.quantity + 1} : product));
  };

  const handleDecreaseQuantity = (productId) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: Math.max(1, product.quantity - 1)} : product));
  };
  
  const handleUpdateQuantity = (productId, quantity) => {
    setCart(prevCart => prevCart.map(product => product.id === productId ? {...product, quantity: Math.max(1, parseInt(quantity))} : product));
  };

  const handleRequestQuote = async () => {
    if (user) {
      try {
        const quoteRequest = {
          userId: currentUser.uid,
          userName: `${user.firstName} ${user.lastName}`,
          address: user.address,
          lat: user.latitude,
          lng: user.longitude,
          products: cart,
          createdAt: serverTimestamp(),
          status: 'new',
          totalSum: getTotalSum(), // Include total sum in quote request
          paymentMade:false,
          additionalMessage: additionalInfo, // Include the additional message
        };
  
        const docRef = await addDoc(collection(db, 'quoteRequests'), quoteRequest);
  
        // Update the document to include the ID
        await updateDoc(docRef, { id: docRef.id });
  
        console.log("Quote request successfully created with ID: ", docRef.id);
  
        // Redirect to the specific quote page
        navigate(`/success`);
  
      } catch (error) {
        console.error("Error creating quote request: ", error);
      }
    }
  };

  const handleSaveCart = async (event) => {
    event.preventDefault(); // prevent the form from causing a page refresh

        // If cart is empty, display a warning and exit the function
        if (!cart || cart.length === 0) {
          alert("You cannot save an empty cart!");
          return;
      }
    if (!nameInputRef.current.value) {
      return; // don't do anything if the name input is empty
    }

    const savedCart = {
      userId: currentUser.uid,
      userName: `${user.firstName} ${user.lastName}`,
      createdAt: serverTimestamp(),
      name: nameInputRef.current.value,
      items: cart,
    };

    try {
      await addDoc(collection(db, 'savedCarts'), savedCart);
      alert('Cart saved successfully!');
      setShowSaveForm(false); // hide the form after successfully saving the cart
    } catch (error) {
      console.error('Error saving cart: ', error);
    }
  };
  
  const handleRemoveProduct = (productId) => {
    setCart(prevCart => prevCart.filter(product => product.id !== productId));
  };
  
  const getArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
  };


  const getTotalSum = () => {
    return cart.reduce((acc, product) => {
        const correctedPrice = (product.byggmakkerPrice || '0').replace(',', '.'); // Replace comma with period
        const parsedPrice = parseFloat(correctedPrice); // Parse corrected price to float
        const parsedQuantity = parseFloat(product.quantity || 0); // Parse quantity to float

        return acc + parsedPrice * parsedQuantity; // Accumulate the total
    }, 0).toFixed(2); // Format the result to two decimal places
};

  const isButtonDisabled = getTotalSum() < 10000;


  const handleTømHandlekurv = () =>{
    setCart([])
  }

  const formatter = new Intl.NumberFormat('no-NO', { style: 'decimal', minimumFractionDigits: 2 });

  const calculateTotal = (price, quantity) => {
    const correctedPrice = (price || '0').replace(',', '.'); // Replace comma with period
    const parsedPrice = parseFloat(correctedPrice); // Parse corrected price to float
    const parsedQuantity = parseFloat(quantity || 0); // Parse quantity to float

    const total = parsedPrice * parsedQuantity; // Calculate the total
    return total.toFixed(2); // Format the total to two decimal places
};

  return (
    <CartContainer>
        {showConfirmModal && (
          <>
          <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 0,
          }}
          onClick={handleCloseConfirmModal}
        />
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '40px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        }}
      >
      {currentUser ? ( <div>
      <span className='x' onClick={handleCloseConfirmModal}>X</span>
{isButtonDisabled ? (
  <>
  <h3>Sum handleliste for lav...</h3>
  <p>Handlelisten din må være på minimum kr. 10 000,-</p>
  </>
) :  (<>
        <h3>Er navn og addresse er riktig?</h3>
        <p>Navn: {user?.firstName} {user?.lastName}</p>
        <p>Adresse: {user?.address}</p>
        </>
      )}

        <div className='text-center'>        
        <button disabled={isButtonDisabled} className='btn btn-success' onClick={handleRequestQuote }>Be om tilbud</button>
        </div>
        <div className='text-center mt-5'>        
        {isButtonDisabled && (<Link to="/profile/redigerprofil">Endre adresse</Link>)}
        </div>
      </div>) : (
        <div>
      <span className='x' onClick={handleCloseConfirmModal}>X</span>
        <h3>Du må logge inn for å be om tilbud</h3>
        
        <div className='text-center my-5'>
        <Link to="/signin">Logg inn</Link>        
        </div>
      </div>
      )
      }
     
      </div>
      </>
    )}
    {showSaveForm && (
    <>
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                zIndex: 0,
            }}
            onClick={() => setShowSaveForm(false)}
        />

        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '40px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1,
                width: '80%', // Adjust based on your preference
                maxWidth: '500px' // Prevent modal from being too wide on large screens
            }}
        >
            <span className='x' onClick={() => setShowSaveForm(false)}>X</span>
            
            <h3>Lagre handleliste</h3>
            <form onSubmit={handleSaveCart}>
                <div>
                    <input 
                        type="text" 
                        ref={nameInputRef} 
                        placeholder="Gi et navn til denne handlekurven" 
                        required 
                        style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px' }} 
                    />
                </div>
                <div className='text-center'>
                <button 
    className='btn btn-outline-info' 
    onClick={() => setShowSaveForm(true)}
    disabled={!cart || cart.length === 0}
>
    {!cart || cart.length === 0 ? "Handlelisten er tom" : "Lagre handleliste"}
</button>
                </div>
            </form>
        </div>
    </>
)}

      <Header>
      <div className='container my-3'>
        <h3>Handlekurv</h3>
        <div className="button-wrapper">
          <button className='btn btn-outline-info' onClick={() => setShowSaveForm(true)}>Lagre handleliste</button>

          <button className='btn btn-outline-danger' onClick={handleOpenDeleteModal}>Tøm handlekurv</button>

          {showDeleteModal && (
    <>
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                zIndex: 0,
            }}
            onClick={handleCloseDeleteModal}
        />

        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '40px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1,
            }}
        >
            <span className='x' onClick={handleCloseDeleteModal}>X</span>
            
            {cart.length === 0 ? (
                <>
                    <h3>Handlekurven er tom</h3>
                    <p>Du har ingen produkter i handlekurven.</p>
                    <div className='text-center mt-4'>
                        <button className='btn btn-secondary' onClick={handleCloseDeleteModal}>Lukk</button>
                    </div>
                </>
            ) : (
                <>
                    <h3>Er du sikker?</h3>
                    <p>Vil du virkelig tømme handlekurven din?</p>
                    
                    <div className='text-center mt-4'>
                        <button className='btn btn-danger' onClick={() => { handleTømHandlekurv(); handleCloseDeleteModal(); }}>Ja, tøm handlekurv</button>
                    </div>
                </>
            )}
        </div>
    </>
)}


        </div>
        </div>
      </Header>
      {cart && cart.length > 0 ? (
        <Table>
        <thead>
          <tr>
            <th></th>
            <th>Produkt</th>
            <th>Pris</th>
            <th>Antall</th>
            <th>Artikkelnummer</th>
            <th>Produkt total</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((product, index) => (
            <tr key={index}>
              <td><img src={product.imageSrc} alt={product.productName} style={{height: '50px'}}/></td>
              <td>
                <ProductNameLink to={`/${product.breadcrumb1}/${product.breadcrumb2}/${product.breadcrumb3}/${product.productName}`}>
                  <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>{product.productName}</h2>
                </ProductNameLink>
              </td>
              <td>kr. {product.byggmakkerPrice} {product.unit}</td>
              <td>
  <QuantityContainer>
    <Button onClick={() => handleDecreaseQuantity(product.id)}>-</Button>
    <QuantityInput type="number" value={product.quantity.toFixed(0)} onChange={e => handleUpdateQuantity(product.id, e.target.value)}/>
    <Button onClick={() => handleIncreaseQuantity(product.id)}>+</Button>
  </QuantityContainer>
</td>
              <td>{getArticleNumber(product)}</td>
              <td>{formatter.format(calculateTotal(product.byggmakkerPrice, product.quantity))}</td>


              <DeleteImg className="deleteIcon" src={slett} onClick={() => handleRemoveProduct(product.id)}/>
            </tr>
          ))}
        </tbody>
      </Table>)
      :
      (
        <div className='tomHandlekurv'>
      <h3>handlekurv er tom...</h3>
      </div>
      )
      }

      { additionalInfo &&
      <div className='mt-2'>
        <p><strong>Teksten under blir sendt til byggevarehus sammen med din handleliste.</strong></p>
        <p>{additionalInfo}</p>

        <button className='btn btn-danger btn-sm me-2' onClick={handleDeleteMessage}>Slett melding</button>
    <button className='btn btn-primary btn-sm' onClick={handleEditMessage}>Endre melding</button>

      </div>
      
      }
      
      <Footer>
  <div className="totalPrice">
    <h2>Total: kr. {formatter.format(getTotalSum())}</h2>

  </div>
  <div className="buttons">
    <Link to="/"><button className='btn btn-secondary'>Fortsett å handle</button></Link>
    <button className='btn btn-success' onClick={handleOpenConfirmModal }>Be om tilbud</button>
  </div>

  {showAdditionalInfoModal && (
  <div className="modalMessage">
    <div className="modal-contentMessage">
      <span onClick={handleCloseAdditionalInfoModal}>&times;</span>
      <h3>Legg til informasjon til varehus</h3>
      <p>Hvis du ønsker tilbud på produkter som ikke finnes på minoppussing.no eller har annen informasjon du ønsker å legge til, kan du skrive det her.</p>
      <textarea
        value={tempAdditionalInfo}
        onChange={(e) => setTempAdditionalInfo(e.target.value)}
        rows="5"
        placeholder="Skriv din melding her..."
      />
      <button onClick={handleSaveMessage}>Lagre melding</button>
    </div>
  </div>
)}


<button className="btn btn-outline-primary my-3" onClick={handleOpenAdditionalInfoModal}>
  Legg til melding til byggevarehus
</button>



  {cart.length > 0 &&  <CartToPDFButton cart={cart} message={additionalInfo}/>}

  
 

</Footer>
    </CartContainer>
  );
}

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden; // Prevent x-axis overflow
  padding: 10px;



  .modalMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal-contentMessage {
  background-color: white;
  width: 80%; /* Larger width */
  height: 50%; /* Larger height */
  max-width: 800px; /* Limit maximum width */
  max-height: 800px; /* Limit maximum height */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: 500px){
    width:98%;
  }
}

.modal-contentMessage span {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 1.5rem;
}

.modal-contentMessage h3 {
  margin-bottom: 20px;
}

.modal-contentMessage textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100px; /* Adjust height as needed */
}

.modal-contentMessage button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-contentMessage button:hover {
  background-color: #0056b3;
}





  .x{
    position:fixed;
    top:0;
    right:10px;
    font-size:16px;
    font-weight:bolder;
    cursor: pointer;
  }

  .tomHandlekurv{
    display:flex;
    justify-content: center;
    align-items:center ;
  }

`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    justify-content: center;
    display: grid;
    grid-template-areas:
      'title'
      'buttons';
  }



  .button-wrapper {
    grid-area: buttons;
    display: flex;
    justify-content: space-between; // Adjusted this to space-between
    gap: 10px;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
    }
  }

  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
`;



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    th:nth-child(5), td:nth-child(5) { // Hides Artikkelnummer
      display: none;
    }

    th:nth-child(1), td:nth-child(1) { // Reduce image size in the first column
      width: 40px;
      img {
        height: 40px;
      }
    }

    th, td {
      padding: 4px; // Reducing padding
      font-size: 12px; // Reducing font size
    }
  }
`;

const Button = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 0;
  padding: 0;
  padding-bottom:4px ;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  @media (max-width: 430px) {
    display:none ;
  }

`;

const QuantityInput = styled.input`
  width: 60px;
  height: 30px;
  text-align: center;
  margin: 0 5px;

  @media (max-width: 768px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensure items take full width

  .totalPrice {
    text-align: right; // Aligns the price to the right
    margin-bottom: 10px; // Space between total and buttons
  }

  .buttons {
    display: flex;
    justify-content: space-between; // Positions buttons on either side
    
    // Ensure buttons are vertically aligned even if they wrap
    align-items: center; 

    button {
      margin: 5px ; // Remove any default margins
    }
  }

  @media (max-width: 768px) {
    .totalPrice h2 {
      font-size: 14px; // Reduce font size of total price
    }

    .buttons button {
      font-size: 12px; // Reduce font size of buttons
      padding: 5px 10px; // Reduce button padding
    }
  }
`;


const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  h2 {
    @media (max-width: 768px) {
      font-size: 11px;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
    @media (max-width: 390px) {
      font-size: 10px !important;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
  }
`;

const DeleteImg = styled.img`
  height: 25px;
  margin-top:15px;
  cursor: pointer;
  
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;
