import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import Nav from "./components/Nav"
import SignIn from "./pages/Signin";
import ConfirmSignIn from "./pages/ConfirmSignIn";
import Profile from "./pages/Profile";
import NavTwo from "./components/NavLine";
import Products from "./pages/Products";
import SingleProduct from './pages/SingleProduct';
import Cart from "./pages/Cart";
import SuccessPage from "./pages/SuccessPage";
import SearchResults from "./pages/SearchResults"
import Kontakt from "./pages/Kontakt";

import SecureRoute from "./admin/SecureRoute";
import AddProduct from "./admin/AddProduct";
import Tilbudsforespørsler from "./admin/Tilbudsforespørsler";
import SingleRequest from "./admin/SingleRequest";
import RegisterByggevarehus from "./admin/RegisterByggevarehus";
import Byggevarehus from "./admin/Byggevarehus";
import HandleRequest from "./admin/HandleRequest";

import Tilbud from "./pages/Profile/Tilbud";
import LagredeHandlelister from "./pages/Profile/LagredeHandlelister"
import RedigerProfil from "./pages/Profile/RedigerProfil";
import ProsjektKalkulator from "./pages/ProsjektKalkulator";
import Kledning from "./pages/calculators/Kledning";
import Terrasse from "./pages/calculators/Terrasse";
import Lettvegg from './pages/calculators/Lettvegg';
import LecaVegg from './pages/calculators/LecaVegg';


import Personvernvilkår from "./pages/Personvernvilkår";
import Hvordan from "./pages/Hvordan";
import Paymenterror from "./pages/Paymenterror";
import YtterVegg from './pages/calculators/YtterVegg';
import Prosjekter from './pages/prosjekt/Prosjekter';
import NyttProsjekt from './pages/prosjekt/NyttProsjekt';
import SingleProject from './pages/prosjekt/SingleProject';
import RedigerProsjekt from './pages/prosjekt/RedigerProsjekt';



function App() {


  return (
    <div className="App">
    <Router>
        <Nav/>
        <NavTwo/>
    <Routes>
    <Route path="/" element={<Home/>} />

    <Route path="/signin" element={<SignIn/>} />
    <Route path="/confirmsignin" element={<ConfirmSignIn/>} />
    <Route path="/profile" element={<Profile/>} />
    <Route path="/cart" element={<Cart/>} />
    <Route path="/success" element={<SuccessPage/>} />
    <Route path="/:breadcrumb1/:breadcrumb2/:breadcrumb3/:productName" element={<SingleProduct/>} />
    <Route path="/:breadcrumb1/:breadcrumb2?/:breadcrumb3?" element={<Products/>} />
    <Route path="/searchresults" element={<SearchResults/>} />
    <Route path="/personvernvilkår" element={<Personvernvilkår/>} />
    <Route path="/kontakt" element={<Kontakt/>} />
    <Route path="/hvordan" element={<Hvordan/>} />
    <Route path="/paymenterror" element={<Paymenterror/>} />


    <Route path="/prosjektkalkulator" element={<ProsjektKalkulator/>} />
    <Route path="/prosjektkalkulator/kledning" element={<Kledning/>} />
    <Route path="/prosjektkalkulator/Terrasse" element={<Terrasse/>} />
    <Route path="/prosjektkalkulator/Lettvegg" element={<Lettvegg/>} />
    <Route path="/prosjektkalkulator/LecaVegg" element={<LecaVegg/>} />
    <Route path="/prosjektkalkulator/Yttervegg" element={<YtterVegg/>} />



    
    <Route path="/profile/lagredehandlelister" element={<LagredeHandlelister/>}/>
    <Route path="/profile/tilbud" element={<Tilbud/>}/>
    <Route path="/profile/redigerprofil" element={<RedigerProfil/>}/>

    <Route path='/admin' element={ <SecureRoute> <Admin/> </SecureRoute> } />
    <Route path='/admin/addproduct' element={ <SecureRoute> <AddProduct/> </SecureRoute> } />
    <Route path='/admin/requests' element={ <SecureRoute> <Tilbudsforespørsler/> </SecureRoute> } />
    <Route path="/admin/requests/:id" element={<SecureRoute><SingleRequest /> </SecureRoute>} />
    <Route path="/admin/handlerequest/:id" element={<SecureRoute><HandleRequest /> </SecureRoute>} />

    <Route path="/admin/byggevarehus" element={<SecureRoute><Byggevarehus /> </SecureRoute>} />
    <Route path="/admin/registerbyggevarehus" element={<SecureRoute><RegisterByggevarehus /> </SecureRoute>} />
    <Route path="/admin/registerbyggevarehus/:id" element={<SecureRoute><RegisterByggevarehus /></SecureRoute>} />


    <Route path='/prosjekter' element={ <Prosjekter/> } />
    <Route path='/nyttprosjekt' element={ <NyttProsjekt/> } />
    <Route path='/prosjekt/:projectId' element={ <SingleProject/> } />
    <Route path="/redigerprosjekt/:projectId" element={<RedigerProsjekt/>} />








    


    </Routes>
    </Router>
    



    </div>
  );
}

export default App;

