import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export default function SearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAllProducts = async () => {
      const q = collection(db, "products");

      try {
        const querySnapshot = await getDocs(q);
        setAllProducts(querySnapshot.docs.map(doc => doc.data()));
      } catch (error) {
        console.error(error);
      }
    };

    loadAllProducts();
  }, []);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const regex = new RegExp(`${searchTerm}`, 'i'); // case-insensitive match
      setSuggestions(allProducts.filter(product => regex.test(product.productName)));
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, allProducts]);

  const handleSearch = (event) => {
    event.preventDefault();

    const regex = new RegExp(`${searchTerm}`, 'i'); // case-insensitive match
    const results = allProducts.filter(product => regex.test(product.productName));
    console.log("Search results: ", results);

    // navigate to the first matched product
    if (results.length > 0) {
      navigate(`/searchresults`, { state: { results: results } });
    }

    setSuggestions([])
  };

  const handleSuggestionClick = (breadcrumb1, breadcrumb2, breadcrumb3, productName) => {
    navigate(`/${breadcrumb1}/${breadcrumb2}/${breadcrumb3}/${productName}`);
    setSuggestions([]); 
  };
  


  return (
    <StyledSearch>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Søk..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {suggestions.length > 0 && (
          <SuggestionList>
            {suggestions.map((item, index) => (
              <SuggestionItem key={index} onClick={() => handleSuggestionClick(item.breadcrumb1, item.breadcrumb2, item.breadcrumb3, item.productName)}>
                <Link to={`/${item.breadcrumb1}/${item.breadcrumb2}/${item.breadcrumb3}/${item.productName}`}>
                  <img src={item.imageSrc} alt={item.productName} className="suggestion-image" />
                  {item.productName}
                </Link>
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}
        <button type="submit">
          {/* SVG remains the same */}
        </button>
      </form>
    </StyledSearch>
  );
  
}

const StyledSearch = styled.div`
  position: relative;
  z-index: 20;

  .suggestion-image {
  width: 30px; /* You can adjust the width as per your needs */
  height: 30px; /* You can adjust the height as per your needs */
  object-fit: cover; /* This ensures the aspect ratio of the image is maintained */
  margin-right: 10px; /* Adds some space between the image and the text */
}


  @media(min-width:860px){
    min-width:400px;
  }
  
  form {
    display: flex;
    justify-content: flex-start;
  }

  input {
    width: 100%;
    border-radius:5px;
    border: 1px solid #2c3e50;
  }

  button {
    position: absolute;
    right: 0;
  }


  button {
    position: absolute;
    right: 2px;
    bottom:1px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 5px; // you can adjust padding as needed

    &:focus {
      outline: none;
    }

    &:hover{
      transform:scale(1.05);
    }

    svg {
      width: 18px;
      height: 18px;
      fill: #000; // color of the icon
    }
    svg:hover{
      fill:tomato;
    }
  }
`;

const SuggestionList = styled.div`
  position: absolute;
  top: 100%;
  background: white;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 2000;
  overflow: auto;

  @media(max-width:860px){
    left:-150%;
    width:250%;
  }

  @media(max-width:670px){
    left:-240px;
    width:260%;
  }

  @media(max-width:600px){
    left:-50px;
    width:180%;
  }
`;

const SuggestionItem = styled.div`
  padding: 10px;
  border-top: 1px solid #ccc;
  cursor: pointer;
  z-index: 20;

  &:hover {
    background: #f7f7f7;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
