import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../firebase/firebase';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import styled from "styled-components"
import AdminNav from './AdminNav';
import { AuthContext } from '../context/AuthContext';

export default function HandleRequest() {
  const { id } = useParams();
  const [request, setRequest] = useState(null);
  const [offers, setOffers] = useState([{ finalPrice: "", file: null }]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchRequest = async () => {
      const docRef = doc(db, 'quoteRequests', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRequest(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchRequest();
  }, [id]);

  const handleFileChange = (e, index) => {
    const newOffers = [...offers];
    const reader = new FileReader();
    reader.onloadend = () => {
      newOffers[index].file = reader.result;
      setOffers(newOffers);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handlePriceChange = (e, index) => {
    const newOffers = [...offers];
    newOffers[index].finalPrice = e.target.value;
    setOffers(newOffers);
  };

  const addOffer = () => {
    setOffers([...offers, { finalPrice: "", file: null }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = doc(db, 'quoteRequests', id);
      let offerUrls = [];

      for(let offer of offers) {
        console.log("offer", offer);
        const storageRef = ref(storage, `quoteFiles/${request.userId}/${Date.now()}`);
        if (offer.file) {
          console.log("Uploading file...");
          await uploadString(storageRef, offer.file, 'data_url');
          const url = await getDownloadURL(storageRef);
          console.log("File uploaded at", url);
          offerUrls.push({
            finalPrice: offer.finalPrice,
            file: url,
          });

          const savings = request.totalSum - offer.finalPrice;
          const quoteData = {
            date: new Date(),
            userName: request.userName,
            userId: request.userId,
            url: url,
            seen: false,
            totalSum: request.totalSum,
            finalPrice: offer.finalPrice,
            savings: savings,
            originalRequestDate: new Date(request.createdAt.seconds * 1000), // add original request date here
            quoteRequestId: id, // add quote request id here
            hasPaidForDownload:false, //changes to true when vipps payment is registerd
          };

          console.log("Adding quote to collection...");
          await addDoc(collection(db, 'quotes'), quoteData);
          console.log("Quote added!");
        } else {
          offerUrls.push(offer);
        }
      }

      console.log("Updating document...");
      await updateDoc(docRef, {
        status: 'Tilbud sendt',
        offers: offerUrls,
      });
      console.log("Document updated!");
    } catch(error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <StyledDiv>
      <AdminNav/>
      <StyledForm onSubmit={handleSubmit}>
        <h1>Handle Request #{request && (request.id)}</h1>
        <div>
          <label>User: </label>
          <span>{request && request.userName}</span>
        </div>
        <div>
          <label>Created At: </label>
          <span>{request && new Date(request.createdAt.seconds * 1000).toLocaleDateString()}</span>
        </div>
        <div>
          <label>Status: </label>
          <span>{request && request.status}</span>
        </div>

        {offers.map((offer, index) => (
          <div key={index}>
            <div>
              <label>TotalSum: </label>
              <span>{request && request.totalSum}</span>
            </div>
            <div>
              <label>Final Price: </label>
              <input
                type="text"
                value={offer.finalPrice}
                onChange={(e) => handlePriceChange(e, index)}
              />
            </div>
            <div>
              <label>File: </label>
              <input type="file" onChange={(e) => handleFileChange(e, index)} />
            </div>
          </div>
        ))}
        <button type="button" onClick={addOffer}>Add Offer</button>
        <button type="submit">Submit</button>
      </StyledForm>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh); // reduced the total height by the height of navigation bar
  padding-top: 100px; // added padding to prevent overlap with the navigation bar
  background-color: #f2f2f2;
`;

const StyledForm = styled.form`
  width: 70%;
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  h1 {
    margin-bottom: 1em;
  }

  div {
    margin-bottom: 1em;
  }

  label {
    display: block;
    margin-bottom: 0.2em;
    font-weight: bold;
  }

  span, input {
    margin-left: 0.5em;
  }

  input[type="text"] {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input[type="file"] {
    display: block;
  }

  button {
    cursor: pointer;
    background: #007BFF;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 3px;
    margin-top: 1em;
  }

  button[type="submit"] {
    float: right;
  }
`;
