import React, { useEffect, useState, useContext, useRef } from 'react';
import { signInWithEmailLink, isSignInWithEmailLink } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { AuthContext } from '../context/AuthContext';
import { Link } from "react-router-dom"

export default function ConfirmSignIn() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const addressInputRef = useRef();

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!addressInputRef.current) {
        return;
      }

      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
      console.log("autocomplete:", autocomplete);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address);

        const location = place.geometry.location;
        setLatitude(location.lat());
        setLongitude(location.lng());
      });
    }

    initializeAutocomplete();
  }, [address]);


  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          const userRef = doc(db, 'users', result.user.uid);
          getDoc(userRef)
            .then((userDoc) => {
              if (userDoc.exists()) {
                const userData = userDoc.data();
                setFirstName(userData.firstName || '');
                setLastName(userData.lastName || '');
                setAddress(userData.address || '');
                setLatitude(userData.latitude || null);
                setLongitude(userData.longitude || null);
                setIsRegistrationComplete(!!userData.firstName && !!userData.lastName && !!userData.address);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.error('Error fetching user data: ', error);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error in email link sign-in: ', error);
        });
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      const fetchUserDetails = async () => {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFirstName(userData.firstName || '');
            setLastName(userData.lastName || '');
            setAddress(userData.address || '');
            setLatitude(userData.latitude || null);
            setLongitude(userData.longitude || null);
            setIsRegistrationComplete(!!userData.firstName && !!userData.lastName && !!userData.address);
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching user data: ', error);
          setIsLoading(false);
        }
      }

      fetchUserDetails();
    } 
  }, [currentUser]);

  const handleCompleteRegistration = async (event) => {
    event.preventDefault();
    const isAnyFieldEmpty = !firstName.trim() || !lastName.trim() || !address.trim();
    if (isAnyFieldEmpty) {
      // At least one field is empty
      return;
    }
  
    const userRef = doc(db, 'users', currentUser.uid);
    await setDoc(userRef, { firstName, lastName, address, latitude, longitude }, { merge: true });
    setIsRegistrationComplete(true);
  }
  

  if (isLoading) {
    return <div className='container my-3'>Loading...</div>
  }

  if (!isRegistrationComplete) {
    return (
      <div className='container my-3'>
      <div className='fromContainer' style={{maxWidth: '600px', margin: '0 auto'}}>
    <h1 style={{fontSize: '24px', marginBottom: '16px'}}>Fullfør registrering</h1>
    <form onSubmit={handleCompleteRegistration} style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
        <input 
            type="text" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            placeholder="Fornavn" 
            required 
            style={{padding: '8px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc'}}
        />
        <input 
            type="text" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            placeholder="Etternavn" 
            required 
            style={{padding: '8px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc'}}
        />
        <input 
            id="address-input" 
            type="text" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
            placeholder="Adresse" 
            required 
            ref={addressInputRef} 
            style={{padding: '8px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc'}}
        />
        <button type="submit" className='btn btn-success' style={{padding: '8px 12px', fontSize: '16px', borderRadius: '4px'}}>Fullfør registrering</button>
    </form>
    </div>
</div>

    );
  } else {
    return (
      <div className='container my-3'>
    <h2 style={{fontSize: '24px', marginBottom: '16px'}}>Din registrering er fullført</h2>
    <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <p style={{fontSize: '16px', margin: '4px 0'}}>Fornavn: {firstName}</p>
        <p style={{fontSize: '16px', margin: '4px 0'}}>Etternavn: {lastName}</p>
        <p style={{fontSize: '16px', margin: '4px 0'}}>Address: {address}</p>
        <p style={{fontSize: '16px', margin: '4px 0'}}>Latitude: {latitude}</p>
        <p style={{fontSize: '16px', margin: '4px 0'}}>Longitude: {longitude}</p>
    </div>
    <Link to="/"><button className='btn btn-primary my-4'>Fortsett</button></Link>
</div>

    );
  }
}