import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { AuthContext } from '../context/AuthContext';
import NavProfile from '../components/NavProfile';
import { Link, useNavigate  } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function Profile() {
    const { currentUser, logout } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if(currentUser) {
            getUserDetails();
        }
    }, [currentUser]);

    const handleLogout = () => {
        logout();
        navigate("/")

    };

    const getUserDetails = async () => {
        const firestore = getFirestore();
        setIsLoading(true);
        try {
            const docRef = doc(firestore, 'users', currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUserDetails(docSnap.data());
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.log("Error getting document:", error);
        }
        setIsLoading(false);
    };

    return (
        <Wrapper>
            <NavProfile/>
            <div className='container my-2'>
                <ProfileContainer>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        userDetails ?
                            <ProfileInfo>
                                <h3>{userDetails.email}</h3>
                                <p>{userDetails.firstName ? `Fornavn: ${userDetails.firstName}` : ''}</p>
                                <p>{userDetails.lastName ? `Etternavn: ${userDetails.lastName}` : ''}</p>
                                <p>{userDetails.address ? `Adresse: ${userDetails.address}` : ''}</p>
                                <p>{userDetails.signUpDate ? `Registreringsdato: ${typeof userDetails.signUpDate.toDate === 'function' ? new Date(userDetails.signUpDate.toDate()).toDateString() : userDetails.signUpDate}` : ''}</p>
                            </ProfileInfo>
                            :
                            <p>Du er ikke logget inn, <Link to="/signup">Logg inn</Link></p>
                    )}
                    <ButtonContainer>
        {userDetails && <Link to="/profile/redigerprofil"><Button variant="info" className="mt-3">Rediger Profil</Button></Link>}
        <Button variant="primary" className="mt-3" onClick={handleLogout}>Logg ut</Button>

    </ButtonContainer>
                </ProfileContainer>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

`;

const ProfileContainer = styled.div`
    width: 80%;
    max-width: 500px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    text-align: center;
`;

const ProfileInfo = styled.div`
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
