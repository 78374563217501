import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import NavProfile from '../../components/NavProfile';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { AuthContext } from '../../context/AuthContext';
import styled from "styled-components";
import _ from 'lodash'; // Import lodash library for grouping
import { Link } from 'react-router-dom';
import BuyButtonComponent from '../../components/BuyButtonComponent';

export default function Tilbud() {
  const { currentUser, unseenQuotes } = useContext(AuthContext);
  const [quoteGroups, setQuoteGroups] = useState([]);
  const [quoteRequests, setQuoteRequests] = useState({});


  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const fetchQuotesAndRequests = async () => {
      // Fetching quotes
      const quotesCollection = collection(db, 'quotes');
      const q1 = query(quotesCollection, where("userId", "==", currentUser.uid));
      const quoteSnapshot = await getDocs(q1);
      const quotes = quoteSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Fetching quoteRequests
      const quoteRequestCollection = collection(db, 'quoteRequests');
      const q2 = query(quoteRequestCollection, where("userId", "==", currentUser.uid));
      const quoteRequestSnapshot = await getDocs(q2);
      const fetchedQuoteRequests = Object.fromEntries(quoteRequestSnapshot.docs.map(doc => [doc.id, doc.data()]));
      
      setQuoteRequests(fetchedQuoteRequests);
      const groupedQuotes = _.groupBy(quotes, 'quoteRequestId');
      setQuoteGroups(groupedQuotes);

      // Mark all quotes as seen
      for (let quote of quotes) {
        if (!quote.seen) {
          const quoteRef = doc(db, 'quotes', quote.id);
          await updateDoc(quoteRef, { seen: true });
        }
      }
    };

    fetchQuotesAndRequests();
  }, [currentUser]);

  const sortedKeys = Object.keys(quoteGroups).sort((a, b) => {
    return quoteGroups[b][0].originalRequestDate.seconds - quoteGroups[a][0].originalRequestDate.seconds;
  });
  
  return (
    <div>
      <NavProfile />
      <div className="container my-5">
        <h3>Dine tilbud</h3>
        {sortedKeys.map(key => {
          const group = quoteGroups[key];
          const firstQuote = group[0]; // We can use the first quote in the group for shared data.
          
          // Retrieve the paymentMade status from the corresponding quoteRequest
          const paymentMadeStatus = quoteRequests[firstQuote.quoteRequestId]?.paymentMade ?? false;
  
          return (
            <StyledCard key={key}>
              <h5>
                Tilbudforespørsel sendt:{" "}
                <strong>
                  {firstQuote.originalRequestDate
                    ? firstQuote.originalRequestDate
                        .toDate()
                        .toLocaleDateString("nb-NO", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric"
                        })
                    : "Dato ikke tilgjengelig"}
                </strong>
              </h5>
              <h5>
                Totalsum på handleliste:{" "}
                <strong>{firstQuote.totalSum.toFixed(2)} kr</strong>
              </h5>
              <div className="text-center my-3">
              {paymentMadeStatus ? (<>

                <h2>Betalt...</h2>
                <p>Takk for din betaling, du kan nå laste ned alle tilbud på din forespørsel.</p>
                </>):(
                <>
                <BuyButtonComponent refId={key} />
                 <p className="text-center">
                Når du har betalt kan du laste ned alle mottatte tilbud på
                forespørsel.
              </p>
                </>
                ) }
              </div>
  
             
  
              <QuoteGrid>
                {group.map(quote => (
                  <QuoteCard key={quote.id}>
                    {quote.seen === false && <span className="new-tag">Ny</span>}
                    <p>Pris fra byggevarehus: <strong> {quote.finalPrice}</strong> kr.</p>
                    <p>Omtrent besparelse: <strong>{quote.savings.toFixed(2)}</strong> kr.</p>
                    {paymentMadeStatus ? (
                      quote.url && (
                        <a
                          href={quote.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFilePdf size={24} />
                          <span>Last ned tilbud</span>
                        </a>
                      )
                    ) : (
                      <Link className="disabled">
                        <FaFilePdf size={24} />
                        <span>Last ned tilbud (ikke betalt)</span>
                      </Link>
                    )}
                  </QuoteCard>
                ))}
              </QuoteGrid>
            </StyledCard>
          );
        })}
      </div>
    </div>
  );
  
}

const StyledCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 50px;

  h5{
    text-align:center ;
  }
  h3{
    margin-top:20px ;
  }

  h2{
    color:darkgreen ;
    font-weight:bold ;
  }
  .betalMedVipps{
    height:40px;

  }
`;

const QuoteGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
`;

const QuoteCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  position: relative;

  .new-tag {
    position: absolute;
    top: -5px;
    right: 5px;
    background: #df3f3a;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disabled {
cursor: not-allowed;
}

.disabled:hover {
    cursor:not-allowed;
}
 
`;
