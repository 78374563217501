import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';

export default function SearchResults() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState('');

  

  useEffect(() => {
    if (location.state) {
      setProducts(location.state.results);
    }
  }, [location.state]);

  useEffect(() => {
    if (products.length > 0) {
      sortProducts();
    }
  }, [sortOrder]);
  

  const renderBreadcrumbs = () => (
    <div className="breadcrumbs my-4">
      {products[0]?.breadcrumb1 && <StyledLink to={`/${products[0].breadcrumb1}`}>{products[0].breadcrumb1}</StyledLink>}
      {products[0]?.breadcrumb2 && <> / <StyledLink to={`/${products[0].breadcrumb1}/${products[0].breadcrumb2}`}>{products[0].breadcrumb2}</StyledLink></>}
      {products[0]?.breadcrumb3 && <> / {products[0].breadcrumb3}</>}
    </div>
  )

  const sortProducts = () => {
    let sortedProducts = [...products];
    if (sortOrder === 'priceLowToHigh') {
      sortedProducts.sort((a, b) => parseFloat(a.byggmakkerPrice) - parseFloat(b.byggmakkerPrice));
    } else if (sortOrder === 'priceHighToLow') {
      sortedProducts.sort((a, b) => parseFloat(b.byggmakkerPrice) - parseFloat(a.byggmakkerPrice));
    }
    setProducts(sortedProducts);
  };
  
  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };
  

  return (
    <Container>
      {products.length > 0 && renderBreadcrumbs()}

      <SelectContainer>
        <StyledSelect  onChange={handleSortChange} value={sortOrder}>
          <option value="">Sorter</option>
          <option value="priceLowToHigh">Pris: Lav til Høy</option>
          <option value="priceHighToLow">Pris: Høy til Lav</option>
        </StyledSelect>
        </SelectContainer>

      <Row xs={2} md={2} lg={5} className="g-4">
        {products.map((product, idx) => (
          <Col key={idx}>
          <StyledLink to={`/${product.breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`}>
              <StyledCard>
                <Card className='card'>
                  <Card.Img variant="top" src={product.imageSrc} />
                  <Card.Body className='bodyWrapper'>
                    <Card.Title>{product.productName}</Card.Title>
                    <PriceUnitContainer>
                      <PriceText>Kr {product.byggmakkerPrice}</PriceText>
                      <UnitText>{product.unit}</UnitText>
                    </PriceUnitContainer>
                    <Advantages>
                      {product.advantages.map((adv, idx) => <li key={idx}>{adv}</li>)}
                    </Advantages>
                  </Card.Body>
                </Card>
              </StyledCard>
            </StyledLink>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end; // Align to the right
  margin-bottom: 15px; // Adjust as needed
`;

const StyledSelect = styled.select`
  width: 30%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;

    @media(max-width:397px){
      font-size:1em ;
    }
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
    @media(max-width:397px){
      font-size:1em ;
    }
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;

const StyledLink = styled(Link)`
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent
`;

const StyledCard = styled.div`
  cursor: pointer;
  .card:hover{
    border:1px solid gray;
    scale:1.01 ;
  }
  .body{
    padding:12px;
  }

  .bodyWrapper{
    padding:16px;
    @media(max-width:388px){
      padding:9px;
    }
    @media(max-width:318px){
      padding:2px;
    }
  }
`;
