
import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProjectCart from './ProjectCart';
import Comments from "./Comments"
import { collection, addDoc,  updateDoc, doc, getDocs, getDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../../firebase/firebase'; // Adjust this import based on your Firebase configuration
import { AuthContext } from '../../context/AuthContext';
import { ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { storage } from '../../firebase/firebase'; // Adjust the import based on your Firebase configuration
import { useParams } from 'react-router';
import { useNavigate, Link } from 'react-router-dom';






export default function RedigerProsjekt() {
  const [items, setItems] = useState([
    {
      id: 'item-1',
      type: 'component',
      content: 'Comments',
      contentType: 'component',
      isContentEditable: false // False for default items like Comments or ProjectCart
    },
    {
      id: 'item-2',
      type: 'component',
      content: 'ProjectCart',
      contentType: 'component',
      isContentEditable: false // False for default items like Comments or ProjectCart
    },
    // ... any other default items
  ]);
        const [projectTitle, setProjectTitle] = useState('');
        const [projectDescription, setProjectDescription] = useState('');
        const [categories, setCategories] = useState([]);
        const [projectKategorier, setProjectKategorier] = useState([]);
        const [selectedCategories, setSelectedCategories] = useState([]);
        const [editType, setEditType] = useState({});
        const [filteredCategories, setFilteredCategories] = useState([]);
        const [editingText, setEditingText] = useState({});
        const [showSuccessMessage, setShowSuccessMessage] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const handleOpenDeleteModal = () => setShowDeleteModal(true);
        const handleCloseDeleteModal = () => setShowDeleteModal(false);




        const { currentUser, projectCart } = useContext(AuthContext);
 
        const userEmailFull = currentUser && currentUser.email ? currentUser.email.split('@')[0] : "";
        const { projectId } = useParams();
        const navigate = useNavigate();



        useEffect(() => {
            if (projectId) {
              // Fetch project data from Firestore using projectId
              const fetchProjectData = async () => {
                const docRef = doc(db, "projects", projectId);
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                  const project = docSnap.data();
                  // Populate state with fetched project data
                  setProjectTitle(project.title);
                  setProjectDescription(project.description);
                  setItems(project.items); // Assuming 'items' is part of your project data
                  setSelectedCategories(project.categories || []);
                  // ... populate other state variables as needed
                }
              };
        
              fetchProjectData();
            }
          }, [projectId]);
        

        
  useEffect(()=>{
  },[items])

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);

    setItems(newItems);
  }

  useEffect(() => {
    const fetchCategories = async () => {
        const querySnapshot = await getDocs(collection(db, "projects"));
        const categoriesSet = new Set();
        querySnapshot.docs.forEach(doc => {
            const project = doc.data();
            project.categories && project.categories.forEach(cat => categoriesSet.add(cat));
        });
        setCategories(Array.from(categoriesSet));
    };

    fetchCategories();
}, []);

const deleteProject = async () => {
  try {
    const docRef = doc(db, "projects", projectId);
    await deleteDoc(docRef);
    console.log("Project deleted successfully");
    // Redirect user after deletion
    navigate('/prosjekter'); // Adjust the route as per your application's routing
  } catch (error) {
    console.error("Error deleting project: ", error);
  }

};

const handleEditTypeChange = (itemId, type) => {
    const currentItem = items.find(item => item.id === itemId);
    if (currentItem && currentItem.contentType === 'text') {
      setEditingText({ ...editingText, [itemId]: currentItem.content });
    }
    setEditType({ ...editType, [itemId]: type });
  };



  const handleAddText = (itemId, text) => {
    setItems(items.map(item => 
      item.id === itemId ? { ...item, content: text, contentType: 'text', type: 'custom', isNewItem: false } : item
    ));
  };
  



const uploadImageToStorage = async (file) => {
  if (!file) return null;

  try {
    // Create a storage reference
    const storageRef = ref(storage, `projectImages/${file.name}`);

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);

    // After upload, get the URL of the file
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading file to Firebase Storage", error);
    return null;
  }
};


const handleImageUpload = async (itemId, event) => {
  const file = event.target.files[0];
  if (file) {
    try {
      // Create a storage reference
      const storageRef = ref(storage, `projectImages/${file.name}`);
      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);
      // After upload, get the URL of the file
      const imageUrl = await getDownloadURL(snapshot.ref);

      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, userContent: imageUrl, userContentType: 'image' } : item
      ));
    } catch (error) {
      console.error("Error uploading file to Firebase Storage", error);
    }
  }
};



const handleAddItem = () => {
  const newItem = {
    id: `item-${Date.now()}-${Math.random().toString(16).slice(2)}`,
    type: 'custom',
    content: '',
    contentType: 'custom',
    isContentEditable: true,
    isNewItem: true, // Flag to indicate it's a new item
  };
  setItems([...items, newItem]);
};


const handleCartDescriptionChange = (itemId, description) => {
  setItems(items.map(item =>
    item.id === itemId ? { ...item, cartDescription: description } : item
  ));
};



const handleAddNewItem = () => {
  const newItem = {
    id: `item-${items.length + 1}`,
    type: 'custom',
    content: '', // initial content
    isContentEditable: true, // allow content editing for this item
  };
  setItems([...items, newItem]);
};

  const handleTitleChange = (event) => {
    setProjectTitle(event.target.value);
  };
  
  const handleDescriptionChange = (event) => {
    setProjectDescription(event.target.value);
  };

  const handleProjectKategorier = (event) => {
    const input = event.target.value;
    setProjectKategorier(input);

    if (!input) {
        setFilteredCategories([]);
        return;
    }

    const matchedCategories = categories.filter(category =>
        category.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCategories(matchedCategories);

    if (event.key === 'Enter' || input.endsWith('  ')) {
        const newCategory = input.trim();
        if (newCategory && !selectedCategories.includes(newCategory)) {
            setSelectedCategories([...selectedCategories, newCategory]);
            setProjectKategorier(''); // Clear the input field
        }
    }
};

const handleImageDescriptionChange = (itemId, description) => {
  setItems(items.map(item => 
    item.id === itemId ? { ...item, imageDescription: description } : item
  ));
};


const handleProjectKategorierChange = (event) => {
    const input = event.target.value;
    setProjectKategorier(input);

    if (!input) {
        setFilteredCategories([]);
        return;
    }

    const matchedCategories = categories.filter(category =>
        category.toLowerCase().startsWith(input.toLowerCase())
    );
    setFilteredCategories(matchedCategories);
};

const handleProjectKategorierKeyDown = (event) => {
    // Check for 'Enter' key or two consecutive spaces
    if (event.key === 'Enter') {
        const newCategory = projectKategorier.trim();
        if (newCategory && !selectedCategories.includes(newCategory)) {
            setSelectedCategories([...selectedCategories, newCategory]);
            setProjectKategorier(''); // Clear the input field after adding the category
        }
    }
};

const handleAddCategory = ()=>{
    handleProjectKategorierChange()
}


const removeCategory = (categoryToRemove) => {
    setSelectedCategories(selectedCategories.filter(category => category !== categoryToRemove));
};

const extractFilePathFromURL = (url) => {
  const matches = url.match(/\/o\/([^?]+)/);
  return matches ? decodeURIComponent(matches[1]) : null;
};

const handleDeleteItem = async (itemId) => {
  const itemToDelete = items.find(item => item.id === itemId);
  
  if (itemToDelete && itemToDelete.contentType === 'image' && itemToDelete.content) {
    try {
      const filePath = extractFilePathFromURL(itemToDelete.content);

      if (filePath) {
        const storageRef = ref(storage, filePath);
        await deleteObject(storageRef);
        console.log("Image deleted successfully from Firebase Storage");
      } else {
        console.log("Invalid file path");
      }
    } catch (error) {
      console.error("Error deleting image from Firebase Storage", error);
    }
  }

  setItems(items.filter(item => item.id !== itemId));
};

const handleAddProjectCart = () => {
  const newProjectCart = {
    id: `item-${Date.now()}`,
    type: 'component',
    content: 'ProjectCart',
    contentType: 'component',
    isContentEditable: false // set this based on your requirements
  };
  setItems([...items, newProjectCart]);
};



  
const publishProject = async () => {
  const serializableItems = items.map(item => {
    let serializableItem = {
      id: item.id,
      type: item.type,
      content: item.content,
      contentType: item.contentType,
      isContentEditable: item.isContentEditable,
      // Include cartDescription for ProjectCart items
      ...(item.content === 'ProjectCart' && { cartDescription: item.cartDescription }),
    };

    // Include imageDescription if the item is an image
    if (item.contentType === 'image') {
      serializableItem.imageDescription = item.imageDescription;
    }

    return serializableItem;
  });
  
  const projectData = {
    title: projectTitle,
    description: projectDescription,
    items: serializableItems,
    selectedCartId: projectCart || [],
    updatedAt: new Date(),
    userId: currentUser.uid, // Assuming currentUser comes from context or authentication
    createdBy: userEmailFull,
    // Do not reset likes, dislikes, commentCount, and shareCount if updating
    ...(projectId ? {} : {
      likes: 0,
      dislikes: 0,
      commentCount: 0,
      shareCount: 0,
    }),
    categories: selectedCategories,
  };
  
    try {
      if (projectId) {
        // Update the existing project
        const projectRef = doc(db, "projects", projectId);
        await updateDoc(projectRef, projectData);
        console.log("Project updated with ID: ", projectId);
        setShowSuccessMessage(true);
        setTimeout(() => {
          navigate(`/prosjekt/${projectId}`);
        }, 3000); // Adjust time as needed
      } else {
        // Create a new project
        const docRef = await addDoc(collection(db, "projects"), projectData);
        console.log("Document written with ID: ", docRef.id);
        // Optionally, update the project data with the generated ID
        await updateDoc(doc(db, "projects", docRef.id), { projectId: docRef.id });
        setShowSuccessMessage(true);
        setTimeout(() => {
          navigate(`/prosjekt/${projectId}`);
        }, 3000); // Adjust time as needed
      }
    } catch (e) {
      console.error("Error handling document: ", e);
      // Handle error
    }
  };
  


const renderComponent = (item) => {
  switch (item.content) {
    case 'Comments':
      return <Comments />;
    case 'ProjectCart':
      // Render ProjectCart only if it's the selected one
      return  <ProjectCart /> 
    // Add cases for other types of components
    default:
      return null;
  }
};

const handleImageChange = async (itemId, event) => {
  const file = event.target.files[0];
  if (file) {
    const uniqueFilename = `${Date.now()}-${file.name}`; // Using a timestamp
    try {
      const storageRef = ref(storage, `projectImages/${uniqueFilename}`);
      const snapshot = await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(snapshot.ref);

      setItems(prevItems => prevItems.map(item => 
        item.id === itemId ? { ...item, content: imageUrl, contentType: 'image', type: 'custom' } : item
      ));
    } catch (error) {
      console.error("Error uploading file to Firebase Storage", error);
    }
  }
};



return (
  <DragDropContext onDragEnd={onDragEnd}>
      <StyledDiv className='container my-5'>
      <div className='breadcrumbs my-4'>
        <StyledLink to="/prosjekter">Prosjekter</StyledLink> /  <ActiveLink to="/RedigerProsjekt">Rediger</ActiveLink> 
        </div>
          <h3>Rediger</h3>
          <div className='topText'>
              <p>Beskriv ditt prosjekt her. Gi prosjektet en tittel, en kort beskrivelse. Du kan også legge til bilder og andre ting.</p>
              <button className='btn btn-outline-danger m-3' onClick={handleOpenDeleteModal}>Slett Prosjekt</button>
              <button className='btn btn-outline-primary m-3' onClick={publishProject}>Lagre endringer</button>

              {showDeleteModal && (
    <>
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                zIndex: 0,
            }}
            onClick={handleCloseDeleteModal}
        />
        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '40px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1,
            }}
        >
            <span className='x' onClick={handleCloseDeleteModal} style={{cursor:"pointer"}}>X</span>
            <>
                <h3>Er du sikker?</h3>
                <p>Vil du virkelig slette dette prosjektet?</p>
                <div className='text-center mt-4'>
                    <button className='btn btn-danger' onClick={() => { deleteProject(); handleCloseDeleteModal(); }}>Ja, slett prosjekt</button>
                </div>
            </>
        </div>
    </>
)}

          </div>


          <div className='firstRow'>
              <div className='defaultItem'>
                  <input
                      type="text"
                      placeholder="Tittel på prosjekt"
                      value={projectTitle}
                      onChange={handleTitleChange}
                      className="titleInput"
                  />
              </div>
              <div className='defaultItem'>
                  <textarea
                      placeholder="Prosjektbeskrivelse"
                      value={projectDescription}
                      onChange={handleDescriptionChange}
                      className="descriptionTextarea"
                  />
              </div>

              <div className='defaultItem'>
                  <div className="input-button-container">
                      <input
                          type="text"
                          placeholder="Kategorier"
                          value={projectKategorier}
                          onChange={handleProjectKategorierChange}
                          onKeyDown={handleProjectKategorierKeyDown}
                          className="titleInput"
                      />
                      <button onClick={handleAddCategory} className="leggTilKnapp btn btn-outline-primary btn-sm m-2">Legg til kategori</button>
                  </div>

                  {projectKategorier && (
                      <div className="autocomplete-options">
                          {filteredCategories.map(category => (
                              <div key={category} onClick={() => setProjectKategorier(category)}>
                                  {category}
                              </div>
                          ))}
                      </div>
                  )}
              </div>

              <div className="selected-categories">
                  {selectedCategories.map((category, index) => (
                      <div key={index} className="selected-category">
                          {category}
                          <span className="remove-category" onClick={() => removeCategory(category)}> X </span>
                      </div>
                  ))}
              </div>
          </div>

          <Droppable droppableId="droppable">
    {(provided) => (
        <div 
            className='dndContainer'
            {...provided.droppableProps}
            ref={provided.innerRef}
        >
           {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
  {(provided) => (
    <div
      className='gridItem'
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {/* Render the component or content based on type */}
      {item.type === 'component' && item.content === 'ProjectCart' && (
        <>
          {renderComponent(item)}
          <textarea
            placeholder="Beskrivelse av handleliste"
            value={item.cartDescription || ''}
            onChange={(e) => handleCartDescriptionChange(item.id, e.target.value)}
            className="cart-description-input"
          />
        </>
      )}

      {item.contentType === 'text' && <div>{item.content}</div>}

      {item.contentType === 'image' && (
        <>
          <img src={item.content} alt="Uploaded" style={{ maxWidth: '100%' }} />
          {item.imageDescription && <p className='imageDesctiptionPrev'>{item.imageDescription}</p>}
          <textarea
            type="text"
            placeholder="Legg til beskrivelse av bilde"
            value={item.imageDescription || ''}
            onChange={(e) => handleImageDescriptionChange(item.id, e.target.value)}
            className="image-description-input"
          />
        </>
      )}

      {/* Show edit options only for new items */}
      {item.isNewItem && (
        <div>
          <button onClick={() => handleEditTypeChange(item.id, 'text')} className='btn btn-outline-primary'>Tekst</button>
          <button onClick={() => handleEditTypeChange(item.id, 'image')} className='btn btn-outline-primary mx-2'>Bilde</button>
          {editType[item.id] === 'text' && (
            <textarea onBlur={(e) => handleAddText(item.id, e.target.value)} className='textArea'/>
          )}
          {editType[item.id] === 'image' && (
            <input type="file" onChange={(e) => handleImageChange(item.id, e)} accept="image/*" />
          )}
        </div>
      )}

      <div className="itemContainer">
        <button className='btn btn-outline-danger deleteButton' onClick={() => handleDeleteItem(item.id)}>Slett</button>
      </div>
    </div>
  )}
</Draggable>

))}

            {provided.placeholder}
        </div>
    )}
</Droppable>

          <button onClick={handleAddItem} className='btn btn-outline-primary my-4'>Legg til bilde eller tekst</button>
          <button onClick={handleAddProjectCart} className='btn btn-outline-primary mx-4 my-4'>Legg til ny handleliste</button>


      </StyledDiv>

  </DragDropContext>
);
}

const StyledDiv = styled.div`

.slettProsjekt{
  max-height: 38px;
}

.success-message {
  /* Your styles here */
  color: green;
  padding: 10px;
  margin-bottom: 10px;
}

.textArea{
  width:100%;
  height:100% ;
}
  h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .leggTilKnapp{
    white-space: nowrap;
  }
  .selected-categories {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .selected-category {
        display: inline-flex;
        align-items: center;
        background-color: #233148;
        color: white;
        font-weight:500 ;
        border-radius: 15px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;

        .remove-category {
            margin-left: 5px;
            cursor: pointer;
            color: #999;
            &:hover {
                color: #333;
                color:darkred;

            }
        }
    }
  .topText{
    display:flex;
    flex-direction:row ;
    justify-content: space-between ;
  }

  .titleInput, .descriptionTextarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }

  .descriptionTextarea {
    height: 100px; // Adjust height as needed
    resize: vertical; // Allow vertical resize
  }
  .input-button-container {
  display: flex;
  align-items: center; /* Align vertically */
}

  .firstRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .defaultItem {
    flex-grow: 1;
    border: 1px solid #ddd;
    padding: 10px;
    margin-right: 10px; /* Add some space between items */
  }

  .dndContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Default to two columns
    grid-gap: 10px;
    grid-auto-rows: minmax(min-content, max-content);

    @media (max-width: 768px) { // Adjust the breakpoint as needed
      grid-template-columns: 1fr; // One column on small screens
    }
  }

  .gridItem {
    min-height:150px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start; // Align content to start
  }

  @media(max-width:500px){
    .gridItem{
        width:80% ;
    }
}

  .textAreaItem, .imageUploadItem {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}

.textAreaItem {
    height: 150px; /* Adjust the height as needed */
}

.imageUploadItem {
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deleteWrapper{
  
}
.itemContainer {
  position: relative;
  padding-top: 30px; /* Adjust as needed to avoid content overlap */
  padding-right: 30px; /* Adjust as needed */
}

.deleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
  .dragHandle {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    user-select: none;
  }

  .autocomplete-options {
        position: absolute;
        border: 1px solid #ddd;
        border-top: none;
        z-index: 1000;
        background: white;
        width: 80%;
        max-height: 200px;
        overflow-y: auto;

        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
  
    .breadcrumbs{
    text-decoration: none; // Removes the underline
    color: inherit; // Inherits the color from its parent
    }
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;

const ActiveLink = styled(Link)`
text-decoration: none; // Removes the underline
color: tomato; // Inherits the color from its parent

`;