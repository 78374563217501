import React,{useContext, useEffect, useState} from 'react'
import styled from "styled-components"
import { AuthContext } from '../../context/AuthContext'
import { db } from '../../firebase/firebase'
import {collection, query, where, getDocs, get, doc,getDoc } from "firebase/firestore"
import { Link } from 'react-router-dom';
import slett from "../../images/delete.png";


export default function LecaProducts() {

    const {materialsLecaVegg, cart, setCart} = useContext(AuthContext)

    const [materials, setMaterials] = useState([])


    //const productIds = {
    //    såleblokk: "q38IwcJ30URpYRRHDoHS",
    //    ublokk30: "iQ71oBHgaIX8ugEtDpa0",
    //    ublokk35: "db1EVNSxFV39ySVgg5Od",
    //    isoblokk30: "ga1l5DS4FmONbaqM4pWr",
    //    isoblokk35: "pTUNDQVMVp8aP7zUYtob",
    //    multiblokk30: "Xz2AfSTqAJMSrxXldlkt",
    //    multiblokk35: "FzyG7vLn0OTApimBjLRT",
    //    armeringTypeUblokk: "TUdBmbdNNdL3YXDEKPYj",
    //    sikksakkArmering: "81ecDi4I7APt0cr4at0q",
    //    laftestrimle: "qZJcbyeu5iLLJYdaqkpc",
    //    fugearmering: "KK9g3VCKsuMjg2xnK7KZ",
    //    m5: "G1GI4kYcXnzqU49vYSwY",
    //    b20: "0RAP5GZXbAvc3WyJjIqm"
    //  };
  

    const fetchProductsByIds = async (productIds) => {
      const productsCollection = collection(db, 'products');
      const products = [];
  
      for (const id of productIds) {
        const docRef = doc(productsCollection, id);
        const docSnapshot = await getDoc(docRef);
  
        if (docSnapshot.exists()) {
          products.push({ id: docSnapshot.id, data: docSnapshot.data() });
        } else {
          console.log(`No document found for ID: ${id}`);
        }
      }
  
      return products;
    };



    useEffect(() => {
      const wallThickness = materialsLecaVegg.wallThickness === "30" ? "30" : "35";
      const productsToFetch = [];
      const quantitiesById = {};
  
      const materialsForCart = {
        numberOfSåleblokks: { id: såleblokk, quantity: materialsLecaVegg.numberOfSåleblokks || 0 },
        numberOfUblokks: { id: wallThickness === "30" ? ublokk30 : ublokk35, quantity: materialsLecaVegg.numberOfUblokks || 0 },
        numberOfIsoblokk: { id: wallThickness === "30" ? isoblokk30 : isoblokk35, quantity: materialsLecaVegg.numberOfIsoblokk || 0 },
        numberOfMultiblokks: { id: wallThickness === "30" ? multiblokk30 : multiblokk35, quantity: materialsLecaVegg.numberOfMultiblokks || 0 },
        
        numberOfArmeringTypeUblokk: { id: armeringTypeUblokk, quantity: materialsLecaVegg.numberOfArmeringTypeUblokk || 0 },
        numberOfB20: {id:b20, quantity: materialsLecaVegg.numberOfB20 || 0},
        numberOfM5: {id: m5, quantity:materialsLecaVegg.numberOfM5 || 0},
        numberOfFugearmering: {id:fugearmering, quantity:materialsLecaVegg.numberOfFugearmering || 0},
        numberOfLaftestrimle: {id: laftestrimle, quantity:materialsLecaVegg.numberOfLaftestrimle || 0},
        numberOfSikksakkarmering: {id: sikksakkArmering, quantity:materialsLecaVegg.numberOfSikksakkarmering || 0},
      };  
      // Note: Make sure all the IDs like såleblokk, ublokk30, etc. are defined somewhere in your code
  
      for (const materialKey in materialsForCart) {
        const material = materialsForCart[materialKey];
        if (material.quantity > 0) {
          productsToFetch.push(material.id);
          quantitiesById[material.id] = material.quantity;
        }
      }
  
      if (productsToFetch.length > 0) {
        fetchProductsByIds(productsToFetch).then(fetchedProducts => {
          const productsWithQuantities = fetchedProducts.map(product => {
            return {
              product: product.data, // Use the data property directly
              quantity: quantitiesById[product.id] // Keep quantity separate
            };
          });
  
          setMaterials(productsWithQuantities);
        }).catch(error => {
          console.error("Error fetching products:", error);
        });
      }
    }, [materialsLecaVegg]); // Only materialsLecaVegg is a dependency here
      

      
      

const såleblokk = "q38IwcJ30URpYRRHDoHS"     //tror jeg
const ublokk30 = "iQ71oBHgaIX8ugEtDpa0"
const ublokk35 = "db1EVNSxFV39ySVgg5Od"
const isoblokk30 = "ga1l5DS4FmONbaqM4pWr"
const isoblokk35 = "pTUNDQVMVp8aP7zUYtob"
const multiblokk30 = "Xz2AfSTqAJMSrxXldlkt"
const multiblokk35 = "FzyG7vLn0OTApimBjLRT"
const armeringTypeUblokk = "TUdBmbdNNdL3YXDEKPYj"
const sikksakkArmering = "81ecDi4I7APt0cr4at0q" //tror jeg
const laftestrimle = "qZJcbyeu5iLLJYdaqkpc"
const fugearmering = "KK9g3VCKsuMjg2xnK7KZ"
const m5 = "G1GI4kYcXnzqU49vYSwY"
const b20 = "0RAP5GZXbAvc3WyJjIqm"

 
const handleArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
};

const handleIncreaseQuantity = (productId) => {
    setMaterials((prevCartProducts) => {
      return prevCartProducts.map((item) => {
        if (item.product.id === productId) {
          return { ...item, quantity: item.quantity + 1 };
        } else {
          return item;
        }
      });
    });
};

const handleDecreaseQuantity = (productId) => {
    const updatedCart = materials.map(item => {
      if (item.product && item.product.id === productId) {
        return { ...item, quantity: Math.max(1, item.quantity - 1) };
      } else {
        return item;
      }
    });
    setMaterials(updatedCart);
};

const handleUpdateQuantity = (productId, quantity) => {
    const updatedCart = materials.map(item => {
      if (item.product && item.product.id === productId) {
        return { ...item, quantity: Math.max(1, parseInt(quantity)) };
      } else {
        return item;
      }
    });
    setMaterials(updatedCart);
};

const handleRemoveProduct = (productId) => {
    const updatedCart = materials.filter(item => item.product.id !== productId);
    setMaterials(updatedCart);
};


const getTotalSum = () => {
  if(materials.length > 0){
      return materials.reduce((total, item) => {
          const product = item.product;
          const price = product && product.byggmakkerPrice 
                        ? parseFloat((product.byggmakkerPrice || '0').replace(',', '.')) 
                        : 0;
          const quantity = Math.round(parseFloat(item.quantity || 0)); // parse quantity to float, round it to the nearest integer
          return total + (price * quantity);
      }, 0);
  }
  return 0;
};




const transferToGlobalCart = () => {
    // Transform the terrasseMaterials array
    const transformedCartTerrasseMaterials = materials.map(item => {
        const {product, quantity} = item;
        return {...product, quantity}; // Spread the product object and add the quantity field
    });

    // Create a new array with the sum of the quantities for the same items
    const mergedCart = [...cart, ...transformedCartTerrasseMaterials].reduce((acc, item) => {
        const existingItem = acc.find(
            accItem => accItem.id === item.id
        );
        if (existingItem) {
            existingItem.quantity += item.quantity;
        } else {
            acc.push(item);
        }
        return acc;
    }, []);
    setCart(mergedCart);
    setMaterials([]); // Clear the local terrasseMaterials
    scrollToTop();
};

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth"  // Optional: smooth scrolling
  });
}
  

const formatter = new Intl.NumberFormat('no-NO', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });


const sumFixer = (price, quantity) => {
  const correctedPrice = (price || '0').replace(',', '.'); // replace comma with period
  const parsedPrice = parseFloat(correctedPrice); // parse corrected price to float
  const parsedQuantity = Math.round(parseFloat(quantity || 0)); // parse quantity to float, round it to the nearest integer
  const sum = parsedPrice * parsedQuantity; // calculate the sum
  
  return sum;
}

  return (
    <CartContainer>
    <>
    {materials  && materials.length > 0 && ( <Table>
      <thead>
        <tr>
          <th></th>
          <th>Produkt</th>
          <th>Pris</th>
          <th>Antall</th>
          <th>Artikkelnummer</th>
          <th>Produkt total</th>
        </tr>
      </thead>
      <tbody>
        {materials  && materials.map((item, index) => (
  <tr key={index}>
    <td>
      {item.product && item.product.imageSrc && 
        <img src={item.product.imageSrc} alt={item.product.productName || 'Product Image'} style={{ height: '50px' }} />
      }
    </td>
    <td>
      {item.product && 
        <ProductNameLink to={`/${item.product.breadcrumb1}/${item.product.breadcrumb2}/${item.product.breadcrumb3}/${item.product.productName}`}>
          <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>
            {item.product.productName}
          </h2>
        </ProductNameLink>
      }
    </td>
    <td className='price'>{item.product && `kr. ${item.product.byggmakkerPrice} ${item.product.unit || ''}`}</td>
    <td>
      <QuantityContainer>
      <Button onClick={() => item.product && handleDecreaseQuantity(item.product.id)}>-</Button>
        <QuantityInput type="number" value={item.quantity.toFixed(0) || 0} onChange={e => item.product && handleUpdateQuantity(item.product.id, e.target.value)} />
        <Button onClick={() => item.product && handleIncreaseQuantity(item.product.id)}>+</Button>
      </QuantityContainer>
    </td>
    <td>{item.product && handleArticleNumber(item.product)}</td>
    <td>
  {item.product && typeof item.product.byggmakkerPrice !== 'undefined' && typeof item.quantity === 'number'
    ? `kr. ${formatter.format(sumFixer(item.product.byggmakkerPrice, item.quantity))}`
    : 'N/A'}
        
    


</td>



    <td>
      {item.product && 
        <DeleteImg className="deleteIcon" src={slett} onClick={() => handleRemoveProduct(item.product.id)} />
      }
    </td>
  </tr>
))}

      </tbody>
    </Table>
    )}

    {materials  && materials.length > 0 && (
  <Footer>
    <div className="totalPrice">
    <h2>Total: kr. {formatter.format(getTotalSum())}</h2>
    </div>
    <div className="buttons">
      <Link to="/"><button className='btn btn-secondary'>Fortsett å handle</button></Link>
      <button className='btn btn-success' onClick={transferToGlobalCart}>Legg alle produkter i handlekurv</button>
    </div>
  </Footer>
)}

    </>
    </CartContainer>
  )
}


const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden; // Prevent x-axis overflow
  padding: 10px;
  @media(max-width:430px){
    padding:0;
  }

  @media (max-width: 390px) {
    .price{
      font-size: 10px !important;
    }
    }
`;



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    th:nth-child(5), td:nth-child(5) { // Hides Artikkelnummer
      display: none;
    }

    th:nth-child(1), td:nth-child(1) { // Reduce image size in the first column
      width: 40px;
      img {
        height: 40px;
      }
    }

    th, td {
      padding: 4px; // Reducing padding
      font-size: 12px; // Reducing font size
    }
  }
`;

const Button = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display:none;

  }


`;

const QuantityInput = styled.input`
  width: 60px;
  height: 30px;
  text-align: center;
  margin: 0 5px;


  @media (max-width: 768px) {
    min-width:50px;
    height: 25px;
    font-size: 12px;
  }
  @media(max-width:430px){
    padding:0 !important;
    margin:0 !important;
    *:focus, input:focus {
    outline: none;
  }
    
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensure items take full width

  .totalPrice {
    text-align: right; // Aligns the price to the right
    margin-bottom: 10px; // Space between total and buttons
  }

  .buttons {
    display: flex;
    justify-content: space-between; // Positions buttons on either side
    
    // Ensure buttons are vertically aligned even if they wrap
    align-items: center; 

    button {
      margin: 5px ; // Remove any default margins
    }
  }

  @media (max-width: 768px) {
    .totalPrice h2 {
      font-size: 14px; // Reduce font size of total price
    }

    .buttons button {
      font-size: 12px; // Reduce font size of buttons
      padding: 5px 10px; // Reduce button padding
    }
  }
  
`;


const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  h2 {
    @media (max-width: 768px) {
      font-size: 12px !important;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
    @media (max-width: 390px) {
      font-size: 10px !important;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
  }
`;

const DeleteImg = styled.img`
  height: 25px;
  margin-top:15px;
  cursor: pointer;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;

`;

//const såleblokk = "q38IwcJ30URpYRRHDoHS"     //tror jeg
//const ublokk30 = "iQ71oBHgaIX8ugEtDpa0"
//const ublokk35 = "db1EVNSxFV39ySVgg5Od"
//const isoblokk30 = "ga1l5DS4FmONbaqM4pWr"
//const isoblokk35 = "pTUNDQVMVp8aP7zUYtob"
//const multiblokk30 = "Xz2AfSTqAJMSrxXldlkt"
//const multiblokk35 = "FzyG7vLn0OTApimBjLRT"
//const armeringTypeUblokk = "TUdBmbdNNdL3YXDEKPYj"
//const sikksakkArmering = "81ecDi4I7APt0cr4at0q" //tror jeg
//const laftestrimle = "qZJcbyeu5iLLJYdaqkpc"
//const fugearmering = "KK9g3VCKsuMjg2xnK7KZ"
//const m5 = "G1GI4kYcXnzqU49vYSwY"
//const b20 = "0RAP5GZXbAvc3WyJjIqm"