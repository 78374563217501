import React from 'react';
import styled from "styled-components";
import ProsjektKalkulator from '../ProsjektKalkulator';

export default function ProsjektkalkulatorFront() {

    return (
        <StyledDiv>
        <div className='container my-5'>
            <div className='wrapper'>
            <ProsjektKalkulator/>
              
                </div>
                </div>
        </StyledDiv>
    )
}

const StyledDiv = styled.div`

.container{
    padding:0;
}
  p{
    margin-left:13px;
  }

.wrapper{
   background-color: #ecf0f1;
   border-radius:10px ;
}

.calculatorImg{
    filter: invert(100%);
    color: black;
    width: 80px;
    

  }


`;
