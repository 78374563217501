import React, { useState } from 'react';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';

export default function PriceInfoIcon({ infoText }) {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <StyledDiv 
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
        >
            <FaInfoCircle color='gray' size="10px"  className='icon'/>
            {showPopup && <div className="info-popup">{infoText}</div>}
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;

    .icon{
        position:absolute;
        top:-20px;
        left:-10px;
    }

    .info-popup {
        position: absolute;
        font-size:12px ;
        bottom: 29px;  // Adjust this value to place the popup below the icon
        left: 50px;     // Centers the popup under the icon
        transform: translateX(-50%);  // Helps in centering the popup
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        white-space: normal;
        z-index: 1000;
    }
`;
