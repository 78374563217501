import React from "react";
import styled from "styled-components";

export default function Hvordan() {
  return (
    <ProfilWrapper>
      <div className="container">
        <div className="row my-3">
          <div className="col">
            <h3>Hvordan virker MinOppussing</h3>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-sm-8   col-lg-6">
            <h5>
              MinOppussing er utviklet for å gi deg en enklere og billigere måte
              å planlegge, bestille og gjennomføre din oppussingsprosjekter.
            </h5>
            <br />
            <h5>
              {" "}
              På MinOppussing.no kan du bygge din handleliste, be om tilbud og få
              rabatterte tilbud fra dine lokale byggevarehus. Deretter velge det tilbudet som passer best for
              deg.
            </h5>
            <br />
            <p>
            Etter å ha samlet alle produktene du trenger i handlelisten din, kan du gå videre og be om tilbud på alle varer. 
            MinOppussing.no sender da handlelisten din til alle byggevarehus i nærheten av den adressen du har valgt, for å be om et tilbud på dine vegne.
            </p>
            <p>
            Så snart byggevarehusene har satt sammen sine tilbud, vil de bli tilgjengelige på profilen din. Ved å besøke <strong>minoppussing.no/profile/tilbud</strong> og fullføre de nødvendige trinnene, 
            vil du få en detaljert oversikt over alle mottatte tilbud. Dette inkluderer pris, kostnaden for hjemmelevering, og en detaljert liste over varene i hvert tilbud.
            </p>
            <p>
            Etter å ha valgt det mest fordelaktige tilbudet for deg, kan du enten ta det med til det aktuelle byggevarehuset for å hente de rabatterte byggevarene, eller du kan be om hjemmelevering.
            </p>
            <p>
            MinOppussing bygger på prinsippet om kvantumsrabatt. Dette betyr at ditt byggeprosjekt må ha en viss størrelse. Av denne grunn må handlelisten din være på minst 10 000 kr.
            </p>
          </div>
          <div className="col-sm-8  col-lg-6">

          <div className="videoWrapper">
              <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/L9F7ESw_HmQ" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>

          </div>
        </div>
      </div>
    </ProfilWrapper>
  );
}

const ProfilWrapper = styled.div`

h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  margin-top: 50px;
  @media (max-width: 490px) {
    margin-top: 10px;
  }

  p {
    font-size: 14px;
  }
  h5 {
    font-size: 15px;
    font-weight: bold;
  }

  .videoWrapper {
    width: 100%;
    height: 400px;
  }
`;