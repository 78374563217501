import React, { useContext } from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from "../images/logo.png";
import logoIcon from "../images/logoIcon.png";
import SideCart from './SideCart';
import profileImg from "../images/user.png";
import SearchBar from './SearchBar';

export default function Nav() {
  const { currentUser, unseenQuotes } = useContext(AuthContext);
  const userEmailFull = currentUser && currentUser.email ? currentUser.email.split('@')[0] : "";
  const userEmail = userEmailFull.length > 10 ? `${userEmailFull.slice(0, 10)}...` : userEmailFull;



  return (
    <StyledDiv>
      <div className='d-flex flex-column px-3 mainWrapper'>
        <div className='d-flex justify-content-between align-items-center innerWrapper'>
          <a href="/"><img src={logo} alt="logo" className='logo pe-2'/></a>
          <a href="/"><img src={logoIcon} alt="logo icon" className='logoIcon pe-2'/></a>
          <SearchBar/>
          <div className='d-flex align-items-end'>
            {userEmail ? 
              (<Link to="/profile" className='profileWrapper'>
                <img src={profileImg} alt="profileIcon" className='profileImg' />
                {unseenQuotes > 0 && <div className="quote-count">{unseenQuotes}</div>} {/* Display unseen quotes count */}
                <div className="px-2 userEmail">{userEmail}</div>
              </Link>)
            : (<div className="me-3 px-2 login1"><Link to="/signin" className='linkLoggInn'>Logg inn</Link></div>)}
            <SideCart/>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .mainWrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background-color: white;
  }

  .innerWrapper {
    position: relative;
    z-index: 10;
    margin-top: 10px;
    
    @media(max-width: 600px) {
      margin-top: 15px;
    }
  }

  .profileWrapper {
    position: relative; /* Make the profileWrapper relative so the quote-count can be positioned in respect to this element */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    text-decoration: none;
    font-size: 0.8em;

    &:hover {
      color: tomato;
      text-decoration: underline;
    }
  }

  .profileImg {
    height: 25px;
  }

  .quote-count {
    position: absolute;
  top: -5px;
  right: 6px;
  background: #df3f3a;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .userEmail {
    @media(max-width: 600px) {
      max-width: 10ch;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .logo {
    height: 45px;
    width: auto;
    display: block;

    @media(max-width: 600px) {
      display: none;
    }
  }
  
  .logoIcon {
    height: 35px;
    width: auto;
    display: none;

    @media(max-width: 600px) {
      display: block;
    }
  }
  
  .cart {
    height: 30px;
  }

  .linkLoggInn{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .login1{
    margin-bottom:10px;
  }
`;