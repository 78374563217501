import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import logo from "../images/logo.png"
import { FaFilePdf } from 'react-icons/fa';


const CartToPDFButton = ({ cart, message }) => {

    const getArticleNumber = (product) => {
        const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
        return articleNumberObject ? articleNumberObject.value : "";
    };

    const generatePDF = (preparedContent) => {
        const input = document.createElement("div");
        input.id = "pdf-cart-container";
        input.innerHTML = preparedContent;
        document.body.appendChild(input);

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(imgData, "PNG", 0, 0);
                pdf.save("Handleliste-pusseno.pdf");
                document.body.removeChild(input);
            })
            .catch(err => {
                console.error("Error generating PDF: ", err);
                document.body.removeChild(input);
            });
    };

    const downloadCartAsPDF = () => {
        const preparedContent = `
        <div style="margin: 20px;">
          <img src="${logo}" alt="logo for pdf" style="width: 400px; margin-bottom: 20px;"/>
          <h3 style="margin-bottom: 20px;">Handleliste</h3>
          <table>
              <thead>
                  <tr>
                      <th style="padding-right: 20px;">Produkt</th>
                      <th style="padding-right: 20px;">Antall</th>
                      <th>Artikkelnummer</th>
                  </tr>
              </thead>
              <tbody>
                  ${cart.map(product => `
                      <tr>
                          <td style="padding-right: 20px;">${product.productName}</td>
                          <td style="padding-right: 20px;">${product.quantity.toFixed(0)}</td>
                          <td>${getArticleNumber(product)}</td>
                      </tr>
                  `).join('')}
              </tbody>
          </table>
          ${message ? `<div style="margin-top: 20px;">${message}</div>` : ''}
        </div>
        `;
        generatePDF(preparedContent);
    };
    
    
    

    return (
        <div className='d-flex justify-content-center'>
    <button className='btn btn-link' onClick={downloadCartAsPDF} style={{ display: 'flex', alignItems: 'center' }}>
        <FaFilePdf color="#0d6efd" size={24} />
        <span>Last ned handleliste som pdf</span>
    </button>
</div>

    );
};

export default CartToPDFButton;
