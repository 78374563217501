import React, { useContext } from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export default function NavProfile() {
  const { unseenQuotes } = useContext(AuthContext);

  return (
    <StyledDiv>
      <div className='d-flex flex-column mainWrapper'>
        <div className='d-flex justify-content-start  h-100'>
          <StyledLink to="/profile/tilbud" activeClassName='active'>
            <div className='px-5 items'>
              Tilbud
              {unseenQuotes > 0 && <span className='quote-count'>{unseenQuotes}</span>}
            </div>
          </StyledLink>
          <StyledLink to="/profile/lagredehandlelister" activeClassName='active'>
            <div className='px-5 items optional'>Lagrede handlelister</div>
          </StyledLink>
          <StyledLink to="/profile/redigerprofil" activeClassName='active'>
            <div className='px-5 items optional'>Rediger profil</div>
          </StyledLink>
          
        </div>
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .mainWrapper {
    width: 100%;
    height: 34px;
    background-color: #fff;
    border-bottom: 0.4px solid #868686a1;
    overflow: hidden;
  }

  .items {
    padding-top: 7px;
    font-size: 12px;
    height: 100%;  
    display: flex; 
    align-items: center; 
    position: relative;
  }

  .items:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  .quote-count {
    position: absolute;
    top: 1px;
    right: 6px;
    background: #df3f3a;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .items{
    @media (max-width: 500px) {
      white-space:nowrap ;
      padding-left:25px !important;
      padding-right:25px !important;
    }
    @media (max-width: 350px) {
      white-space:nowrap ;
      padding-left:10px !important;
      padding-right:10px !important;
    }
  }

`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active .items {
    background-color: lightgray;
  }
`;
