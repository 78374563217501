import React,{useState, useContext, useEffect} from 'react'
import styled from "styled-components"
import {Link} from "react-router-dom"
import DeckDesigner from './DeckDesigner'
import { Card, Row } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { collection, getDocs, query, where } from "firebase/firestore";
import {db} from "../../firebase/firebase"
import small from "../../images/098.avif"
import medium from "../../images/148.avif"
import large from "../../images/198.avif"
import huge from "../../images/223.avif"
import TerrasseProducts from './TerrasseProducts';
import FineProdukterTerrasse from './FineProdukterTerrasse';




export default function Terrasse() {

    useEffect(() => {
        if (window.gtag) { // gtag is Google's Global Site Tag
          window.gtag('config', 'G-HQP57VRNM4', {
            'page_path': '/prosjektkalkulator/terrasse'
          });
        }
      }, []);

    const TERRASSEBORD_COVERAGE = {
        '28x120': {
            dimension: '28x120',
            coverage:  8.13
        },
        '28x145': {
            dimension: '28x145',
            coverage: 6.76
        },
        '28x095': {
            dimension: '28x095',
            coverage: 10.2
        },
        '21x095':{
            dimension: '21x095',
            coverage: 10.2
        }
    };

    const BJELKELAG_DIMENSIONS = {
        "small": { dimension: "48x98", productId: "crHgzTAynKJSQKKyFECE" },
        "medium": { dimension: "48x148", productId: "ZUpgVabv3NUNoWEZn6Di" },
        "large": { dimension: "48x198", productId: "VNXLFOdk136AvMYhs7sO" },
        "huge": { dimension: "48x223", productId: "KK607Y3xvulqM2fq1qNW" },
      };

      const SCREW_PACKS_TERRASSEBORD = {
        TERRASSE_250: {
            productId: 'qmZ1avwHm7cxfXSiwglZ',
            packSize: 250
        },
        TERRASSE_1000: {
            productId: 'sUjIvM5XLDsMtCJRDevF',
            packSize: 1000
        }
    };

    const SCREW_PACKS_BJELKELAG = {
        BJELKELAG_100: {
            productId: 'mF8XxM7Xgp2KON5I6Tn1',
            packSize: 100
        },
        BJELKELAG_400: {
            productId: '65jAhJZEb2ubb6TXZvkF',
            packSize: 400
        }
    };


    const {terrasseArea, setTerrasseMaterials, terrasseMaterials} = useContext(AuthContext)
    const [terrassebordProducts, setTerrassebordProducts] = useState([])
    const [selectedTerrassebord, setSelectedTerrassebord] = useState([])
    const [selectedBjelkelagDimensjon, setSelectedBjelkelagDimensjon] = useState("")



    useEffect(() => {
        async function fetchProductsByBreadcrumb() {
            const productRef = collection(db, 'products');
            const q = query(productRef, where('breadcrumb3', '==', 'Terrassebord'));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map(doc => doc.data());
        }

        async function fetchProducts() {
            try {
                const kledning = await fetchProductsByBreadcrumb("Terrassebord");
                setTerrassebordProducts(kledning);
            } catch (error) {
                console.error("Error fetching kledning:", error);
            }
        }
        
        fetchProducts();
    }, []);

    const handleCalculateMaterials = () => {
        let nameAndQuantityForTerrasse = [];
      
        if (selectedTerrassebord) {
          const terrassebordType = selectedTerrassebord?.productName?.toLowerCase() || null;
          const coverageData = Object.keys(TERRASSEBORD_COVERAGE).find(key => terrassebordType.includes(key));
          if (coverageData) {
            const coverage = TERRASSEBORD_COVERAGE[coverageData].coverage;
            const terrassebordNeeded = terrasseArea.terrasseArea * coverage;
      
            nameAndQuantityForTerrasse.push({
              productName: terrassebordType,
              quantity: terrassebordNeeded,
              productId: selectedTerrassebord.id
            });
          } else {
            console.error('Coverage data not found for selected terrassebord.');
          }
        } else {
          console.error('No terrassebord selected.');
        }
      
        if (selectedBjelkelagDimensjon) {
          const circumference = terrasseArea.terrasseCircumference;
          const interiorBjelkelag = terrasseArea.terrasseArea / 0.6;
          const totalBjelkelag = circumference + interiorBjelkelag;
            
          nameAndQuantityForTerrasse.push({
            productName: selectedBjelkelagDimensjon,
            quantity: totalBjelkelag,
            productId: BJELKELAG_DIMENSIONS[selectedBjelkelagDimensjon].productId
          });
        } else {
          console.log('No bjelkelag dimension selected.');
        }
      
        const screwsPerSquareMeter = {
            '21x095': 40,
            '28x120': 32,
            '28x145': 28
          };
          
          const calculateScrewsNeeded = (area, dimension) => {
              const screwsPerSquareMeterForDimension = screwsPerSquareMeter[dimension];
              if (!screwsPerSquareMeterForDimension) {
                console.error(`Screws per square meter not found for dimension: ${dimension}`);
                return;
              }
              const totalScrewsNeeded = area * screwsPerSquareMeterForDimension;
              return totalScrewsNeeded;
          };
      
          const screwsPerSquareMeterForBjelkelag = 4;
          const calculateScrewsNeededForBjelkelag = (area) => {
            const totalScrewsNeeded = area * screwsPerSquareMeterForBjelkelag;
            return totalScrewsNeeded;
          };
      
          const area = terrasseArea.terrasseArea;
          const terrassebordType = selectedTerrassebord.productName.toLowerCase();
          const dimension = Object.keys(screwsPerSquareMeter).find(key => terrassebordType.includes(key));
        
          const calculateScrewPacks = (screwsNeeded, screwPackInfo) => {
            const sortedSizes = Object.values(screwPackInfo).sort((a, b) => b.packSize - a.packSize);
            let remainingScrews = screwsNeeded;
            let screwPacks = {};
      
            for (const { packSize } of sortedSizes) {
                const packsNeeded = Math.floor(remainingScrews / packSize);
                if (packsNeeded > 0) {
                    screwPacks[packSize] = packsNeeded;
                    remainingScrews -= packsNeeded * packSize;
                }
            }
      
            const smallestPackSize = sortedSizes[sortedSizes.length - 1].packSize;
            if (!screwPacks[smallestPackSize]) {
                screwPacks[smallestPackSize] = 1;
            }
      
            return screwPacks;
          }
      
        const screwsNeededForSelectedTerrassebord = calculateScrewsNeeded(area, dimension);
        const terrasseScrewPacks = calculateScrewPacks(screwsNeededForSelectedTerrassebord, SCREW_PACKS_TERRASSEBORD);
        for (const [size, packs] of Object.entries(terrasseScrewPacks)) {
            const productInfo = Object.values(SCREW_PACKS_TERRASSEBORD).find(pack => pack.packSize === parseInt(size));
            nameAndQuantityForTerrasse.push({
                productName: dimension,
                quantity: packs,
                productId: productInfo.productId
            });
        }
    
        const screwsNeededForBjelkelag = calculateScrewsNeededForBjelkelag(area);
        const bjelkelagScrewPacks = calculateScrewPacks(screwsNeededForBjelkelag, SCREW_PACKS_BJELKELAG);
        for (const [size, packs] of Object.entries(bjelkelagScrewPacks)) {
            const productInfo = Object.values(SCREW_PACKS_BJELKELAG).find(pack => pack.packSize === parseInt(size));
            nameAndQuantityForTerrasse.push({
                productName: "6x100",
                quantity: packs,
                productId: productInfo.productId
            });
        }
      
        setTerrasseMaterials(nameAndQuantityForTerrasse);
        scrollToBottom()
    }
    
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    }
    
      
    
      const handleSelectBjelkelagDimensjon = (size) => {
        setSelectedBjelkelagDimensjon(size);
        const selectedProduct = BJELKELAG_DIMENSIONS[size];
      }

  return (
    <StyledDiv>
        <div className='container'>
            <div className='breadcrumbs my-4'>
             <StyledLink to="/prosjektkalkulator">Prosjektkalkulator</StyledLink> / <StyledLink to="/prosjektkalkulator/terrasse">Terrasse</StyledLink>
            </div>
            <h3>Terrassekalkulator</h3>
            <p>Denne kalkulatoren gir et estimat over hvilke materialer du trenger for å bygge en terrasse. Det er viktig å huske at kalkulatoren kun gir et estimat for ditt materialebehov. Kalkulatoren legger ikke til noe "kapp". Det er vanlig å estimere ca 10% går bort til kapp.</p>

<br></br>
<h5> Tegn terrassens form i boksen under </h5>

            <DeckDesigner/>  


    <section>
        <div className='bjelkelag mt-5'>
            <p className='w-100'>Hvilken dimensjon på bjelkelag?</p>
            <p>For et bjelkelag med bjelkeavstand (c/c) på 600 mm, dimensjonert med en snølast på 4,5 kN/m², er de maksimale spennviddene for forskjellige bjelkestørrelser som følger:</p>

            <ul>
                <li>For bjelker som er 48x98 mm, er den maksimale spennvidden 1,6 meter.</li>
                <li>For bjelker som er 48x148 mm, er den maksimale spennvidden 2,3 meter.</li>
                <li>For bjelker som er 48x198 mm, er den maksimale spennvidden 3,1 meter.</li>
                <li>For bjelker som er 48x223 mm, er den maksimale spennvidden 3,5 meter.</li>
            </ul>
            <p>Disse spennviddene er basert på en bjelkeavstand på 600 mm og en snølast på 4,5 kN/m². Konsultere med lokale byggeforskrifter og en profesjonell ingeniør for å sikre at ditt prosjekt er trygt og i samsvar med gjeldende standarder.</p>
                                <div>
                                    <img 
                                    src={small}
                                    alt="liggende kledning img"
                                    className={selectedBjelkelagDimensjon.includes("small") ? "selected" : ""}
                                    onClick={()=>handleSelectBjelkelagDimensjon("small")}
                                />
                                <p>48x98</p>
                                </div>

                                <div>
                                <img 
                                    src={medium}
                                    alt="stående kledning img"
                                    className={selectedBjelkelagDimensjon.includes("medium") ? "selected" : ""}
                                    onClick={()=>handleSelectBjelkelagDimensjon("medium")}
                                />
                                <p>48x148</p>

                                </div>

                                <div>
                                <img 
                                    src={large}
                                    alt="liggende kledning img"
                                    className={selectedBjelkelagDimensjon.includes("large") ? "selected" : ""}
                                    onClick={()=>handleSelectBjelkelagDimensjon("large")}
                                />
                                <p>48x198</p>
                                </div>

                                <div>
                                <img 
                                    src={huge}
                                    alt="stående kledning img"
                                    className={selectedBjelkelagDimensjon.includes("huge") ? "selected" : ""}
                                    onClick={()=>handleSelectBjelkelagDimensjon("huge")}
                                />
                                <p>48x223</p>
                                </div>
        </div>
    </section>
            <section>
<div className='product-cards-container'>
<p>Hvilken terrassebord ønsker du?</p>

<Row xs={2} md={2} lg={5} className="g-4">

{terrassebordProducts.length > 0 && terrassebordProducts.map(product => (
<StyledCard key={product.id} 
            onClick={() => setSelectedTerrassebord(product)}
            className={selectedTerrassebord && selectedTerrassebord.id === product.id ? "selected" : ""}>
<Card className='card'>
<Card.Img variant="top" src={product.imageSrc} />
<Card.Body className="body">
<Card.Title>{product.productName}</Card.Title>
<PriceUnitContainer>
<PriceText>Kr {product.byggmakkerPrice}</PriceText>
<UnitText>{product.unit}</UnitText>
</PriceUnitContainer>
<Advantages>
{product.advantages.map((adv, idx) => <li key={idx}>{adv}</li>)}
</Advantages>
</Card.Body>
</Card>
    <a href={`/${product.breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`} target="_blank" rel="noopener noreferrer">
  <button className='btn btn-success my-1'> Se produkt</button>
</a>

</StyledCard>
))}
</Row>

</div>
</section>  

    <section>
        <button onClick={handleCalculateMaterials} className='btn btn-info buttonRegneUt'     disabled={selectedTerrassebord.length === 0} >Regn ut materialer</button>
    </section>


<TerrasseProducts/>
{terrasseMaterials.length > 0 && <FineProdukterTerrasse/>}
        </div>
    </StyledDiv>
  )
}


const StyledDiv = styled.div`
.btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.bjelkelag{

    img {
        width: 150px;
        height: auto;
        cursor: pointer;
        margin-right: 30px;
        transition: transform 0.3s ease;
        mix-blend-mode: multiply;

        &:hover {
            transform: scale(1.1);
        }
    }
}

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    h5 {
        color: #2c3e50;

    }

    section{
        background-color: #ecf0f1;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0,0,0,0.1);
        margin-top:15px;
        margin-bottom:15px;
        
        @media(max-width:400px){
                padding:12px!important;
            }

    }
    .buttonRegneUt{
        width: 100%; /* Add this line */
        text-align: center; /* Add this line */
    }


.selected {
            transform: scale(1.01);
            border: 3px solid #3498db;
            border-radius:4px ;
        }
    
        .bjelkelag {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.bjelkelag div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px; /* Margin between rows */
}

.bjelkelag img {
    width: 100%; /* Use the full width of the parent div */
    max-width: 100px; /* Set a maximum width */
}

.bjelkelag p {
    margin-top: 5px; /* Space between image and text */
}

@media (max-width: 768px) {
    .bjelkelag div {
        flex-basis: calc(50% - 10px); /* Two items per row on smaller screens */
    }
}
`




const StyledCard = styled.div`

@media(max-width:400px){
    padding-left:3px;
    padding-right:3px;
}
  cursor: pointer;
  .card:hover{
    border:1px solid gray;
    scale:1.01 ;
  }
  .body{
    padding:12px;
  }


  &.selected{
    border: 3px solid #3498db;
    border-radius:4px ;
    padding:3px;
  }
`;
const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;