import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import {Link} from "react-router-dom"
import { db } from '../../firebase/firebase';
import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faComment } from '@fortawesome/free-solid-svg-icons';


export default function Prosjekter() {

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState()
  const [activeCategory, setActiveCategory] = useState(null);



  useEffect(() => {
    const fetchProjects = async () => {
        const querySnapshot = await getDocs(collection(db, "projects"));
        const projectsArray = [];
        const categoryCounts = {};

        querySnapshot.docs.forEach(doc => {
            const project = doc.data();
            projectsArray.push({ id: doc.id, ...project });

            if (project.categories) {
                project.categories.forEach(cat => {
                    if (cat in categoryCounts) {
                        categoryCounts[cat] += 1;
                    } else {
                        categoryCounts[cat] = 1;
                    }
                });
            }
        });

        setProjects(projectsArray);
        setSelectedCategories(Object.entries(categoryCounts).map(([name, count]) => ({ name, count })));
    };

    fetchProjects();
}, []);



function calculateScore(likes, dislikes, comments, shares) {
  // Constants to control the influence of each factor
  const likeWeight = 2;
  const dislikeWeight = 2;
  const commentWeight = 1;
  const shareWeight = 1.5;
  const maxScore = 10;

  // Check if there are no interactions at all
  if (likes === 0 && dislikes === 0 && comments === 0 && shares === 0) {
      // Handle this case as needed; for example, return a default score
      return 0; // or any other default value you prefer
  }

  // Calculate a raw score based on likes, dislikes, comments, and shares
  let rawScore = (likes * likeWeight - dislikes * dislikeWeight) + 
                 (comments * commentWeight + shares * shareWeight);

  // Normalize the raw score using a logarithmic function
  let normalizedScore = Math.log10(rawScore + 1) * maxScore / Math.log10(maxScore);

  // Ensure the score is within 0-10 range
  normalizedScore = Math.min(Math.max(normalizedScore, 0), maxScore);

  return normalizedScore.toFixed(1); // Rounded to one decimal place
}


const calculateSvgProperties = (score, maxScore = 10) => {
  const radius = 20; // Radius of the circle
  const circumference = 2 * Math.PI * radius;
  const normalizedScore = Math.min(score, maxScore); // Ensure score doesn't exceed maxScore
  const filledPortion = (normalizedScore / maxScore) * circumference;
  const emptyPortion = circumference - filledPortion;

  return { circumference, emptyPortion };
};


function timeAgo(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  // Less than a minute
  if (diffInSeconds < 60) {
      return `${diffInSeconds} sekunder siden`;
  }
  // Less than an hour
  else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutter siden`;
  }
  // Less than a day
  else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} timer siden`;
  }
  // Less than 30 days
  else if (diffInSeconds < 2592000) {
      return `${Math.floor(diffInSeconds / 86400)} dager siden`;
  }
  // More than 30 days
  else {
      return date.toLocaleDateString(); // Format as a standard date
  }
}


function parseDate(dateInput) {
  let dateStr;

  // Check if dateInput is a Firebase Timestamp
  if (dateInput && typeof dateInput.toDate === 'function') {
      dateStr = dateInput.toDate().toString(); // Convert to JavaScript Date, then to string
  } 
  // Check if dateInput is already a Date object
  else if (dateInput instanceof Date) {
      dateStr = dateInput.toString(); // Convert to string
  } 
  // Assume dateInput is a string
  else if (typeof dateInput === 'string') {
      dateStr = dateInput;
  } 
  else {
      return null; // Handle invalid input
  }

     // Parse the date string
     const parts = dateStr.split(' ');
     const datePart = parts.slice(1, 4).join(' '); // Extract the date part (excluding day of the week)
     const timePart = parts[4]; // Extract the time part
     const yearPart = parts[3]; // Extract the year part
     const amPm = parts[5]; // Extract AM/PM part
 
     // Reformat to a standard date string
     const reformattedDateStr = `${datePart} ${yearPart} ${timePart} ${amPm}`;
     const parsedDate = new Date(reformattedDateStr);
 
     return parsedDate;
}







return (
  <StyledDiv className='container my-5'>

      <div className='row'>
          <h3>Prosjekter</h3>
          <div className="content-container">
          <p>Del ditt oppussingsprosjekt og inspirer andre med dine ideer og fremskritt.
          Har du oppdaget smarte løsninger eller kreative ideer? Vis dem frem! </p>

              <Link to="/nyttprosjekt">
                  <button className='btn btn-outline-success'>Legg til prosjekt</button>
              </Link>
          </div>
          <div className='col-md-8 my-3 col-sm-12 order-2 order-md-1'>
          {projects.filter(project => 
        !activeCategory || project.categories.includes(activeCategory)
    ).map(project => {
                  const projectScore = calculateScore(project.likes, project.dislikes, project.commentCount, project.shareCount);

                  return ( 
                    <StyledLink to={`/prosjekt/${project.projectId}`}>
                      <div key={project.id} className="project">
                          
                          <div className="project-score">
                          <div className="score-circle">
    <span>{projectScore}</span>
    <svg width="50" height="50" className="score-bar" style={{ transform: "rotate(-180deg)", transformOrigin: "center" }}>
    <circle
        stroke="#13849b"
        strokeWidth="8"
        fill="transparent"
        r="20"
        cx="25"
        cy="25"
        style={{
            strokeDasharray: `${calculateSvgProperties(projectScore).circumference}`,
            strokeDashoffset: `${calculateSvgProperties(projectScore).emptyPortion}`
        }}
    />
</svg>

</div>
                          </div>
                          <div className="project-reactions">
                              <span><FontAwesomeIcon icon={faThumbsUp} /> {project.likes}</span>
                              <span><FontAwesomeIcon icon={faThumbsDown} /> {project.dislikes}</span>
                              <span><FontAwesomeIcon icon={faComment}/> {project.commentCount}</span>
                          </div>
                          <div className="project-image">
                              {/* Image goes here */}
                          </div>
                          <div className="project-info">
                              <h4 className='title'>{project.title}</h4>
                              <span><small> {project.createdBy} - {timeAgo(parseDate(project.createdAt))}</small></span>

                              
                              <div className="project-tags">
                                  {project.categories.map((category,index)=>(
                                    <div key={index} className="selected-categoryPost">
                                    {category} 
                                      </div>
          
                                  ))}
                              </div>


                          </div>
                      </div>
                      </StyledLink>
                  );
              })}
          </div>
          <div className='col-md-2 col-sm-12 order-md-2 order-sm-1'>
              <h4>Kategorier</h4>
              <div className="selected-category" onClick={() => setActiveCategory(null)}>
    Vis alle
      </div>
              <div className="selected-categories">
    {selectedCategories && selectedCategories.map((category, index) => (
        <div key={index} className="selected-category" onClick={() => setActiveCategory(category.name)}>
            {category.name} ({category.count})
        </div>
    ))}
</div>

          </div>
      </div>
  </StyledDiv>
);


}

const StyledLink = styled(Link)`
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent

  &:hover{

}
`;


const StyledDiv = styled.div`


.title{
  margin:0;
  padding-bottom:4px ;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .score-circle {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1e252a;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.score-circle span {
    position: absolute;
    z-index: 1;
}

.score-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.score-bar circle {
    stroke: #13849b;
    stroke-width: 8;
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}



.logoDefault{
  height: 60px;
}
    .selected-categories {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .selected-category {
      cursor: pointer;
        display: inline-flex;
        align-items: center;
        background-color: #233148;
        color: white;
        font-weight:500 ;
        border-radius: 15px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .selected-categoryPost {
        display: inline-flex;
        align-items: center;
        background-color: #233148;
        color: white;
        font-size:14px;
        font-weight:600 ;
        border-radius: 15px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .project {
        display: flex;
        border: 1px solid #ddd;
        margin-bottom: 15px;
        padding: 15px;
    }

    .project-score {
        width: 50px; // Adjust as needed
        display: flex;
        justify-content:center;
        align-items:center ;
    }

    .project-reactions {
      flex-direction:column ;
        width: 50px; // Adjust as needed
        display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Optional: Adds space between the like and dislike */
    }

    .project-image {
        // Style for the image
    }

    .project-info {
        flex-grow: 1;
        padding-left: 15px;
        max-width:80% ;
       

        .project-tags {
            // Style for tags
        }
    }
    // Additional styling
`;

