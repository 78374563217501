import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { AuthContext } from '../../context/AuthContext';
import NavProfile from '../../components/NavProfile';
import { Link, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

export default function RedigerProfil() {
    const { currentUser } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
      if(currentUser) {
          getUserDetails();
      }
  }, [currentUser]);



  const getUserDetails = async () => {
      const firestore = getFirestore();
      setIsLoading(true);
      try {
          const docRef = doc(firestore, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              setUserDetails(docSnap.data());
          } else {
              console.log("No such document!");
          }
      } catch (error) {
          console.log("Error getting document:", error);
      }
      setIsLoading(false);
  };

  const handleSave = async () => {
      const firestore = getFirestore();
      const docRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(docRef, userDetails);
      navigate("/profile")
  }

  const handleCancel = () => {
    navigate("/profile")
  }

  const handleChange = (field, value) => {
      setUserDetails({...userDetails, [field]: value});
  }
    return (
        <Wrapper>
            <NavProfile/>
            <div className='container my-5'>
            <h3>Rediger profil</h3>
                <ProfileContainer>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        userDetails ?
                            <ProfileInfo>
                                <InputRow>
                                    <label>Email: </label>
                                    <input type="text" value={userDetails.email} onChange={(e) => handleChange('email', e.target.value)} />
                                </InputRow>
                                <InputRow>
                                    <label>Fornavn: </label>
                                    <input type="text" value={userDetails.firstName || ''} onChange={(e) => handleChange('firstName', e.target.value)} />
                                </InputRow>
                                <InputRow>
                                    <label>Etternavn: </label>
                                    <input type="text" value={userDetails.lastName || ''} onChange={(e) => handleChange('lastName', e.target.value)} />
                                </InputRow>
                                <InputRow>
                                    <label>Adresse: </label>
                                    <input type="text" value={userDetails.address || ''} onChange={(e) => handleChange('address', e.target.value)} />
                                </InputRow>
                                <ButtonContainer>
                                    <Button variant="info" className="mt-3" onClick={handleSave}>Lagre</Button>
                                    <Button variant="primary" className="mt-3" onClick={handleCancel}>Avbryt</Button>
                                </ButtonContainer>
                            </ProfileInfo>
                            :
                            <p>Du er ikke logget inn, <Link to="/signup">Logg inn</Link></p>
                    )}
                    <Button variant="secondary" className="mt-3" onClick={() => navigate("/profile")}>Tilbake</Button>
                </ProfileContainer>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

input{
    border-radius:5px;
    border:1px solid #2c3e50;
    @media(min-width: 600px){
        min-width:400px
    }
}
`;

const ProfileContainer = styled.div`
    width: 80%;
    max-width: 600px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    text-align: center;
`;

const ProfileInfo = styled.div`
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

`;
