import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';  // Adjust path according to your project structure
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #888;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  min-width:300px ;

  transition: border-color 0.2s;
  &:focus {
    border-color: #00aaff;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #00aaff;
  color: #ffffff;
  transition: background-color 0.2s;
  margin-bottom:15px ;
  &:hover {
    background-color: #0088cc;
  }
`;

const Break = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 10px 0 20px;
  text-align: center;

  &:before,
  &:after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: gray;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const EllerText = styled.span`
  background-color: #fff;
  padding: 0 10px;
`;


const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content:center ;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4285f4;
  color: #ffffff;
  min-width:300px ;
  transition: background-color 0.2s;
  &:hover {
    background-color: #2a75d2;
  }
  img {
    margin-right: 8px;
    width: 20px;
  }
`;

// This style will be for the error message container
const ErrorContainer = styled.div`
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f2dede; /* light red background */
  color: #a94442; /* dark red text */
  border: 1px solid #a94442;
  text-align: center;
`;

// This style will be for the message container
const MessageContainer = styled.div`
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #dff0d8; /* light green background */
  color: #3c763d; /* dark green text */
  border: 1px solid #3c763d;
  text-align: center;
`;



const Signin = () => {
  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
const [successMessage, setSuccessMessage] = useState('');


  const { passwordlessSignIn, googleSignIn } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setErrorMessage('');
    setSuccessMessage('');
  
    console.log('Sending request for passwordless sign-in...');  // Log for debugging
  
    try {
      await passwordlessSignIn(email);
      console.log('Sign-in was successful, setting success message.');  // Log for debugging
      setSuccessMessage(`En innloggingslenke har blitt sendt til ${email}. Sjekk e-posten din (e-posten kan havne i søppelpost-mappen). E-posten vil komme fra følgende avsender: *noreply@mop-simple.firebaseapp.com*`);
    } catch (error) {
      console.error('An error occurred during sign-in:', error);  // Log for debugging
      setErrorMessage('En feil har oppstått. Prøv igjen.');
    }
  };
  
  

  return (
    <Container>
      <Title>Logg inn</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit">Logg inn med Email</Button>
      </Form>
      <Break>
  <EllerText>eller</EllerText>
</Break>
      
      <GoogleButton onClick={googleSignIn}>
        <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" />
        Logg inn med Google
      </GoogleButton>


      {successMessage && (
      <MessageContainer>
        {successMessage}
      </MessageContainer>
    )}

    {errorMessage && (
      <ErrorContainer>
        {errorMessage}
      </ErrorContainer>
    )}
    </Container>
  );
};

export default Signin;
