import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { Link } from 'react-router-dom';
import styled from "styled-components"

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedBreadcrumb1, setSelectedBreadcrumb1] = useState(null);
  const [selectedBreadcrumb2, setSelectedBreadcrumb2] = useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);

  };

  const handleBreadcrumb1Click = (breadcrumb1) => {
    if (selectedBreadcrumb1 === breadcrumb1) {
      setSelectedBreadcrumb1(null);   // Close it if it's already opened
      setSelectedBreadcrumb2(null);   // Also reset the second breadcrumb
    } else {
      setSelectedBreadcrumb1(breadcrumb1);
      setSelectedBreadcrumb2(null);   // Ensure that breadcrumb2 is reset when you click a new breadcrumb1
    }
  };
  

  const handleBreadcrumb2Click = (breadcrumb2) => {
    if (selectedBreadcrumb2 === breadcrumb2) {
      setSelectedBreadcrumb2(null);   // Close it if it's already opened
    } else {
      setSelectedBreadcrumb2(breadcrumb2);
    }
  };
  

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      const categoryList = categorySnapshot.docs.map(doc => doc.data());

      const nestedCategories = {};
      for (let category of categoryList) {
        if (!nestedCategories[category.breadcrumb1]) {
          nestedCategories[category.breadcrumb1] = {};
        }
        if (!nestedCategories[category.breadcrumb1][category.breadcrumb2]) {
          nestedCategories[category.breadcrumb1][category.breadcrumb2] = [];
        }
        nestedCategories[category.breadcrumb1][category.breadcrumb2].push(category.breadcrumb3);
      }

      setCategories(nestedCategories);
    };

    fetchCategories();
  }, []);

  return (
    <SidebarWrapper className={isOpen ? "open" : "closed"}>
      <div className='items px-2' onClick={handleToggle}> 
        <Hamburger isOpen={isOpen}>
          <span />
          <span />
          <span />
        </Hamburger>
        <span>Produkter</span>
      </div>
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        {isOpen && Object.keys(categories).map((breadcrumb1, index) => (
          <div key={index} className="breadcrumb1">
            <p onClick={() => handleBreadcrumb1Click(breadcrumb1)}>{breadcrumb1}</p>
            {selectedBreadcrumb1 === breadcrumb1 && Object.keys(categories[breadcrumb1]).map((breadcrumb2, index) => (
              <div key={index} className="breadcrumb2">
                <p onClick={() => handleBreadcrumb2Click(breadcrumb2)}>{breadcrumb2}</p>
                {selectedBreadcrumb2 === breadcrumb2 && categories[breadcrumb1][breadcrumb2].map((breadcrumb3, index) => (
                  <div key={index} className="breadcrumb3">
                    <Link onClick={handleToggle} to={`/${breadcrumb1}/${breadcrumb2}/${breadcrumb3}`}>{breadcrumb3}</Link>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;

const SidebarWrapper = styled.div`
  .sidebar {
    width: 250px;
    background: #ffff;
    position: absolute;
    top: 94px;
    left: -250px;
    min-height:100vh;
    transition: left 0.3s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
    z-index:10;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    font-size:12px;
    
    
    p, a {
      cursor: pointer;
    }

    p:hover, a:hover {
      color: tomato;

    }
  }
 

  .sidebar.open {
    left: 0;
  }

  .breadcrumb1 {
    margin: 10px 0;
  }

  .breadcrumb2 {
    margin: 10px 0;
    padding-left: 10px;
  }

  .breadcrumb3 {
    margin: 10px 0;
    padding-left: 20px;
  }

  .items{
    padding-top:7px;
    font-size:12px;
    height:34px;
    width:149px;
    margin-top:0px;
    display: flex;
    align-items: center;
    padding-bottom:0 ;
  }

  .items:hover{
    background-color:lightgray;
    cursor:pointer;
  }
  span{
    margin-bottom:5px ;
  }
`;

const Hamburger = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  margin-right: 10px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-top:2px ;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: ${props => props.isOpen ? '7px' : '0px'};
    transform: ${props => props.isOpen ? 'rotate(135deg)' : 'rotate(0deg)'};
  }

  span:nth-child(2) {
    top: 7px;
    opacity: ${props => props.isOpen ? 0 : 1};
    left: ${props => props.isOpen ? '-60px' : '0px'};
  }

  span:nth-child(3) {
    top: ${props => props.isOpen ? '7px' : '14px'};
    transform: ${props => props.isOpen ? 'rotate(-135deg)' : 'rotate(0deg)'};
  }
`;
