import React,{useContext} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import slett from "../../images/delete.png";
import { AuthContext } from '../../context/AuthContext';


function YetterveggProductsList() {
    const { cart, setCart, cartYttervegg, setCartYttervegg } = useContext(AuthContext);




    const handleArticleNumber = (product) => {
        const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
        return articleNumberObject ? articleNumberObject.value : "";
    };

    const handleIncreaseQuantity = (productId) => {
        const updatedCart = cartYttervegg.map(product => 
            product.product.id === productId
                ? { ...product, quantity: product.quantity + 1 } 
                : product
        );               
        setCartYttervegg(updatedCart);
    };
    
    const handleDecreaseQuantity = (productId) => {
        const updatedCart = cartYttervegg.map(product => 
            product.product.id === productId 
                ? { ...product, quantity: Math.max(1, product.quantity - 1) } 
                : product
        );
        setCartYttervegg(updatedCart);
    };
    

    const handleUpdateQuantity = (productId, quantity) => {
        const updatedCart = cartYttervegg.map(product => 
            product.product.id === productId 
                ? { ...product, quantity: Math.max(1, parseInt(quantity)) } 
                : product
        );
        setCartYttervegg(updatedCart);
    };
    

    const handleRemoveProduct = (productId) => {
        const updatedCart = cartYttervegg.filter(product => product.product.id !== productId);
        setCartYttervegg(updatedCart);
    };
    

    const getTotalSum = () => {
      if(cartYttervegg.length > 0){
          return cartYttervegg.reduce((total, item) => {
              const product = item.product;
              const price = product && product.byggmakkerPrice 
                            ? parseFloat((product.byggmakkerPrice || '0').replace(',', '.')) 
                            : 0;
              const quantity = Math.round(parseFloat(item.quantity || 0)); // parse quantity to float, round it to the nearest integer
              return total + (price * quantity);
          }, 0);
      }
      return 0;
  };
      
      const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"  // Optional: smooth scrolling
        });
    }

      const transferToGlobalCart = () => {
        // Transform the cartYttervegg array
        const transformedCartYttervegg = cartYttervegg.map(item => {
          const {product, quantity} = item;
          return {...product, quantity}; // Spread the product object and add the quantity field
        });
      
        // Merge the current global cart with the transformed cartYttervegg
        const mergedCart = [...cart, ...transformedCartYttervegg];
      
        const updatedCart = mergedCart.reduce((acc, item) => {
          const existingItem = acc.find(
            cartItem =>
              cartItem.id === item.id
          );
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            acc.push(item);
          }
          return acc;
        }, []);
        
        setCart(updatedCart);
        setCartYttervegg([]); // Clear the local cartYttervegg
        scrollToTop();
      };
      
      

      const formatter = new Intl.NumberFormat('no-NO', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });

      const sumFixer = (price, quantity) => {
        const correctedPrice = (price || '0').replace(',', '.'); // replace comma with period
        const parsedPrice = parseFloat(correctedPrice); // parse corrected price to float
        const parsedQuantity = Math.round(parseFloat(quantity || 0)); // parse quantity to float, round it to the nearest integer
        const sum = parsedPrice * parsedQuantity; // calculate the sum
        
        return sum;
    }

  return (
    <CartContainer>
    <>
    {cartYttervegg && cartYttervegg.length > 0 && ( <Table>
      <thead>
        <tr>
          <th></th>
          <th>Produkt</th>
          <th>Pris</th>
          <th>Antall</th>
          <th>Artikkelnummer</th>
          <th>Produkt total</th>
        </tr>
      </thead>
      <tbody>
        {cartYttervegg && cartYttervegg.map((item, index) => (
  <tr key={index}>
    <td>
      {item.product && item.product.imageSrc && 
        <img src={item.product.imageSrc} alt={item.product.productName || 'Product Image'} style={{ height: '50px' }} />
      }
    </td>
    <td>
      {item.product && 
        <ProductNameLink to={`/${item.product.breadcrumb1}/${item.product.breadcrumb2}/${item.product.breadcrumb3}/${item.product.productName}`}>
          <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>
            {item.product.productName}
          </h2>
        </ProductNameLink>
      }
    </td>
    <td className='price'>{item.product && `kr. ${item.product.byggmakkerPrice} ${item.product.unit || ''}`}</td>
    <td>
      <QuantityContainer>
      <Button onClick={() => item.product && handleDecreaseQuantity(item.product.id)}>-</Button>
        <QuantityInput type="number" value={item.quantity.toFixed(0) || 0} onChange={e => item.product && handleUpdateQuantity(item.product.id, e.target.value)} />
        <Button onClick={() => item.product && handleIncreaseQuantity(item.product.id)}>+</Button>
      </QuantityContainer>
    </td>
    <td>{item.product && handleArticleNumber(item.product)}</td>
    <td>
  {item.product && typeof item.product.byggmakkerPrice !== 'undefined' && typeof item.quantity === 'number'
    ? `kr. ${formatter.format(sumFixer(item.product.byggmakkerPrice, item.quantity))}`
    : 'N/A'}
</td>



    <td>
      {item.product && 
        <DeleteImg className="deleteIcon" src={slett} onClick={() => handleRemoveProduct(item.product.id)} />
      }
    </td>
  </tr>
))}

      </tbody>
    </Table>
    )}

    {cartYttervegg && cartYttervegg.length > 0 && (
  <Footer>
    <div className="totalPrice">
    <h2>Total: kr. {formatter.format(getTotalSum())}</h2>
    </div>
    <div className="buttons">
      <Link to="/"><button className='btn btn-secondary'>Fortsett å handle</button></Link>
      <button className='btn btn-success' onClick={transferToGlobalCart}>Legg alle produkter i handlekurv</button>
    </div>
  </Footer>
)}
    </>
    </CartContainer>
  );
}

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden; // Prevent x-axis overflow
  padding: 10px;
  @media(max-width:430px){
    padding:0;
  }

  @media (max-width: 390px) {
    .price{
      font-size: 10px !important;
    }
    }
`;



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    th:nth-child(5), td:nth-child(5) { // Hides Artikkelnummer
      display: none;
    }

    th:nth-child(1), td:nth-child(1) { // Reduce image size in the first column
      width: 40px;
      img {
        height: 40px;
      }
    }

    th, td {
      padding: 4px; // Reducing padding
      font-size: 12px; // Reducing font size
    }
  }
`;

const Button = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display:none;

  }


`;

const QuantityInput = styled.input`
  width: 50px;
  height: 30px;
  text-align: center;
  margin: 0 5px;

  @media (max-width: 999px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
    padding:0 !important;
      margin:0 !important;
    *:focus, input:focus {
    outline: none;
  }
  }

  @media (max-width: 768px) {
    width: 40px;
    min-width:45px;
    height: 25px;
    font-size: 12px;
    padding:0 !important;
    margin:0 !important;
    *:focus, input:focus {
    outline: none;
  }
  }
  @media(max-width:430px){
    padding:0 !important;
    margin:0 !important;
    *:focus, input:focus {
    outline: none;
  }
    
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch; // Ensure items take full width

  .totalPrice {
    text-align: right; // Aligns the price to the right
    margin-bottom: 10px; // Space between total and buttons
  }

  .buttons {
    display: flex;
    justify-content: space-between; // Positions buttons on either side
    
    // Ensure buttons are vertically aligned even if they wrap
    align-items: center; 

    button {
      margin: 5px ; // Remove any default margins
    }
  }

  @media (max-width: 768px) {
    .totalPrice h2 {
      font-size: 14px; // Reduce font size of total price
    }

    .buttons button {
      font-size: 12px; // Reduce font size of buttons
      padding: 5px 10px; // Reduce button padding
    }
  }
  
`;


const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  h2 {
    @media (max-width: 768px) {
      font-size: 12px !important;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
    @media (max-width: 390px) {
      font-size: 10px !important;
      WebkitLineClamp: 1; // Truncate to 1 line
    }
  }
`;

const DeleteImg = styled.img`
  height: 25px;
  margin-top:15px;
  cursor: pointer;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;

`;

export default YetterveggProductsList;
