import React from "react";
import styled from "styled-components";
import list from "../../images/frontImages/list.png"
import percent from "../../images/frontImages/percent.png";
import delivery from "../../images/frontImages/delivery.png";


export default function Services() {


   const services= [
      {
        id: 1,
        icon: <img src={list} alt="" className="img" />,
        title: "Bygg handleliste online",
        text: "Bygg handlelisten ved hjelp av prosjektkalkulator, eller plukk varer selv"
      },
      {
        id: 2,
        icon: <img src={percent} alt="" className="img" />,
        title: "Få rabatterte tilbud",
        text: "Du får de beste tilbudene fra dine lokale byggevarehus"
      },
      {
        id: 3,
        icon: <img src={delivery} alt="" className="img" />,
        title: "Få varene sendt eller hent varene selv",
        text:
          "Velg det beste tilbudet, og få varene sendt rett hjem til deg eller hent varene selv"
      }
    ]



    return (
      <ServicesWrapper className="mb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>
          </div>
          <div className="row">
            {services.map(item => {
              return (
                <div
                  className="col-10 mx-auto col-ms-6 col-md-4 text-center "
                  key={item.id}
                >
                  <div className="wrapper">
                    <div className="number">{item.id}</div>
                    <div className="service-icon text-bold">
                      <div className="iconWrap">{item.icon}</div>
                    </div>
                    <div className="mt-3">
                      <h5>{item.title}</h5>
                    </div>
                    <div className="m-3">
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>


        </div>
      </ServicesWrapper>
    );
}

const ServicesWrapper = styled.section`
  .text-center {
    @media (max-width: 769px) {
      margin-bottom: 10px;
    }
  }
  .container {
    padding: 0 !important;
  
    @media (max-width: 769px) {
    padding: 15px !important;
  }}
  .img {
    height: 50px;
    @media (max-width: 500px) {
      height: 40px;
    }
  }
  .service-icon {
    color: #2c3e50;
  }
  .iconWrap {
    padding-top: 10px;
  }
  h5 {
    font-size: 15px;
    font-weight: bold;
    color: #2c3e50;
  }

  p {
    font-size: 15px !important;
  }

  .wrapper {
    position: relative;  // Add this line
    background: #f0f0f0;
    height: 200px;
    border-radius:10px;

    @media (max-height: 768px) {
      height: 200px;
    }
    @media (max-height: 994px) {
      height: 220px;
    }
  }

  .number {
    color: white;
    background-color: #4267af;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    font-weight: bold;
    z-index: 10; // This ensures the number stays on top, if there are other overlapping elements
  }
`;