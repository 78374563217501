
import React, { useState, useEffect, useRef  } from 'react';
import styled from "styled-components";
import { Container, Row, Col, Card, Carousel  } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { collection, getDoc, doc} from "firebase/firestore";
import { db } from '../../firebase/firebase';
import CasdSkeleton from '../../components/CasdSkeleton';

export default function FineProdukterLettvegg() {
    const [products, setProducts] = useState([])
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [loading, setLoading] = useState(true)
    const scrollRef = useRef(null);

    
    const PRODUCTS = ["jLt688nCUXHUMsIzo3w2","lMTXw4QP7xcIGg5YgZSb","MQqYVq5hmDLPdZU2kaOj","9S8yan9bYWxAOfkjEXs8","NJG5fi1hESO3j2pIliRx","wgPkBJ7dk1EhD5fHxRvx","WvRxPzVIUasl31OUzf5q","0NW8LFLUEL9fFajZtVlN","MD9Qaayjhj70U3RSU9rA"]

    useEffect(() => {
        const fetchProductsById = async () => {
            const productsCollection = collection(db, 'products');  // Assuming 'products' is the collection name
    
            // Fetch each product by its ID
            const productFetchPromises = PRODUCTS.map(productId => 
                getDoc(doc(productsCollection, productId))
            );
    
            const productDocs = await Promise.all(productFetchPromises);
            // Convert doc snapshots to data
            const fetchedProducts = productDocs.map(docSnapshot => 
                docSnapshot.exists ? docSnapshot.data() : null
            ).filter(Boolean);  // This filter removes null values (non-existing docs)
    
            setProducts(fetchedProducts);
            setLoading(false);
        };
    
        fetchProductsById();
    }, []);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
      };
      
      const handleMouseLeave = () => {
        setIsDragging(false);
      };
      
      const handleMouseUp = () => {
        setIsDragging(false);
      };
      
      const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX);
        scrollRef.current.scrollLeft = scrollLeft - walk;
      };
      

    return (
        <StyledDiv>
          <div className='container my-5'>
            <div className='wrapper'>
              <h3 className='p-2'>Nyttige verktøy og produkter når du skal sette opp en lettvegg.</h3>
              <div className="custom-carousel-container"
                ref={scrollRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}>
                {loading && <> <CasdSkeleton/><CasdSkeleton/><CasdSkeleton/><CasdSkeleton/><CasdSkeleton/></> }
                {products.map((product, idx) => (
                  <div className="custom-carousel-item" key={idx}>
                    <StyledCard>
                      <a href={`/${product.breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`} target="_blank" rel="noopener noreferrer" > 
                        <Card className='card'>
                          <Card.Img variant="top" src={product.imageSrc} />
                          <Card.Body>
                            <Card.Title>{product.productName}</Card.Title>
                            <PriceUnitContainer>
                              <PriceText>Kr {product.byggmakkerPrice}</PriceText>
                              <UnitText>{product.unit}</UnitText>
                            </PriceUnitContainer>
                            <Advantages>
                              {product.advantages.map((adv, idx) => <li key={idx}>{adv}</li>)}
                            </Advantages>
                          </Card.Body>
                        </Card>
                        </a>
                    </StyledCard>
                  </div>
            
                ))}
              </div>
            </div>
          </div>
        </StyledDiv>
      )
    
}

const StyledDiv = styled.div`
a{
  text-decoration: none; // Removes the underline
  color: inherit; // Inherits the color from its parent
}
.card{
    height:365px ;
    overflow-y:hidden ;
}

.custom-carousel-container {
    display: flex;
    width: 100%;
    margin: 5px;
    padding: 15px;
    -ms-overflow-style: none;  /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
  overflow-x: scroll;  /* allow horizontal scroll */
  overflow-y: hidden;  /* hide vertical scroll */
}

.custom-carousel-container::-webkit-scrollbar {
  display: none;  /* for Chrome, Safari, and Opera */
}
  .custom-carousel-item {
    flex: 0 0 auto;
    width: 250px;
    margin-right: 10px;
  }

h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    margin-left:10px;
  }
.container{
    padding:0;
}
  p{
    margin-left:13px;
  }

.wrapper{
   background-color: #ecf0f1;
   border-radius:10px ;
   overflow-x:hidden ;
   overflow-y:hidden ;
}
`;

const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;


const StyledCard = styled.div`
  cursor: pointer;
  max-width: 250px; // set the maximum width of each card
  margin: auto; // centers the card within its container

  .card {
    &:hover {
      border: 1px solid gray;
      scale: 1.01;
    }
    .body {
      padding: 12px;
    }
    img {
      object-fit: cover;  // or use 'contain' based on your need
      height: 200px;  // set to the height you desire
      width: 100%; // auto-adjusts to keep aspect ratio
    }
  }
`;
