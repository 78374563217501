import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import banner from "../../images/frontImages/backgroundFront.jpg";

export default function Banner() {
    const [showVideo, setShowVideo] = useState(false);

    return (
        <StyledDiv>
            <div className='container-fluid'>
                <div className='banner'>
                    <div className="overlay"></div>
                    <img src={banner} alt="banner bilde" />
                    <div className='textFloat'>
                        BYGGEVARER PÅ NETT <br />
                        TIL RABATERTE PRISER
                    </div>
                    <div className='buttonsBanner'>
                        <div className='googlePlay' onClick={() => setShowVideo(true)}></div>
                        <Link to="/hvordan"><div className='button'>Les mer</div></Link>
                    </div>
                </div>
             
            </div>
            {showVideo && (
                <VideoModal onClick={() => setShowVideo(false)}>
                    <iframe 
                        width="100%" 
                        height="100%" 
                        src="https://www.youtube.com/embed/L9F7ESw_HmQ" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                </VideoModal>
            )}
           
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
a {
    text-decoration: none;
}

.container-fluid {
    padding: 0;
    height: 60vh;
    @media (max-width: 768px) {
            height: 40vh;
    }
}

.banner {
    position: relative;
    height: 300px;

  

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 60vh;
        background-color: rgba(128, 128, 128, 0.2);
        z-index: 0; 
        @media (max-width: 768px) {
            height: 40vh;
    }
    }

    img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        z-index: 1; 
        @media (max-width: 768px) {
            height: 40vh;
    }
    }

    .textFloat {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-20%);
        color: white;
        background-color: rgba(66, 103, 175, 1);
        padding: 10px 20px;
        border-radius: 5px;
        font-family: 'Lorem', sans-serif;
        font-size: 30px;
        letter-spacing: 2px;
        text-transform: uppercase;
        z-index: 3; 
        font-style:italic ;
        white-space: nowrap;
        font-weight:600 ;
        filter: drop-shadow(3px 2px 2px black);

        @media(max-width:775px){
            font-size: 25px;
            }
            @media(max-width:675px){
            font-size: 15px;
            }
            @media(max-width:768px) {
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%); /* Adjust the translation to center it */
        font-size: 20px;
    }
    }

    .buttonsBanner {
        position: absolute;
        bottom: 10%;
        left: 10%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        z-index: 2; 

        .googlePlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 50px;
    border-radius: .25rem;
    background-color: rgba(128, 128, 128, 0.6);
    cursor: pointer;
    position: relative; /* Added to position the before pseudo-element */

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-top: 12px solid transparent; /* Half of height to create the top of the triangle */
        border-bottom: 12px solid transparent; /* Half of height to create the bottom of the triangle */
        border-left: 20px solid white; /* Width of the triangle */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Center the triangle */
    }

    &:hover {
        background-color: rgba(205, 32, 31, 1);
    }
}


        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 50px;
            border-radius: .25rem;
            color: white;
            border: 2px solid white;
            filter: drop-shadow(1px 1px 1px gray);
            font-size:20px ;


            &:hover {
                background-color: white;
                color: black;
                font-weight:600 ;
            }
        }

        @media (max-width: 768px) {
        bottom: 15%; /* Adjust as needed based on your preference */
        left: 50%;
        transform: translateX(-50%); /* Center it horizontally */
    }
    }

    /* Adjust the positioning of text and buttons on smaller screens */
    @media (max-width: 768px) {
        .textFloat {
            top: 30%;
        }

        .buttonsBanner {
            bottom: 5%;
        }
    }
}



.prosjektkalkulatorBanner {
    /* Additional styles can be added here later */
}
`;

const VideoModal = styled.div`
 
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* This creates the backdrop effect */
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;

        iframe {
            width: 80vw;
            height: 45vw; /* Keep the 16:9 aspect ratio for videos */
            max-width: 960px; /* Setting a max-width to ensure the video doesn't get too large on bigger screens */
            max-height: 540px; /* 16:9 aspect ratio */
            border-radius:10px ;
        }
    
`