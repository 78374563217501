import React, { useState, useEffect, useRef, useContext } from 'react';
import { Stage, Layer, Line, Circle, Text } from 'react-konva';
import styled from "styled-components";
import { AuthContext } from '../../context/AuthContext';

const DeckDesigner = () => {
  const [vertices, setVertices] = useState([]);
  const [selectedVertex, setSelectedVertex] = useState(null);
  const [area, setArea] = useState(0); // Initialize area state with 0
  const [scale, setScale] = useState(1);
  const [pos, setPos] = useState({ x: 0, y: 0 });

  const [stageWidth, setStageWidth] = useState(0);
  const [stageHeight, setStageHeight] = useState(0);
  const canvasRef = useRef();
  const stageRef = useRef();


  const {setTerrasseArea}= useContext(AuthContext)

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = canvasRef.current.getBoundingClientRect();
      setStageWidth(width);
      setStageHeight(height);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (vertices.length >= 3) {
      handleCalculateArea();
    }
  }, [vertices]);


  const zoomOut = () =>{
    setScale(scale / 1.1)
  }

  const zoomIn = () =>{
    setScale(scale * 1.1)
  }

  const resetZoom = () =>{
    setScale(1)
  }
  

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = canvasRef.current.getBoundingClientRect();
      setStageWidth(width);
      setStageHeight(height);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClick = (e) => {
    const newVertices = [...vertices];
    let newX = e.evt.offsetX;
    let newY = e.evt.offsetY;
    const snapTolerance = 30; // pixels
    const selectTolerance = 30; // pixels
  
    // Check if the clicked position is close to an existing vertex
    const closeVertex = vertices.findIndex(vertex => {
      return getDistance(vertex[0], vertex[1], newX, newY) < selectTolerance;
    });
  
    // Select existing vertex if close, otherwise add a new one
    if (closeVertex !== -1) {
      setSelectedVertex(closeVertex);
    } else {
      if (newVertices.length > 0) {
        const lastVertex = newVertices[newVertices.length - 1];
        const dx = newX - lastVertex[0];
        const dy = newY - lastVertex[1];
    
        // Snap to horizontal line
        if (Math.abs(dy) < snapTolerance) {
          newY = lastVertex[1];
        }
    
        // Snap to vertical line
        if (Math.abs(dx) < snapTolerance) {
          newX = lastVertex[0];
        }
    
        if (selectedVertex === 0) {
          setSelectedVertex(null);
        } else {
          newVertices.push([newX, newY]);
          setVertices(newVertices);
          setSelectedVertex(newVertices.length - 1);
        }
      } else {
        newVertices.push([newX, newY]);
        setVertices(newVertices);
        setSelectedVertex(newVertices.length - 1);
      }
    }
  };
  
  
  

  const handleDrag = (e, index) => {
    const newVertices = [...vertices];
    const newX = e.target.x();
    const newY = e.target.y();
    const prevVertex = newVertices[(index - 1 + vertices.length) % vertices.length];
    const nextVertex = newVertices[(index + 1) % vertices.length];
    const snapTolerance = 45; // pixels
  
    // Snap to horizontal line
    const dyPrev = Math.abs(prevVertex[1] - newY);
    const dyNext = Math.abs(nextVertex[1] - newY);
    if (dyPrev < snapTolerance) {
      newVertices[index] = [newX, prevVertex[1]];
    } else if (dyNext < snapTolerance) {
      newVertices[index] = [newX, nextVertex[1]];
    }
  
    // Snap to vertical line
    const dxPrev = Math.abs(prevVertex[0] - newX);
    const dxNext = Math.abs(nextVertex[0] - newX);
    if (dxPrev < snapTolerance) {
      newVertices[index] = [prevVertex[0], newY];
    } else if (dxNext < snapTolerance) {
      newVertices[index] = [nextVertex[0], newY];
    }
  
    setVertices(newVertices);
  };
  


  const handleDeleteVertex = () => {
    if (selectedVertex !== null && vertices.length > 2) {
      const newVertices = vertices.filter((_, index) => index !== selectedVertex);
      setVertices(newVertices);
      setSelectedVertex(null);
    }
  };

  const calculateArea = (points) => {
    let area = 0;
    const n = points.length;
  
    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area += points[i][0] * points[j][1];
      area -= points[j][0] * points[i][1];
    }
  
    area = Math.abs(area) / 2;
    return area * scaleFactor * scaleFactor; // in square meters
};

  const getDistance = (x1, y1, x2, y2) => {
    const dx = x1 - x2;
    const dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const getAngle = (p1, p2, p3) => {
    const dx1 = p1[0] - p2[0];
    const dy1 = p1[1] - p2[1];
    const dx2 = p3[0] - p2[0];
    const dy2 = p3[1] - p2[1];
    const dotProduct = dx1 * dx2 + dy1 * dy2;
    const len1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const len2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
    const cosTheta = dotProduct / (len1 * len2);
    const angle = Math.acos(cosTheta);
    return (angle * 180) / Math.PI;
  };

  const handleCalculateArea = () => {
    if (vertices.length < 3) return;
  
    const calculatedArea = calculateArea(vertices);
    const calculatedCircumference = calculateCircumference(vertices);
  
    setArea(calculatedArea); // Update the area state with the calculated area
    setTerrasseArea({ 
      terrasseArea: calculatedArea, 
      terrasseCircumference: calculatedCircumference 
    }); // Update the context state with the area and circumference
  };
  
  const calculateCircumference = (points) => {
    let circumference = 0;
    const n = points.length;
  
    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      const distance = calculateDistance(
        points[i][0], points[i][1], points[j][0], points[j][1]
      );
      circumference += parseFloat(distance); // in meters
    }
  
    return circumference;
  };
  



  const scaleFactor = 0.03; // replace with the actual scale factor

  const calculateDistance = (x1, y1, x2, y2) => {
    const dx = x1 - x2;
    const dy = y1 - y2;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return (distance * scaleFactor).toFixed(2); // in meters
  };

  const handleClearAll = () => {
    setVertices([]);
    setSelectedVertex(null);
  };



  return (
    <StyledDiv>

      <div className="container">
        <div className="panel">
          <button onClick={handleDeleteVertex} className='btn btn-dark'>Slett punkt</button>
          <button onClick={handleClearAll} className='btn btn-dark'>Slett alt</button>

          {area && area > 0 ? (<div>Areal: {area.toFixed(2)} m²</div>) : <span></span>}
         
        </div>
        <div className="canvas" ref={canvasRef}>
        <Stage width={stageWidth} height={stageHeight} ref={stageRef} onClick={handleClick}  scaleX={scale} scaleY={scale} x={pos.x} y={pos.y}>

            <Layer>
              {vertices.map((vertex, index) => (
                <Circle
  key={index}
  x={vertex[0]}
  y={vertex[1]}
  radius={15}
  fill={index === selectedVertex ? 'blue' : 'gray'}
  stroke="white"
  strokeWidth={2}
  draggable
  onDragMove={(e) => handleDrag(e, index)}
  onClick={() => setSelectedVertex(index)}
  onMouseEnter={(e) => {
    // Change cursor style to pointer
    const container = e.target.getStage().container();
    container.style.cursor = 'pointer';
  }}
  onMouseLeave={(e) => {
    // Change cursor style back to default
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
  }}
/>

              ))}
              {vertices.length > 0 &&
                vertices.slice(1).map((vertex, index) => {
                  const distance = calculateDistance(
                    vertices[index][0], vertices[index][1], vertex[0], vertex[1]
                  );
                  return (
                    <React.Fragment key={index}>
                      <Line
                        key={index}
                        points={[
                          vertices[index][0], vertices[index][1], vertex[0], vertex[1]
                        ]}
                        stroke="black"
                        strokeWidth={5}
                      />
                      <Text
                        x={(vertices[index][0] + vertex[0]) / 2 + 5}
                        y={(vertices[index][1] + vertex[1]) / 2 + 5}
                        text={`${distance} m`}
                        fill="black"
                        padding={5}
                        backgroundFill="white"
                        fontStyle="bold"
                      />
                    </React.Fragment>
                  );
                })}
              {vertices.length > 2 &&
                <>
                  <Line
                    points={[
                      vertices[vertices.length - 1][0], vertices[vertices.length - 1][1],
                      vertices[0][0], vertices[0][1]
                    ]}
                    stroke="black"
                    strokeWidth={5}
                  />
                  <Text
                    x={(vertices[vertices.length - 1][0] + vertices[0][0]) / 2 + 5}
                    y={(vertices[vertices.length - 1][1] + vertices[0][1]) / 2 + 5}
                    text={`${calculateDistance(
                      vertices[vertices.length - 1][0], vertices[vertices.length - 1][1],
                      vertices[0][0], vertices[0][1]
                    )} m`}
                    fill="black"
                    padding={5}
                    backgroundFill="white"
                    fontStyle="bold"
                  />
                </>}
            </Layer>

            
          </Stage>
          <div className='d-flex my-3'>
          <button className='btn btn-outline-dark btn-sm' onClick={zoomIn}>Zoom inn</button>
          <button className='btn btn-outline-dark btn-sm mx-3' onClick={zoomOut}>Zoom ut</button>
          <button className='btn btn-outline-dark btn-sm' onClick={resetZoom}>Tilbakestill zoom</button>
        </div>
        
        </div>
      </div>
    </StyledDiv>
  );
};

export default DeckDesigner;

const StyledDiv = styled.div`
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .canvas {
    width: 70vw;
    height: 60vh;
    border: 2px solid black;
  }

  .panel {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 25vw;
  }

  @media (max-width: 1000px) {
    .container {
      flex-direction: column; 
      justify-content: center; 
      align-items: center; 
    }

    .canvas {
      width: 80vw; 
      height: calc(100vh - 240px); /* Adjust the height based on the panel's height and any spacing */
    }

    .panel {
      width: 80vw; 
      height: auto; 
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }
`;
