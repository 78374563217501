import React, { useState, useEffect, useContext } from 'react'
import NavProfile from '../../components/NavProfile'
import styled from "styled-components"
import { AuthContext } from '../../context/AuthContext';
import { collection, getDocs, query, where, deleteDoc, doc  } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import list from "../../images/frontImages/list.png"
import deleteIcon from "../../images/delete.png"

export default function LagredeHandlelister() {
  const { currentUser, cart, setCart } = useContext(AuthContext);
  const [savedCarts, setSavedCarts] = useState([]); 
  const [selectedCart, setSelectedCart] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
}
const handleCloseDeleteModal = () => {
  setShowDeleteModal(false);
}

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const fetchSavedCarts = async () => {
      try {
        const q = query(collection(db, 'savedCarts'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const carts = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          data.id = doc.id
          carts.push(data);
        });
        setSavedCarts(carts);
      } catch (error) {
        console.error('Error fetching saved carts: ', error);
      }
    };

    fetchSavedCarts();
  }, [currentUser]);

  const handleCartClick = (cart) => {
    setSelectedCart(cart);
  }


  const transferToGlobalCart = () => {
    // Merge the current global cart with the transformed cartKledning
    const mergedCart = [...cart, ...selectedCart.items];
    setCart(mergedCart);
    scrollToTop()
  };
  
  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"  // Optional: smooth scrolling
    });
  }

  const getArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
  };

  const getTotalSum = () => {
    return selectedCart.items.reduce((total, product) => total + (product.byggmakkerPrice * product.quantity), 0);
  };

  const handleDelete = async (id) => {
    // Ensure 'id' is present.
    if (!id) {
      console.error("Id error, liste finnes ikke.");
      return;
    }
  
    try {
      await deleteDoc(doc(db, "savedCarts", id));
      console.log(`Handleliste med ID ${id} slettet.`);
  
   
    } catch (error) {
      console.error("Error med sletting av liste: ", error);
    }
  };

  return (
    <StyledDiv>
      <NavProfile/>
      <div className='container my-5'>
      <h3>Dine handlelister</h3>
      <div>
      {savedCarts.map(cart => (
  <div className='listWrapper' key={cart.id}>
    <div className='lister my-3' onClick={() => handleCartClick(cart)}>
      <img src={list} alt="liste" className='list me-2'/> {cart.name}
      {".    Handleliste lagret: "}{cart && new Date(cart.createdAt.seconds * 1000).toLocaleDateString()}
    </div>
    <img src={deleteIcon} alt="slett" className='deleteIcon' onClick={handleOpenDeleteModal} />
    {showDeleteModal && (
    <>
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                zIndex: 0,
            }}
            onClick={handleCloseDeleteModal}
        />

        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '40px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 1,
            }}
        >
            <span className='x' onClick={handleCloseDeleteModal} style={{cursor:"pointer"}}>X</span>
            
        
                <>
                    <h3>Er du sikker?</h3>
                    <p>Vil du virkelig slette handleliste?</p>
                    
                    <div className='text-center mt-4'>
                        <button className='btn btn-danger' onClick={() => { handleDelete(cart.id); handleCloseDeleteModal(); }}>Ja, slett handleliste</button>
                    </div>
                </>
            
        </div>
    </>
)}
  </div>
  
))}


      </div>
      {selectedCart && (
        <CartContainer>
          <h5>Handleliste: <strong> {selectedCart.name} </strong></h5>
          <Table>
            <thead>
              <tr>
              <th></th>
                <th>Produkt</th>
                <th>Pris</th>
                <th>Antall</th>
                <th className="hide-sm">Artikkelnummer</th>
                <th>Produkt total</th>
              </tr>
            </thead>
            <tbody>
              {selectedCart.items.map((product, index) => (
                <tr key={index}>
                  <td><img src={product.imageSrc} alt={product.productName} style={{height: '50px'}}/></td>
                  <td>
                    <ProductNameLink to={`/${product.breadcrumb1}/${product.breadcrumb2}/${product.breadcrumb3}/${product.productName}`}>
                      <h2 style={{fontSize: '14px', marginBottom: '5px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>{product.productName}</h2>
                    </ProductNameLink>
                  </td>
                  <td>kr. {product.byggmakkerPrice} {product.unit}</td>
                  <td>{product.quantity}</td>
                  <td className="hide-sm">{getArticleNumber(product)}</td>
                  <td>{(product.byggmakkerPrice * product.quantity).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Footer>
            <div className='d-flex justify-content-between w-100'>
            <h2>Total: kr. {getTotalSum().toFixed(2)}</h2>
           <button className='btn btn-info' onClick={transferToGlobalCart}>Overfør til handlehurv</button>
            </div>
          </Footer>
        
        </CartContainer>
      )}
</div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`

h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .listWrapper {
    display: flex; 
    align-items: center; 
    justify-content: space-between; // Adjust based on your layout preference
  }

  .lister {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-grow: 1; // it will take the available space
  }

  .lister:hover {
    color: tomato;
  }

  .list {
    width: 40px;
  }

  .lister:hover .list {
    filter: brightness(0.5) sepia(1) hue-rotate(-50deg) saturate(1000%) contrast(0.8);
  }

  .deleteIcon {
    height: 27px;
    cursor: pointer;
    margin-left: 20px;
    transition: filter 0.3s ease; // for a smooth transition effect
  }

  .deleteIcon:hover {
    filter: brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(0.8); 
    // This applies a series of filter effects to alter the icon's appearance
    // You may need to play with these values to get your desired effect.
  }
`

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 60px);
  margin-top:10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th, td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  @media (max-width: 768px) {
    .hide-sm {
      display: none;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductNameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
