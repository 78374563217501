import React, {useContext, useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export default function SecureRoute({children}){
    const { currentUser, loading } = useContext(AuthContext);

    // If the user data is still being fetched, return null or a loading spinner
    if (loading) {
      return null; // Or return a loading spinner
    }

    return currentUser && currentUser.email === "alexander.utne@gmail.com" ? children : <Navigate to="/" />;
}
