import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import AdminNav from './AdminNav';
import styled from "styled-components";

export default function SingleRequest() {
  const { id } = useParams();
  const [request, setRequest] = useState(null);
  const [byggevarehus, setByggevarehus] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    console.log("lat lng from function "+ lat1, lon1, lat2, lon2)
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    const fetchRequest = async () => {
      const docRef = doc(db, 'quoteRequests', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('User data:', docSnap.data()); 
        setRequest(docSnap.data());
        setUserLocation({lat: docSnap.data().lat, lng: docSnap.data().lng});
      } else {
        console.log("No such document!");
      }
    };

    fetchRequest();
  }, [id]);

  useEffect(() => {
    const fetchByggevarehus = async () => {
        if (userLocation && !isNaN(userLocation.lat) && !isNaN(userLocation.lng)) {
        const byggevarehusSnapshot = await getDocs(collection(db, 'byggevarehus'));
        const byggevarehusData = byggevarehusSnapshot.docs.map(doc => {
          console.log('Byggevarehus data:', doc.data()); 
          let distance = null;
          if (doc.data().lat && doc.data().lng) {
            console.log('Calculating distance with lat, long:', userLocation.lat, userLocation.lng, doc.data().lat, doc.data().lng); 
            distance = getDistanceFromLatLonInKm(
              userLocation.lat, userLocation.lng,
              doc.data().lat, doc.data().lng
            );
          }

          return {
            id: doc.id,
            ...doc.data(),
            distance,
          };
        }).sort((a, b) => (a.distance > b.distance) ? 1 : -1);
        console.log(byggevarehusData)
        setByggevarehus(byggevarehusData.slice(0,10));
      }
    };

    fetchByggevarehus();
  }, [userLocation]);

  const getArticleNumber = (product) => {
    const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
    return articleNumberObject ? articleNumberObject.value : "";
  };

// Checkbox toggle function
const toggleByggevarehusSelection = (id) => {
    setByggevarehus(byggevarehus.map(bygg => bygg.id === id ? { ...bygg, selected: !bygg.selected } : bygg));
  };

  // Email generation function
  const generateEmailContent = () => {
    if (!request) return "";
  
    const selectedByggevarehus = byggevarehus.filter(bygg => bygg.selected);
    const cc = selectedByggevarehus.flatMap(bygg => bygg.employees.map(employee => employee.email)).join(',');
    const subject = `Tilbudsforespørsen fra MinOppussing | #${id}`;
  
    // Header of our table-like structure
    const tableHeader = "Produktnavn                   |        Produktnummer       |        Antall\n" + "-".repeat(60) + "\n";
  
    const productRows = request.products.map(product => {
      // Extracting the article number from the product
      const articleNumberObject = product.articleNumbers.find(el => el.key === "Artikkelnummer");
      const articleNumber = articleNumberObject ? articleNumberObject.value : '';
  
      // Formatting each product for the table. Adjust spaces as required to ensure alignment.
      return `${product.productName.padEnd(25, ' ')}    |    ${articleNumber.toString().padEnd(25, ' ')}  |     ${product.quantity.toString().padEnd(12, ' ')}\n`;
    }).join('');
  
    const tableContent = tableHeader + productRows;
    const body = `På vegne av kunde ønsker vi tilbud på følgende produkter (eller tilsvarende produkter). Ønsker også tilbud på frakt til følgende adresse: ${request.address}
    \n ${request.additionalMessage}
    \n\n\n${tableContent}`;
  
    return `To: \nCC: ${cc}\nSubject: ${subject}\n\n${body}`;
  };

  
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      alert('Email content copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  


  
  const updateStatus = async () => {
    const docRef = doc(db, 'quoteRequests', id);
    await updateDoc(docRef, {
      status: 'Tilbudsforespørsel sendt',
    });
    // Update local state so the change is reflected immediately in the UI
    setRequest({...request, status: 'Tilbudsforespørsel sendt'});
  }

  const regretUpdateStatus = async () => {
    const docRef = doc(db, 'quoteRequests', id);
    await updateDoc(docRef, {
      status: 'new',
    });
    // Update local state so the change is reflected immediately in the UI
    setRequest({...request, status: 'new'});
  }

  
  const getTotalSum = () => {
    if(request) return request.products.reduce((total, product) => total + (product.price * product.quantity), 0);
  };

  

  const formatter = new Intl.NumberFormat('no-NO', { style: 'decimal', minimumFractionDigits: 2 });
  return (
    <StyledDiv>
      <AdminNav/>
      <h1>Tilbudsforestørsel for {request && request.userName}</h1>
      {request && (
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Enhet</th>
              <th>Article Number</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {request.products.map((product, index) => (
              <tr key={index}>
                <td>
                  <img src={product.imageSrc} alt={product.productName} style={{ height: '50px' }} />
                </td>
                <td>{product.productName}</td>
                <td>{product.price}</td>
                <td>{product.quantity}</td>
                <td>{product.unit}</td>
                <td>{getArticleNumber(product)}</td>
                <td>{(product.price * product.quantity).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
        <div className='d-flex justify-content-center'>  
           <h2>Total: kr. {request && (formatter.format(getTotalSum().toFixed(2)))}</h2>
        </div>
      <p>{request && request.additionalMessage}</p>

      <br/>
      <h4>Leveringsadresse: { request && request.address}</h4>

      <br></br>
      <h4>Nærmeste Byggevarehus:</h4>
      <table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Employees' Emails</th>
      <th>Distance (km)</th>
      <th>Select</th>
    </tr>
  </thead>
  <tbody>
    {byggevarehus.map((bygg, index) => (
      <tr key={index}>
        <td>{bygg.name}</td>
        <td>{bygg.address}</td>
        <td>{bygg.employees.map(employee => employee.email).join(', ')}</td>
        <td>{bygg.distance && bygg.distance.toFixed(2)}</td>
        <td><input type="checkbox" checked={bygg.selected} onChange={() => toggleByggevarehusSelection(bygg.id)} /></td>
      </tr>
    ))}
  </tbody>
</table>
<br/>
<div className='d-flex justify-content-center'>
    <h3>Tilbudsforespørsel status: <strong>{request && request.status}</strong></h3>
</div>
<div className='d-flex justify-content-center'>
<p>Husk å endre status etter epost er sendt til byggevarehus...</p>
</div>
<div className='d-flex justify-content-around my-2'>
  <UpdateStatusButton onClick={updateStatus}>Endre Status til "Tilbudsforespørsel sendt"</UpdateStatusButton>

</div>
<div className='d-flex justify-content-around my-2'>

{request && request.status==="Tilbudsforespørsel sendt" && (<button className='btn btn-outline-danger' onClick={regretUpdateStatus}>Angre </button>)}
</div>
    <div className='d-flex justify-content-around my-2'>
      <SendEmailButton >Send email (ute av drift)</SendEmailButton>
</div>

<div className='d-flex justify-content-around my-2'>
  <button onClick={() => copyToClipboard(generateEmailContent())}>Copy Email Content</button>
</div>


    </StyledDiv>
  );
}

const SendEmailButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #007BFF;
  border-radius: 5px;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
`;

const UpdateStatusButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
`;

const StyledDiv = styled.div`

table td {
    padding-right:  20px; /* Adjust the right and left padding as per your needs */
}

table {
    border-spacing: 0 20px; /* This adds 20px spacing between columns */
}
`