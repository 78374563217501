import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import { GoogleAuthProvider, signInWithPopup, sendSignInLinkToEmail, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const getInitialCart = () => {
    try {
      const cartFromLocalStorage = localStorage.getItem('cart');
      if (cartFromLocalStorage) {
        return JSON.parse(cartFromLocalStorage);
      }
    } catch (error) {
      console.error("Invalid JSON in localStorage:", error);
    }
    return [];
  };

  const [currentUser, setCurrentUser] = useState(null);
  const [cart, setCart] = useState(getInitialCart());
  const [loading, setLoading] = useState(true);
  const [unseenQuotes, setUnseenQuotes] = useState(0); // State to keep track of unseen quotes
  const [materials, setMaterials] = useState([]);
  const [cartKledning, setCartKledning] = useState([]);
  const [terrasseArea, setTerrasseArea] = useState([]);
  const [terrasseMaterials, setTerrasseMaterials] = useState([]);
  const [materialsLettvegg, setMaterialsLettvegg] = useState({});
  const [materialsLecaVegg, setMaterialsLecaVegg] = useState([]);
  const [materialsYttervegg, setMaterialsYttervegg] = useState([]);
  const [cartYttervegg, setCartYttervegg] = useState([])
  const [materialsCalculatedYttervegg, setMaterialsCalculatedYttervegg] = useState(false);
  const [projectCart, setProjectCart] = useState()



  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (!docSnap.exists()) {
          await setDoc(userRef, {
            signUpDate: new Date().toISOString(),
            email: user.email,
            userId: user.uid
          });
        }
      }
      setCurrentUser(user);
      setLoading(false)
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchUnseenQuotes = async () => {
      if (!currentUser) {
        return;
      }

      const quotesCollection = collection(db, 'quotes');
      const q = query(quotesCollection, where('userId', '==', currentUser.uid), where('seen', '==', false));
      const querySnapshot = await getDocs(q);
      setUnseenQuotes(querySnapshot.size); // Update the state with the count of unseen quotes
    };

    fetchUnseenQuotes();
  }, [currentUser]);


  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const passwordlessSignIn = async (email) => {
    const urls = [
      'http://localhost:3000/confirmSignIn',
      'https://tubular-sable-0efb77.netlify.app/confirmSignIn',
      'https://www.pusse.no/confirmSignIn',
      'http://pusse.no/confirmSignIn',
      'https://www.minoppussing.no/confirmSignIn',
      'http://minoppussing.no/confirmSignIn',
      'https://minoppussing.no/confirmSignIn',
      'https://pusse.no/confirmSignIn',

      // Add more URLs as needed
    ];

    // Select the URL that matches the current window origin
    const appUrl = urls.find((url) => url.startsWith(window.location.origin));

    if (!appUrl) {
      console.error('No matching URL found for the current window location.');
      return;
    }

     try {
    await sendSignInLinkToEmail(auth, email, {
      url: appUrl,
      handleCodeInApp: true,
    });
    window.localStorage.setItem('emailForSignIn', email);
    console.log(`Sign-in link sent to ${email}`);
  } catch (error) {
    console.error('Error sending sign in link: ', error);
    throw error;  // important: re-throw the error so it can be caught in the calling component
  }
};


  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);

      const userRef = doc(db, 'users', result.user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists() || !docSnap.data().firstName || !docSnap.data().lastName || !docSnap.data().address) {
        // if user doc doesn't exist or firstName, lastName, or address are missing,
        // redirect to the ConfirmSignIn page to complete profile
        window.location.href = "/confirmSignIn";
      } else {
        // if all necessary fields exist, redirect to the home page
        window.location.href = "/";
      }

    } catch (error) {
      console.error('Error signing in with Google: ', error);
    }
  };

  const setUserDocument = async (user) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        signUpDate: new Date().toISOString(),
        email: user.email,
        userId: user.uid
      });
    } catch (error) {
      console.error('Error creating user document: ', error);
    }
  };


  return (
    <AuthContext.Provider value={{ 
      currentUser, 
      loading, 
      logout, 
      passwordlessSignIn, 
      googleSignIn, 
      setUserDocument, 
      cart, 
      setCart, 
      unseenQuotes, 
      materials, 
      setMaterials, 
      cartKledning, 
      setCartKledning,
      terrasseArea,
      setTerrasseArea,
      terrasseMaterials, 
      setTerrasseMaterials,
      materialsLettvegg, 
      setMaterialsLettvegg,
      materialsLecaVegg,
      setMaterialsLecaVegg,
      setMaterialsYttervegg,
      materialsYttervegg,
      setCartYttervegg,
      cartYttervegg,
      materialsCalculatedYttervegg,
      setMaterialsCalculatedYttervegg,
      setProjectCart,
      projectCart
      

       }}>
      {children}
    </AuthContext.Provider>
  );
};
