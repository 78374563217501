import { useEffect, useState } from 'react';
import { db } from '../firebase/firebase';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { doc, getDoc } from 'firebase/firestore';
import styled from "styled-components"

const PriceHistory = ({ productId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "priceHistory", productId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const historyData = docSnap.data().priceHistory?.map(entry => {
          const date = entry.timestamp.toDate().toLocaleDateString();
          return { date, price: parseFloat(entry.price) } // parsing string price to float for correct graph representation
        }) || [];

        setData(historyData);
      } else {
        console.log("No such document!");
      }
    }

    fetchData();
  }, [productId]);

  

  return (
    <StyledDiv>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis dataKey="price" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 5 }}   dot={{ r: 0 }} />
        </LineChart>
      </ResponsiveContainer>
    </StyledDiv>
  );
}

export default PriceHistory;

const StyledDiv = styled.div`
  width: 600px;
  height: 300px;
  
@media(max-width:700px){
  width: 300px;
  height: 200px;
}
`
