
import React from "react";
import styled from "styled-components";

export default function Paymenterror() {
  return (
    <ProfilWrapper>
      <div className="container">
        <div className="row my-3">
          <div className="col">
            <h3>Beklager: Feil med betaling... </h3>
          </div>
        </div>
        
      </div>
    </ProfilWrapper>
  );
}

const ProfilWrapper = styled.div`

h3 {
    color: #2c3e50;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

`;