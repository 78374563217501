import React, { useState, useEffect,useContext } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import {Link} from "react-router-dom"
import styled from "styled-components"
import {db} from "../../firebase/firebase"
import wall from "../../images/wall.png"
import wallgavel from "../../images/wallgavel.png"
import { Card, Row } from 'react-bootstrap';
import glava from "../../images/glavaIcon.webp"
import rockwool from "../../images/rockwoolIcon.webp"
import vindsperre from "../../images/vindsperre.jpg.png"
import asfalt from "../../images/asfalt.jpg"
import gips from "../../images/gips.jpg"
import KledningProducts from './KledningProducts';
import { AuthContext } from '../../context/AuthContext';
import liggende from "../../images/liggende.png"
import stående from "../../images/stående.png"

import FineProdukterKledning from "../calculators/FineProdukterKledning";



export default function Kledning() {
    const [replaceInsulation, setReplaceInsulation] = useState(null);
    const [wallThickness, setWallThickness] = useState(null);
    const [etterisolere, setEtterisolere] = useState(null);
    const [etterisolereThickness, setEtterisolereThickness] = useState(null);
    const [selectedWall, setSelectedWall] = useState(null);
    const [wallDimensions, setWallDimensions] = useState({
        length: 0, 
        height: 0, 
        sideRoofHeight: 0, 
        centerRoofHeight: 0, 
        windows: [] // Array to store multiple windows
    });
    const [kledningOptions, setKledningOptions] = useState([]);
    const [selectedKledningProduct, setSelectedKledningProduct] = useState(null);
    const [selectedInsulation, setSelectedInsulation] = useState(null)
    const [selectedInsulationEtter, setSelectedInsulationEtter] = useState(null)
    const [selectedVindsperreOptions, setSelectedVindsperreOptions] = useState([]);
    const [selectedKledningOrientering, setSelectedKledningOrientering] = useState([])

    const { setMaterials, cartKledning } = useContext(AuthContext);

    useEffect(() => {
        if (window.gtag) { // gtag is Google's Global Site Tag
          window.gtag('config', 'G-HQP57VRNM4', {
            'page_path': '/prosjektkalkulator/kledning'
          });
        }
      }, []);
      

    const calculateSurfaceArea = () => {
        let totalArea = 0;
        if (selectedWall === "sidewall") {
            totalArea = wallDimensions.length * wallDimensions.height;
        } else if (selectedWall === "gablewall") {
            const squareArea = wallDimensions.length * wallDimensions.sideRoofHeight;
            const triangleHeight = Math.max(0, wallDimensions.centerRoofHeight - wallDimensions.sideRoofHeight);
            const triangleArea = 0.5 * wallDimensions.length * triangleHeight;
            totalArea = squareArea + triangleArea;
        }
            return totalArea ;
    };

        const area = calculateSurfaceArea();



        const calculateWindowArea = () => {
            return wallDimensions.windows.reduce((total, window) => {
                return total + (window.count * window.width * window.height);
            }, 0) / 10000; // Convert from cm² to m²
        };

        const addWindow = () => {
            setWallDimensions({
                ...wallDimensions,
                windows: [...wallDimensions.windows, { count: 1, width: 0, height: 0 }]
            });
        };
        
        const updateWindow = (index, field, value) => {
            const newWindows = [...wallDimensions.windows];
            newWindows[index] = { ...newWindows[index], [field]: value };
            setWallDimensions({ ...wallDimensions, windows: newWindows });
        };
        
        const removeWindow = (index) => {
            const newWindows = wallDimensions.windows.filter((_, i) => i !== index);
            setWallDimensions({ ...wallDimensions, windows: newWindows });
        };

    const calculateRoofSideAngle = () => {
        if (selectedWall !== "gablewall") {
            return null; // Return null or some default value when it's not a gable wall
        }
        const triangleHeight = Math.max(0, wallDimensions.centerRoofHeight - wallDimensions.sideRoofHeight);
        const hypotenuse = Math.sqrt((wallDimensions.length / 2) ** 2 + triangleHeight ** 2);
        const angleInRadians = Math.asin(triangleHeight / hypotenuse);
        const angleInDegrees = angleInRadians * (180 / Math.PI); // Convert radians to degrees
        return angleInDegrees;
    };
    

    const angle = calculateRoofSideAngle();
if (angle !== null) {
}




    useEffect(() => {
        async function fetchProductsByBreadcrumb() {
            const productRef = collection(db, 'products');
            const q = query(productRef, where('breadcrumb2', '==', 'Utvendig kledning'));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map(doc => doc.data());
        }

        async function fetchProducts() {
            try {
                const kledning = await fetchProductsByBreadcrumb("Utvendig kledning");
                setKledningOptions(kledning);
            } catch (error) {
                console.error("Error fetching kledning:", error);
            }
        }
        
        fetchProducts();
    }, []);


    const calculateBargesForWindows = () => {
        return wallDimensions.windows.reduce((total, window) => {
            const width = window.width / 100; // Convert from cm to m
            const height = window.height / 100; // Convert from cm to m
            const perimeter = (width + height) * 2; // Calculate the perimeter of each window
            console.log(total + (window.count * perimeter))
            return total + (window.count * perimeter);
        }, 0);
    };
    
    const handleCalculateMaterials = () => {
        const windowArea = calculateWindowArea(); // Calculate the window area
        const netWallArea = area - windowArea;
    
        const insulationForExisting = netWallArea;
        const vindsperreArea = netWallArea;
        const kledningAmount = netWallArea / 0.130;
    
        let totalBarges = 0;  // initialize this variable outside the if block
        let insulationForEtterisolering = 0;  // same for this variable
    
        if (etterisolere) {
            const bargesForWindows = calculateBargesForWindows()
            const bargesForWall = 2 * (wallDimensions.length + wallDimensions.height);
            const internalBarges = (wallDimensions.length / 0.6) * wallDimensions.height;
            totalBarges = bargesForWindows + bargesForWall + internalBarges;
            insulationForEtterisolering = netWallArea;
        }
    
        const result = {
            wallArea: netWallArea,
            wallLength: wallDimensions.length,
            wallHeight: wallDimensions.height,
            wallHeightSide:wallDimensions.sideRoofHeight,
            wallHeightCenter: wallDimensions.centerRoofHeight,
            selectedWall:selectedWall,
            insulationForExisting: insulationForExisting,
            insulationForExistingThickness: wallThickness,
            vindsperreArea: vindsperreArea,
            kledningAmount: kledningAmount,
            lekter: totalBarges,
            insulationForEtterisolering: insulationForEtterisolering,
            etterisolereThickness: etterisolereThickness,
            insulationType: selectedInsulation,
            selectedVindsperreOptions: selectedVindsperreOptions,
            selectedKledningProduct: selectedKledningProduct,
            selectedKledningOrientering:selectedKledningOrientering
        };
    
        setMaterials(result);
        scrollToBottom();
    }
    
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    }


    const toggleVindsperreOption = (option) => {
        if (selectedVindsperreOptions.includes(option)) {
          setSelectedVindsperreOptions(prev => prev.filter(item => item !== option));
        } else {
          setSelectedVindsperreOptions(prev => [...prev, option]);
        }
      };

      const handleInsulationClick = (insulationType) => {
        setSelectedInsulation(insulationType);
        setSelectedInsulationEtter(insulationType);
    };

    const handleOpenProduct = (event, product) => {
        event.preventDefault();
        const url = `/${product.breadcrumb1}/${product.breadcrumb2 || ''}/${product.breadcrumb3 || ''}/${product.productName}`;
        window.open(url, "_blank");
      };
      
    return (

        <>
        <StyledDiv className='container my-3'>
              <div className='breadcrumbs my-4'>
        <StyledLink to="/prosjektkalkulator">Prosjektkalkulator</StyledLink> / <StyledLink to="/prosjektkalkulator/kledning">Kledning</StyledLink>
      </div>
            <h3>Bytte kledning</h3>
            <p>Denne kalkulatoren gir et estimat over hvor mye materiale du trenger for å bytte ut kledningen. Det er viktig å huske at kalkulatoren kun gir et estimat av ditt materialebehov.</p>
    
            <form>
            <section>
                <p>Hvilken type vegg skal du bytte kledning på og hvor stor er den?</p>
                <div className="wallOptions">
                    <img src={wall} className={`sideWallIcon ${selectedWall === "sidewall" ? "selected" : ""}`} onClick={() => setSelectedWall("sidewall")} alt="bilde av hus med rette vegger" />
                    <img src={wallgavel} className={`gableWallIcon ${selectedWall === "gablewall" ? "selected" : ""}`} onClick={() => setSelectedWall("gablewall")} alt="bilde av hus med stråvegg" />
                </div>
                
                {selectedWall === "gablewall" && (
                    <>
                        <div className='input-container'>
                            <label>Høyde på siden av vegg? </label>
                            <input type="number" value={wallDimensions.sideRoofHeight} onChange={(e) => setWallDimensions({...wallDimensions, sideRoofHeight: parseFloat(e.target.value) })}></input>
                            <span> meter</span>
                        </div>
                        <div className='input-container'>
                            <label>Høyde til møne? (midt på vegg) </label>
                            <input type="number" value={wallDimensions.centerRoofHeight} onChange={(e) => setWallDimensions({...wallDimensions, centerRoofHeight: parseFloat(e.target.value) })}></input>
                            <span> meter</span>
                        </div>
                        <div className='input-container'>
                            <label>Hvor lang er vegg? </label>
                            <input type="number" value={wallDimensions.length} onChange={(e) => setWallDimensions({...wallDimensions, length: parseFloat(e.target.value)})}></input>
                            <span> meter</span>
                        </div>
                    </>
                )}
    
                {selectedWall === "sidewall" && (
                    <>
                        <div className='input-container'>
                            <label>Hvor lang er vegg? </label>
                            <input type="number" value={wallDimensions.length} onChange={(e) => setWallDimensions({...wallDimensions, length: parseFloat(e.target.value)})}></input>
                            <span> meter</span>
                        </div>
                        <div className='input-container'>
                            <label>Hvor høy er vegg? </label>
                            <input type="number" value={wallDimensions.height} onChange={(e) => setWallDimensions({...wallDimensions, height: parseFloat(e.target.value)})}></input>
                            <span> meter</span>
                        </div> 
                    </>
                )}
                {area > 0 && (<p>Det totale arealet på veggen: {area.toFixed(2)} m<sup>2</sup></p>)}
                {angle > 0 && (<p>Takvinkel er omtrent {angle.toFixed(2)} grader.</p>)}
                </section>


                <section>
    <p>Hvor store og mange vinduer er det på veggen?</p>
    <button type="button" onClick={addWindow} className='btn btn-outline-primary mx-2'>Legg til vinduer</button>

    {wallDimensions.windows.map((window, index) => (
        <div key={index} className='window-group'>
            <hr />
            <div className='input-container'>
                <label>Antall vinduer:</label>
                <input 
                    type="number"
                    value={window.count}
                    onChange={(e) => updateWindow(index, 'count', parseInt(e.target.value, 10))}
                />
            </div>
            <div className='input-container'>
                <label>Bredde på vinduer (cm):</label>
                <input 
                    type="number"
                    value={window.width}
                    onChange={(e) => updateWindow(index, 'width', parseInt(e.target.value, 10))}
                />
            </div>
            <div className='input-container'>
                <label>Høyde på vinduer (cm):</label>
                <input 
                    type="number"
                    value={window.height}
                    onChange={(e) => updateWindow(index, 'height', parseInt(e.target.value, 10))}
                />
            </div>
            <button type="button" onClick={() => removeWindow(index)} className='btn btn-danger'>Fjern</button>
        </div>
    ))}

    {wallDimensions.windows.length > 0 && (
        <p>Det totale arealet dekket av vinduer: {calculateWindowArea().toFixed(2)} m<sup>2</sup></p>
    )}
</section>

                <section>
    <p>Bytte eksisterende isonasjon?</p>
    <label>
        <input
            type="radio"
            value="yes"
            checked={replaceInsulation === 'yes'}
            onChange={e => {
                setReplaceInsulation(e.target.value);
                if (e.target.value === 'yes') {
                    setWallThickness("5cm");
                    setSelectedInsulation("glava")
                }
            }}
        />
        Ja
    </label>

    <label>
    <input
        type="radio"
        value="no"
        checked={replaceInsulation === 'no'}
        onChange={e => {
            const newValue = e.target.value;
            setReplaceInsulation(newValue);
            if (newValue === 'no') {
                setWallThickness(null);
                if (etterisolere === "no") {
                    setSelectedInsulation(null);
                }
            }
        }}
    />
    Nei
</label>

    {replaceInsulation === 'yes' && (
        <>
            <div>
                <p>Hvor tykk er eksisterende isolasjon/vegg?</p>
                <select value={wallThickness} onChange={e => setWallThickness(e.target.value)}>
                    <option value="5cm">5cm</option>
                    <option value="7cm">7cm</option>
                    <option value="10cm">10cm</option>
                    <option value="15cm">15cm</option>
                    <option value="20cm">20cm</option>
                </select>
            </div>
        </>
    )}
</section>

                <section>
                <p>Skal du etterisolere?</p>
<label>
    <input
        type="radio"
        value="yes"
        checked={etterisolere === 'yes'}
        onChange={e => {
            const newValue = e.target.value;
            setEtterisolere(newValue);
            if (newValue === 'yes') {
                setEtterisolereThickness("5cm");
                setSelectedInsulationEtter("glava");
            }
        }}
    />
    Ja
</label>

<label>
    <input
        type="radio"
        value="no"
        checked={etterisolere === 'no'}
        onChange={e => {
            const newValue = e.target.value;
            setEtterisolere(newValue);
            if (newValue === 'no' ) {
                setSelectedInsulationEtter(null);
                if (replaceInsulation === "no") {
                    setSelectedInsulation(null);
                }
            }
        }}
    />
    Nei
</label>


    {etterisolere === 'yes' && (
        <>
            <div>
                <p>Hvor mye skal du etterisolere?</p>
                <select value={etterisolereThickness} onChange={e => setEtterisolereThickness(e.target.value)}>
                    <option value="5cm">5cm</option>
                    <option value="7cm">7cm</option>
                    <option value="10cm">10cm</option>
                    <option value="15cm">15cm</option>
                    <option value="20cm">20cm</option>
                </select>
            </div>
        </>
    )}
</section>

                     {(replaceInsulation === 'yes' || etterisolere === 'yes') && (
                    <section>
                        <div className='insulationOptions'>
                            <p>Hvilken type isolasjon ønsker du?</p>
                            <img src={glava} className={` ${selectedInsulation === "glava" ? "selected" : ""}`} onClick={() => handleInsulationClick("glava")} alt="glava icon"/>
                            <img src={rockwool} className={` ${selectedInsulation === "rockwool" ? "selected" : ""}`} onClick={() => handleInsulationClick("rockwool")} alt="rockwoll icon" />
                        </div>
                    </section>
                )}

                        

                        <section>
  <div className='vindsperreOptions'> 
    <p>Hvilken type vingsperre ønsker du? </p>
    <img 
      src={vindsperre} 
      alt="vindsperre duk" 
      className={selectedVindsperreOptions.includes("vindsperre") ? "selected" : ""} 
      onClick={() => toggleVindsperreOption("vindsperre")} 
      title="vindsperre duk" 

    />
    <img 
      src={asfalt} 
      alt="vindsperre asfalt" 
      className={selectedVindsperreOptions.includes("asfalt") ? "selected" : ""} 
      onClick={() => toggleVindsperreOption("asfalt")} 
      title="vindsperre asfaltplate" 

    />
    <img 
      src={gips} 
      alt="vindsperre gips" 
      className={selectedVindsperreOptions.includes("gips") ? "selected" : ""} 
      onClick={() => toggleVindsperreOption("gips")} 
      title="vindsperre gipsplate" 

    />
  </div>
</section>

                        <section>
                            <div className='liggendeEllerStående'>
                            <p>Hvilken orientering skal kledning ha? (liggende eller stående)</p>
                                <img 
                                    src={liggende}
                                    alt="liggende kledning img"
                                    className={selectedKledningOrientering.includes("liggende") ? "selected" : ""}
                                    onClick={()=>setSelectedKledningOrientering("liggende")}
                                    title="Liggende kledning" 

                                />
                                
                                <img 
                                    src={stående}
                                    alt="stående kledning img"
                                    className={selectedKledningOrientering.includes("stående") ? "selected" : ""}
                                    onClick={()=>setSelectedKledningOrientering("stående")}
                                    title="Stående kledning" 

                                />
                            </div>
                        </section>


<section>
<div className='product-cards-container'>
<p>Hvilken type kledning ønsker du?</p>

<Row xs={2} md={2} lg={5} className="g-4">

{kledningOptions.length > 0 && kledningOptions.map(product => (
<StyledCard key={product.id} 
            onClick={() => setSelectedKledningProduct(product)}
            className={selectedKledningProduct && selectedKledningProduct.id === product.id ? "selected" : ""}>
<Card className='card'>
<Card.Img variant="top" src={product.imageSrc} />
<Card.Body className="body">
<Card.Title>{product.productName}</Card.Title>
<PriceUnitContainer>
<PriceText>Kr {product.byggmakkerPrice}</PriceText>
<UnitText>{product.unit}</UnitText>
</PriceUnitContainer>
<Advantages>
{product.advantages.map((adv, idx) => <li key={idx}>{adv}</li>)}
</Advantages>
</Card.Body>
</Card>
  <button className='btn btn-success my-1' onClick={(event) => handleOpenProduct(event, product)}> Se produkt</button>

    
</StyledCard>
))}
</Row>
</div>
</section>
</form>

<section>
    <button onClick={handleCalculateMaterials} className='btn btn-info buttonRegneUt'>Regn ut materialer</button>
</section>

<KledningProducts  />


        </StyledDiv>
        {cartKledning.length > 0 && <FineProdukterKledning/>}

        </>
    );
    
}



const StyledDiv = styled.div`
    font-family: Arial, sans-serif;


    h3 {
        color: #2c3e50;
        border-bottom: 2px solid #ecf0f1;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 15px;
        color: #34495e;
    }
    
    section{
        background-color: #ecf0f1;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0,0,0,0.1);
        margin-bottom:15px;

        @media(max-width:400px){
                padding:12px!important;
            }
    }

    label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #2c3e50;
    }

    input[type="number"], select {
        width: 50%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #bdc3c7;
        border-radius: 4px;
    }

    .buttonRegneUt{
        width: 100%; /* Add this line */
        text-align: center; /* Add this line */
    }
    .breadcrumbs{
    text-decoration: none; // Removes the underline
    color: inherit; // Inherits the color from its parent
    }


    .insulationOptions, .vindsperreOptions {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap; // Add this line to allow the images to wrap to the next line if there's not enough space
    p {
        width: 100%; /* Add this line */
        text-align: start; /* Add this line */
    }

    img {
        width: 150px;
        height: auto;
        cursor: pointer;
        margin-right: 30px;
        transition: transform 0.3s ease;
        mix-blend-mode: multiply;

        &:hover {
            transform: scale(1.1);
        }
    }

    .selected {
        transform: scale(1.1);
        border: 3px solid #3498db;
        border-radius: 4px;
    }
}

.liggendeEllerStående {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap; // Add this line to allow the images to wrap to the next line if there's not enough space
    p {
        width: 100%; /* Add this line */
        text-align: start; /* Add this line */
    }

    img {
        width: 150px;
        height: auto;
        cursor: pointer;
        margin-right: 30px;
        transition: transform 0.3s ease;
        mix-blend-mode: multiply;

        &:hover {
            transform: scale(1.1);
        }
    }

    .selected {
        transform: scale(1.1);
        border: 3px solid #3498db;
        border-radius: 4px;
    }
}

@media (max-width: 768px) {
    .insulationOptions img, .vindsperreOptions img {
        width: 45%; // Set to 100% to make them full-width on small screens
        margin-right: 0; // Remove the margin on the right to make them stack properly
    }
}



    .wallOptions {
        margin-bottom: 20px;
        display: flex;
        gap: 20px;

        img {
            width:100px;
            height:100px ;
            cursor: pointer;
            margin-right:30px ;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }
        }

        .selected {
            transform: scale(1.1);
            border: 3px solid #3498db;
            border-radius:4px ;
        }
    }

    form {
    
    }

    .container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 15px;
}

.wallOptions {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.sideWallIcon, .gableWallIcon {
    width: 100px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 0.6;
}

.sideWallIcon.selected, .gableWallIcon.selected {
    opacity: 1;
}

.input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.input-container label {
    margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-container label {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.input-container input[type="number"] {
    width: auto;
    margin-right: 5px;
}

.input-container span {
    margin-left: 5px;
}

.product-cards-container {
    display: flex;
    flex-wrap: wrap; // This allows the items to wrap to the next row if there's not enough space
    gap: 16px; // Space between each card
}


`
const StyledCard = styled.div`
@media(max-width:400px){
    padding-left:3px;
    padding-right:3px;
}
  cursor: pointer;
  .card:hover{
    border:1px solid gray;
    scale:1.01 ;
  }
  .body{
    padding:12px;
  }


  &.selected{
    border: 3px solid #3498db;
    border-radius:4px ;
    padding:3px;
  }
`;
const PriceUnitContainer = styled.div`
    display: flex;
    align-items: baseline;  // align items along the text baseline
`;

const PriceText = styled.span`
    font-size: 1.3em;
    font-weight: bold;
`;

const UnitText = styled.span`
    font-size: 1.1em;
    margin-left: 0.1em;  // Add a little spacing between the price and the unit
`;

const Advantages = styled.ul`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 17px; // space for bullet points
  font-size:12px;
`;

const StyledLink = styled(Link)`
text-decoration: none; // Removes the underline
color: inherit; // Inherits the color from its parent

&:hover{
    color:tomato;
}
`;

