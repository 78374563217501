import React, { useEffect, useState } from 'react';
import AdminNav from '../admin/AdminNav';
import { db } from '../firebase/firebase';
import { collection, getDocs } from "firebase/firestore";

export default function Admin() {
  const [numProducts, setNumProducts] = useState(0);
  const [numUsers, setNumUsers] = useState(0);
  const [numQuoteRequests, setNumQuoteRequests] = useState(0);


  useEffect(() => {
    // Fetch number of products
    getDocs(collection(db, "products")).then((querySnapshot) => {
      setNumProducts(querySnapshot.size);
    });

    // Fetch number of users
    getDocs(collection(db, "users")).then((querySnapshot) => {
      setNumUsers(querySnapshot.size);
    });

    // Fetch number of quote requets
    getDocs(collection(db, "quoteRequests")).then((querySnapshot) =>{
      setNumQuoteRequests(querySnapshot.size)
    })
  }, []);

  return (
    <div>
      <AdminNav />
      <div className='container my-5'>
        <div className='row'>
        <h1>Some stats</h1>
          <div className='col'>
          <p>Brukere: {numUsers}</p>
          <p>Produkter: {numProducts}</p>
          <p>Tilbudsforespørsler: {numQuoteRequests}</p>
            
            

          </div>
          <div className='col'>
          <a href="https://analytics.google.com/analytics/web/?authuser=0#/p404097964/reports/intelligenthome?params=_u..insightCards%3D%5B%7B%22question%22:%22Hvilke%20enheter%20er%20mest%20utbredt%3F%22%7D%5D" target="_blank" rel="noopener noreferrer">Gå til google analytics</a>
           
          </div>
        </div>
      </div>
    </div>
  );
}
